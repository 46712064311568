 <template>
    <a-modal
      :title="title"
      :width="600"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="手机号">
                  <a-input :maxLength="30"  v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号!' }]  }]"  style="width:100%"
                      placeholder="请输入手机号"  ></a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="姓名">
                  <div class="speech-layout">
                      <a-input :maxLength="100" v-decorator="['memberName', { rules: [{ required: false, message: '!' }] }]"  style="width:100%"
                          placeholder=""  ></a-input> <a-icon @click="speechRecognitionName" type="audio" style="color:#1890FF;padding-left: 20px;" />
                  </div>    
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="备注">
                  <a-auto-complete
                        :maxLength="200"
                        v-decorator="['remark', { rules: [{ required: false, message: '!' }] }]"
                        :data-source="remarkData"
                        style="width: 100%"
                        placeholder="请输入备注"
                      />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
    </a-modal>  
</template>

<script>

  import { postAction, getAction } from '@/api/manage'
  import { speakCharacter,speechRecogn } from '@/utils/util'

  export default {
    name: 'UpdateMemberModal',
    components: {
    },
    data () {
      return {
        title:'修改会员信息',
        width:600,
        visible: false,
        confirmLoading: false,
        remarkData:["会员卡消费打n折","会员充值满n元送x元"],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        record:{},
        url: {
          updateMemberInfo: "/khcrm/web/haircut/updateMemberInfo",
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      update (record) {
        this.record = record;
        this.form.resetFields();
        this.$nextTick(() => {
          setTimeout(() => {
            this.form.setFieldsValue({
              phone: record.phone,
              memberName: record.memberName,
              remark: record.remark,
            })
          })
        });
        this.visible = true;
      },
      submitForm (e) {
        const that = this;
        that.form.validateFields((err, values) => {
          if (!err) {
            values.hcMemberId = that.record.id;
            that.updateMemberInfo(values);
          }
         })
      },
      updateMemberInfo(param){
        let that = this
        this.confirmLoading = true
        postAction(this.url.updateMemberInfo,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("修改成功");
            that.handleCancel()
            that.$emit('ok');
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
           that.confirmLoading = false;
        })
      },
      speechRecognitionName(){
        this.$message.warn("请讲话！！！（请注意目前仅支持Microsoft Edge浏览器）  ");
        let recognition = speechRecogn();
        let that = this;
        recognition.onspeechend = (e)=>{
            recognition.stop();
        }
        recognition.onresult = function(event) {
            const result = event.results[event.results.length - 1]
            let info = result[0].transcript;
            if(info != ''){
              that.setName(info.replace('。',''))
            }
        }
      },
      setName(name){
        this.$nextTick(() => {
          setTimeout(() => {
            this.form.setFieldsValue({
              memberName: name,
            })
          })
        });
      },
      handleCancel () {
        this.confirmLoading = false;
        this.visible = false;
      }
    }
  }
</script>
<style scoped>
.speech-layout{
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>