<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24" class="searchclass" >
          <a-col :md="8" :sm="10">
            <a-form-item label="">
              <a-input-search v-model="queryParam.name" :maxLength="200" placeholder="请输入客户名称/合同名称/合同编号" enter-button="搜索" size="default"
                  @search="searchQuery" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24"  >
          <a-col :md="6" :sm="8">
            <a-form-item label="合同范围">
              <a-select v-model="queryParam.range" placeholder="请选择" @change="changeRange">
                <a-select-option value="1">全部合同</a-select-option>
                <a-select-option value="2">本部门的合同</a-select-option>
                <a-select-option value="3">我负责的合同</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-button type="primary"  @click="changeOwner"> <a-icon type="retweet" />变更负责人</a-button>
      <a-button style="margin-left: 8px" type="danger" @click="batchDel"> <a-icon type="delete"/>删除</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择 <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="name" slot-scope="text, record">
          {{text}}
        </template>

        <template slot="returnInfo" slot-scope="text, record">
          <a @click="showConDetails(record)" >
            <span v-if="text > 0">{{text}}</span>
            <span v-else style="color:red">{{text}}</span>
          </a>
        </template>
       

       <template slot="file" slot-scope="text">
           <template v-if="text">
              <a @click="askOrDownFile(text)">点击查看/下载附件</a>
           </template>
        </template>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleDetail(record)">详情</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
    <change-leader-modal ref="changeLeader" @ok="changeLeaderOk" />
    <khcrm-contract-modal ref="modalForm" @ok="modalFormOk"></khcrm-contract-modal>
    <show-contract-details ref="contractdetails" />
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import KhcrmContractModal from './modules/KhcrmContractModal'
  import ChangeLeaderModal from '../customer/modules/ChangeLeaderModal.vue'
  import { Contract } from "@/apis";
  import Vue from 'vue'
  import { USER_INFO } from '@/store/mutation-types'
  import ShowContractDetails from './modules/ShowContractDetails.vue'


  export default {
    name: 'KhcrmContractList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      KhcrmContractModal,
      ChangeLeaderModal,
      ShowContractDetails
    },
    data () {
      return {
        description: 'khcrm_contract管理页面',
        // 表头
        columns: [
          {
            title:'合同名称',
            align:"center",
            fixed:"left",
            ellipsis: true,
            width:147,
            dataIndex: 'name',
            scopedSlots: { customRender: 'name' }

          },
          {
            title:'合同金额(元)',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'money'
          },{
            title:'回款金额(元)',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'returnInfo',
            scopedSlots: { customRender: 'returnInfo' }
          },
          {
            title:'合同编号',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'num'
          },
           {
            title:'商机',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'businessName'
          },
          {
            title:'客户名称',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'customerName'
          },
          
          {
            title:'合同类型',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'type'
          },
           {
            title:'附件',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'fileUrl',
            scopedSlots: { customRender: 'file' }

            
          },
          {
            title:'下单日期',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'orderDate'
          },
          {
            title:'开始时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'startTime' 
          },
          {
            title:'结束时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'endTime' 
          },
          {
            title:'客户联系人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'personName'
          },
          {
            title:'公司签约人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'companyUserName'
          },
          {
            title:'负责人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'ownerName'
          },
          {
            title:'备注',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'remark'
          },
          {
            title:'创建人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'createName'
          },
          {
            title:'创建时间',
            align:"center",
            dataIndex: 'createTime'
          },
           {
            title:'更新时间',
            align:"center",
            dataIndex: 'updateTime'
          },

          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/khcrm/web/khcrmContract/list",
          delete: "/khcrm/web/khcrmContract/delete",
          deleteBatch: "/khcrm/web/khcrmContract/deleteBatch",
        },
        dictOptions:{},
        superFieldList:[],
      }
    },
    created() {
      const userinfo = Vue.ls.get(USER_INFO)
      this.userId = userinfo.userId
      this.queryParam.range = "3"
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      changeOwner(){
        this.$refs.changeLeader.show();
      },
      changeRange(){
        this.loadData(1)
      },
      showConDetails(record){
        this.$refs.contractdetails.show(record);
      },
      changeLeaderOk(userOwnerId){
         var ids = "";
          for (var a = 0; a < this.selectedRowKeys.length; a++) {
            ids += this.selectedRowKeys[a] + ",";
          }
          let param = {
             ids : ids,
             ownerUserId : userOwnerId
          }
          if(ids == ""){
            this.$message.warning("请先选择合同");
            return
          }
          var that = this
          that.loading = true;
          Contract.changeContractOwner(param).then((res) => {
              if (res.code ==200) {
                that.$message.success(res.message);
                that.loadData();
                that.loading = false;
              } else {
                that.$message.warning(res.message);
              }
          }) 
       }
       
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>