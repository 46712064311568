<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="是否上架">
             <a-select  
                   style="width: 100%" 
                   placeholder="请选择是否上架"
                   v-model="queryParam.status"
                   :allowClear="true" >
                  <a-select-option  value="1">
                    是
                  </a-select-option> 
                  <a-select-option  value="0">
                   否
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="优惠券类型">
             <a-select  
                   style="width: 100%" 
                   placeholder="请选择优惠券类型"
                   v-model="queryParam.rangeType"
                   :allowClear="true" >
                  <a-select-option  value="1">
                    全平台通用券
                  </a-select-option> 
                  <a-select-option  value="2">
                    商品分组券
                  </a-select-option>
                  <a-select-option  value="3">
                    商品券
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="门槛类型">
             <a-select  
                   style="width: 100%" 
                   placeholder="请选择门槛类型"
                   v-model="queryParam.thresType"
                   :allowClear="true" >
                  <a-select-option  value="1">
                    无门槛券
                  </a-select-option> 
                  <a-select-option  value="2">
                     满减券
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="addCoupon" type="primary" icon="plus">新增</a-button>
      <a-button @click="batchChangeOnline(1)" type="primary" icon="arrow-up">上架</a-button>
      <a-button @click="batchChangeOnline(0)" type="danger"  icon="arrow-down">下架</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
        注意: 1.系统默认每个优惠券每单只能使用一次,多个优惠券可以叠加使用
      </div>

      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择 <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        @change="handleTableChange">

         <template slot="status" slot-scope="text, record">
          <template  v-if="record.status == 1">
            <a-switch checked-children="已上架"  :checked="true" :loading="switchLoading" @click="showAlertChangeOnline(record.id,0)" />
          </template>
          <template  v-else>
            <a-switch un-checked-children="未上架" :checked="false" :loading="switchLoading" @click="showAlertChangeOnline(record.id,1)"/>
          </template>
        </template>

        
        <template slot="rangeType" slot-scope="text">
          <template  v-if="text == 1">
              <a-tag color="blue">
                全平台通用券
              </a-tag>
          </template>
          <template  v-else-if="text == 2">
              <a-tag color="orange">
                商品分组券
              </a-tag>
          </template>
          <template  v-else-if="text == 3">
             <a-tag color="red">
                商品券
              </a-tag>
          </template>
        </template>

        <template slot="relationId" slot-scope="text, record">
          <template  v-if="text == 1">
               全平台
          </template>
          <template  v-else-if="text == 2">
              <a-tag color="#108ee9">
                分组 
              </a-tag>
               <div :title="record.relationName"> {{record.relationName}}</div>
          </template>
          <template  v-else-if="text == 3" >
             <a-tag color="#108ee9">
                商品 
              </a-tag>
              <div :title="record.relationName"> {{record.relationName}}</div>
          </template>
        </template>

        

         <template slot="thresType" slot-scope="text">
          <template  v-if="text == 1">
              <a-tag color="#f50">
                无门槛券
              </a-tag>
          </template>
          <template  v-else-if="text == 2">
              <a-tag color="#108ee9">
                满减券
              </a-tag>
          </template>
        </template>
        
        <template slot="fullMoney" slot-scope="text, record">
           满 <span style="color:red"> {{record.fullMoney}}元 </span> 
           减 <span style="color:red"> {{record.reduceMoney}}元 </span>
        </template>
   

        <span slot="action" slot-scope="text, record">
            <a-popconfirm title="确定删除吗?" @confirm="() => deletCoupon(record.id)">
                  <a>删除</a>
            </a-popconfirm>
        </span>

      </a-table>
    </div>

    <a-modal
       title="提示"
       :visible="alertVisible"
       :confirm-loading="modalLoading"
       @ok="changeOnline"
       @cancel="handleCancel"
     >
      <p>您确定要上架或者下架这些优惠券吗?</p>
    </a-modal>

    <add-goods-coupon-modal  ref="modalForm" @ok="modalFormOk" />
  </a-card>
</template>
 
<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import AddGoodsCouponModal from './modules/AddGoodsCouponModal.vue'
  import { postAction, getAction } from '@/api/manage'

  // 优惠券管理
  export default {
    name: 'ShopGoodsCouponList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
      AddGoodsCouponModal
    },
    data () {
      return {
        description: '优惠券管理',
        switchLoading:false,
        alertVisible:false,
        modalLoading:false,
        changeOnlineParam:{},
        // 表头
        columns: [
          {
            title:'是否上架',
            align:"center",
            dataIndex: 'status',
            ellipsis: true,
            width:130,
            scopedSlots: { customRender: 'status' },
          },
          {
            title:'优惠券类型',
            align:"center",
            dataIndex: 'rangeType',
            ellipsis: true,
            width:130,
            scopedSlots: { customRender: 'rangeType' },

          },
          {
            title:'门槛类型',
            align:"center",
            dataIndex: 'thresType',
            ellipsis: true,
            width:100,
            scopedSlots: { customRender: 'thresType' },
          },
          {
            title:'满减',
            align:"center",
            dataIndex: 'fullMoney',
            ellipsis: true,
            width:150,
            scopedSlots: { customRender: 'fullMoney' },
          },
          {
            title:'使用范围',
            align:"center",
            dataIndex: 'rangeType',
            ellipsis: true,
            width:150,
            scopedSlots: { customRender: 'relationId' },
          },
          {
            title:'用户可以领取次数',
            align:"center",
            dataIndex: 'canUse',
            ellipsis: true,
            width:100,
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/shop/web/shopCoupon/getShopCouponList",
          changeOnline:"/shop/web/shopCoupon/changeOnline",
          deleteCoupon:"/shop/web/shopCoupon/deleteCoupon",
        },

      }
    },
    created() {
    },
    computed: {
    
    },
    methods: {
       addCoupon(){
          this.$refs.modalForm.add();
       },
       // 批量上下架
       batchChangeOnline(status){
          if(this.selectedRowKeys.length <= 0){
             this.$message.warn("请勾选要操作的列");
             return
          }
        let ids = this.selectedRowKeys.join(",");  
        this.showAlertChangeOnline(ids,status)
       },
       showAlertChangeOnline(ids,status){
          this.alertVisible = true;
          let param = {
             couponIdStr : ids,
             status : status
          }
          this.changeOnlineParam = param;
       },
       changeOnline(){
          let that = this
          this.modalLoading = true
          postAction(this.url.changeOnline,this.changeOnlineParam).then((res)=>{
            if(res.code == 200){
              that.$message.success("操作成功");
              that.handleCancel();
              that.modalFormOk()
            }else{
              that.$message.error(res.message);
            }
          }).finally(() => {
            this.modalLoading = false
          })
       },
       deletCoupon(id){
          let that = this
          let param = {
             couponId : id
          }
          this.modalLoading = true
          postAction(this.url.deleteCoupon,param).then((res)=>{
            if(res.code == 200){
              that.$message.success("删除成功");
              that.modalFormOk()
            }else{
              that.$message.error(res.message);
            }
          }).finally(() => {
            this.modalLoading = false
          })
       },
       modalFormOk(){
         this.loadData();
       },
       handleCancel(){
         this.alertVisible = false
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>