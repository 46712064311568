<template>
    <a-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="submitForm"
    @cancel="handleCancel"
    cancelText="关闭">
     <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
          <a-row>
            <a-col :span="24">
              <a-form-item label="商品分组" :labelCol="labelCol" :wrapperCol="wrapperCol">
               <a-select
                  show-search
                  placeholder="请输选择商品分组"
                  option-filter-prop="children"
                  style="width: 100%"
                  :filter-option="filterOption"
                  v-decorator="['goodsGroupId', { rules: [{ required: true, message: '请请输选择商品分组!' }] }]" 
                >
                  <a-select-option  v-for=" item in shopGroupFormList" :key="item.id" :value="item.id">
                    {{item.groupNameZh}}
                  </a-select-option>
                </a-select>
                 <a-icon style="margin-right:10px;color:#EA7500" type="info-circle" />注意: 此处显示前50条分组信息(按照分组排序),如果超过请设置分组顺序
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品名称(中文)" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input :maxLength="240"  v-decorator="['goodsNameZh', { rules: [{ required: true, message: '请输入商品名称(中文)!' }] }]" placeholder="请输入商品名称(中文)"  ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="24" v-if="openLanguage">
              <a-form-item label="商品名称(英文)" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input :maxLength="240"  v-decorator="['goodsNameEn', { rules: [{ required: true, message: '请输入商品名称(英文)!' }] }]"  placeholder="请输入商品名称(英文)"  ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="24" >
              <a-form-item label="商品服务描述" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <!-- <a-select mode="tags" style="width: 100%" 
                 v-decorator="['serviceDescZh', { rules: [{ required: false, message: '请选择或输入商品服务描述(中文)!' }] }]"
                 placeholder="请选择或输入商品服务描述(中文)">
                      <a-select-option  value="支持7天无理由退货">
                        支持7天无理由退货
                      </a-select-option>
                      <a-select-option  value="支持7天无理由退货(拆封后不支持)">
                        支持7天无理由退货(拆封后不支持)
                      </a-select-option>
                      <a-select-option  value="不支持7天无理由退货">
                        不支持7天无理由退货
                      </a-select-option>
                      <a-select-option  value="保证正品 假一赔十">
                        保证正品 假一赔十
                      </a-select-option>
                </a-select> -->
                <a-input :maxLength="240"  v-decorator="['serviceDescZh', { rules: [{ required: true, message: '请输入商品服务描述!' }] }]" placeholder="请输入商品服务描述"  ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品轮播图" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-upload
                      v-decorator="['rotatImgFile', { rules: [{ required: true, message: '请选择商品轮播图!' }] }]"
                      :file-list="fileList"
                      :before-upload="beforeUpload"
                      accept=".png,.jpg"
                      :remove="handleRemove"
                    >
                  <div v-if="fileList.length < 8">
                      <a-button type="primary"> <a-icon type="upload" />上传轮播图</a-button>
                      <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />注意: 轮播图最多上传8个
                  </div>
               </a-upload>

              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品原价（元）" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number 
                  :max="99999999" :precision="2" :min="0" 
                  v-decorator="['oldMoney', { rules: [{ required: true, message: '请输入商品原价（元）!' }] , initialValue:'9999' }]"
                  placeholder="请输入商品原价（元）" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品现价（元）" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number 
                  :max="99999999" :precision="2" :min="0.01" 
                  v-decorator="['realMoney', { rules: [{ required: true, message: '请输入商品现价（元）!' }] }]"
                  placeholder="请输入商品现价（元）" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品成本（元）" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <a-input-number :max="99999999" :precision="2" :min="0.01" 
                  v-decorator="['profit', { rules: [{ required: true, message: '请输入商品成本（元）!' }]  }]"
                  placeholder="请输入商品成本（元）" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="初始库存" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number 
                  :max="99999999" :precision="0" :min="0" 
                  v-decorator="['stock', { rules: [{ required: true, message: '请输入初始库存!' }] }]"
                  placeholder="请输入初始库存" style="width: 35%" />
                  <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />每次上架商品都会将库存初始化为该值
              </a-form-item>
            </a-col>
            
            <!-- <a-col :span="24">
              <a-form-item label="商品标签" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <a-select 
                  v-decorator="['shopTag', { rules: [{ required: false, message: '' }] }]" :allowClear="true"
                    style="width: 100%" placeholder="请选择商品标签">
                      <a-select-option  value="recommend">
                        精品推荐
                      </a-select-option>
                      <a-select-option  value="minPrice">
                        低价促销
                      </a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col :span="24">
              <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number :max="99999" :min="-99999" :precision="0"
                  v-decorator="['sort', { rules: [{ required: true, message: '请输入排序!' }] , initialValue:'1'}]"
                  placeholder="请输入排序" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品详情图" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-upload
                      v-decorator="['goodsDetailsImgFile', { rules: [{ required: true, message: '请选择商品详情图!' }] }]"
                      :file-list="goodsDetailFileList"
                      accept=".png,.jpg"
                      :before-upload="goodsDetailBeforeUpload"
                      :remove="goodsDetailHandleRemove"
                    >
                  <div v-if="goodsDetailFileList.length < 8">
                      <a-button type="primary"> <a-icon type="upload" />上传详情图</a-button>
                      <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />注意: 详情图为商品下面最详细得介绍,最多上传8个
                  </div>
               </a-upload>
              </a-form-item>
            </a-col>
          </a-row>
     </a-form>
    </a-modal>

</template>

<script>

  import { httpAction, postAction,uploadAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'
  export default {
    name: 'ShopGoodsForm',
    components: {
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        title:'',
        width:800,
        visible: false,
        openLanguage:false,
        fileList: [],
        goodsDetailFileList:[],
 
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        shopGroupFormList:[],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          add: "",
          edit: "",
          createShop:'/shop/web/shop/createShop'
        }
      }
    },
    computed: {
      
    },
    created () {
    
    },
    methods: {
      add (param) {
        this.visible = true;
        this.openLanguage = param.openLanguage;
        this.shopGroupFormList = param.shopGroupList;
        this.title = "新增商品"
        this.fileList = [];
        this.goodsDetailFileList = [];
        this.form.resetFields();
      },
      edit (record) {
        this.visible = true;
      },
      submitForm () {
        this.form.validateFields((err, values) => {
          if (!err) {
            const formData = new FormData();
             for(let i in values){
               let v = values[i]
               if(!isEmpty(v)){
                 if(v.fileList){
                    v.fileList.forEach(file => {
                      if(file.originFileObj){
                          formData.append(i, file.originFileObj);
                      }else{
                          formData.append(i, file);
                      }
                      
                    });
                 }else{
                    formData.append(i, v);
                 }
               }
             }
             this.createShop(formData);
          }
         })
      },
      createShop(formData){
        let that = this
        this.confirmLoading = true
        uploadAction(this.url.createShop,formData).then((res)=>{
          if(res.code == 200){
            that.$message.success("创建商品成功");
            that.close()
            that.$emit('ok');
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
      },
      beforeUpload(file) {
        // this.fileList = [...this.fileList, file];
        // this.fileList = []
        this.fileList = [...this.fileList, file];
        return false;
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      goodsDetailBeforeUpload(file){
        this.goodsDetailFileList = [...this.goodsDetailFileList, file];
        return false;
      },
      goodsDetailHandleRemove(file) {
        const index = this.goodsDetailFileList.indexOf(file);
        const newFileList = this.goodsDetailFileList.slice();
        newFileList.splice(index, 1);
        this.goodsDetailFileList = newFileList;
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      close () {
        this.visible = false;
      },
      handleCancel () {
        this.close()
      }
    }
  }
</script>