<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="回款编号" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="number">
              <a-input v-model="model.number" placeholder="请输入回款编号"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="客户" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="customerId">
              <div @click="selectCustomer">
                  <a-select style="width: 100%" v-model="model.customerId" placeholder="请选择客户" :open="false"  >
                    <template v-for="(item,i) in cusrecord" >
                          <a-select-option :key="i" :value="item.id">{{item.name}}</a-select-option>
                    </template>
                  </a-select>
              </div>  
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>  
          <a-col :span="12">
            <a-form-model-item label="合同" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="contractId">
              <div @click="selectContract">
                  <a-select style="width: 100%" v-model="model.contractId" placeholder="请选择合同" :open="false"  >
                    <template v-for="(item,i) in conrecord" >
                          <a-select-option :key="i" :value="item.id">{{item.name}}</a-select-option>
                    </template>
                  </a-select>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="回款日期" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="returnTime">
              <j-date placeholder="请选择回款日期" v-model="model.returnTime" style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>            
          <a-col :span="12">
            <a-form-model-item label="回款方式" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="returnType">
              <a-select v-model="model.returnType" placeholder="请选择回款方式">
                <a-select-option value="现金">现金</a-select-option>
                <a-select-option value="微信">微信</a-select-option>
                <a-select-option value="支付宝">支付宝</a-select-option>
                <a-select-option value="支票">支票</a-select-option>
                <a-select-option value="网上转账">网上转账</a-select-option>
                <a-select-option value="银行卡汇款">银行卡汇款</a-select-option>
                <a-select-option value="其他">其他(请备注说明)</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="回款金额" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="money">
              <a-input-number v-model="model.money" :min="0" placeholder="请输入回款金额" style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>   
          <a-col :span="12">
             <a-form-model-item label="收款凭证图片" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="file">
              <a-upload :file-list="fileList" accept="image/*"  :multiple="false" :remove="handleRemove" :before-upload="beforeUpload">
                <a-button> <a-icon type="upload" />点击上传</a-button>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>   
          <a-col :span="24">
            <a-form-model-item label="备注" prop="remark">
              <a-textarea v-model="model.remark" rows="3" placeholder="请输入备注" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
    <select-contract-modal ref="contractForm" @ok="contractFormOk" />
    <select-customer-modal ref="modalForm" @ok="modalFormOk" />
  </a-spin>
</template>

<script>

  import { httpAction, uploadAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'
  import JFormContainer from '@/components/jeecg/JFormContainer'
  import JDate from '@/components/jeecg/JDate.vue'
  import SelectCustomerModal from '../../customer/modules/SelectCustomerModal.vue'
  import SelectContractModal from '../../contract/modules/SelectContractModal.vue'


  export default {
    name: 'KhcrmReturnMoneyForm',
    components: {
      JFormContainer,
      JDate,
      SelectCustomerModal,
      SelectContractModal
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        model:{
         },
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
        cusrecord:[],
        confirmLoading: false,
        conrecord:[],
        fileList:[],
        validatorRules: {
           number: [
              { required: false, message: '请输入回款编号!'},
           ],
           customerId: [
              { required: false, message: '请选择客户!'},
           ],
           contractId: [
              { required: false, message: '请选择合同!'},
           ],
           returnTime: [
              { required: true, message: '请选择回款日期!'},
           ],
           returnType: [
              { required: true, message: '请输入回款方式!'},
           ],
           money: [
              { required: true, message: '请输入回款金额!'},
           ],
        },
        url: {
          add: "/khcrm/web/khcrmReturnMoney/add",
          edit: "/khcrm/web/khcrmReturnMoney/edit",
          queryById: "/khcrm/web/khcrmReturnMoney/queryById"
        }
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        let c = {
          id:record.customerId,
          name:record.customerName
        }
        if(record.fileUrl){
          this.fileList = [
            {
                uid: 'kczaeq1',
                name: '凭证图片',
                status: 'done',
                url: '',
              },
          ]
        }else{
          this.fileList = []
        }
        this.cusrecord = [];
        this.cusrecord.push(c)
        this.model.customerId = c.id
        
        let con = {
          id:record.contractId,
          name:record.contractName
        }
 
        this.conrecord = [];
        this.conrecord.push(con)
        this.model.contractId = con.id

        this.model = Object.assign({}, record);
        this.visible = true;
      },
      selectCustomer(){
          this.$refs.modalForm.show();
      },
      // 文件移除
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      } ,
      // 文件上传
      beforeUpload(file) {
        this.fileList = []
        this.fileList = [...this.fileList, file];
        return false;
      } ,
      modalFormOk(e){
          this.cusrecord = [];
          this.cusrecord.push(e)
          this.model.customerId = e.id
          delete this.model['contractId']
      },
      selectContract(){
        let curid = this.model.customerId;
          if(curid){
              this.$refs.contractForm.show(curid);
          }else{
             this.$message.warning("请先选择客户！");
          }
      },
      contractFormOk(e){
          this.conrecord = [];
          this.conrecord.push(e)
          this.model.contractId = e.id
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            let httpurl = '';
            if(!this.model.id){
              httpurl+=this.url.add;
            }else{
              httpurl+=this.url.edit;
            }
            const fileList = this.fileList;
            const formData = new FormData();
            if(fileList.length >0){
              if(fileList[0].uid != 'kczaeq1'){
                  fileList.forEach(file => {
                    formData.append('file', file);
                  });
              }
            }
            for(let i in this.model){
              if(this.model[i]){
                  formData.append(i, this.model[i]); 
              }
            }
            that.confirmLoading = true;
            uploadAction(httpurl,formData).then((res)=>{
              if(res.code ==200){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },
    }
  }
</script>