<template>
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
        <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
          <a-row>
              <a-col :span="24">
                <a-form-item label="首页轮播图">
                  <a-upload
                        v-decorator="['file', { rules: [{ required: true, message: '请选择首页轮播图!' }] }]"
                        :file-list="fileList"
                        :before-upload="beforeUpload"
                        :multiple="true"
                        accept=".png,.jpg"
                        :remove="handleRemove"
                      >
                    <div>
                        <a-button type="primary"> <a-icon type="upload" />上传轮播图</a-button>
                        
                    </div>
                </a-upload>
                </a-form-item>
               </a-col>
              <a-col :span="24">
                <a-form-item label="关联商品id">
                       <a-input :maxLength="50"  v-decorator="['goodsId', { rules: [{ required: false, message: '' }] }]" placeholder="请输入关联商品id"  ></a-input>
                      <a-icon style="margin-right:10px;color:#EA7500" type="info-circle" />当此处填写商品id后,用户可以点击图片跳转到该商品详情
                      <br/><a-icon style="margin-right:10px;color:#EA7500" type="info-circle" />商品id值可以在【商品管理】-【商品维护】列表中查看
                </a-form-item>
               </a-col>
              <a-col :span="24">
                <a-form-item label="排序"  >
                  <a-input-number :max="99999" :min="-99999" :precision="0"
                    v-decorator="['sort', { rules: [{ required: true, message: '请输入排序!' }] , initialValue:'1'}]"
                    placeholder="请输入排序" style="width: 30%" />
                </a-form-item>
              </a-col>
          </a-row>
        </a-form>
    </a-modal>  

</template>

<script>

  import { postAction, getAction,uploadAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'
  export default {
    name: 'RotationFormModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:800,
        visible: false,
        openLanguage:false,
        confirmLoading: false,
        fileList:[],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          createRotation: "/shop/web/shopGoodsNotice/createRotation",
      
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      add () {
        this.fileList=[]
        this.form.resetFields();
        this.visible = true;
        this.title = "新增轮播图"
      },
      submitForm () {
        this.form.validateFields((err, values) => {
          if (!err) {
            const formData = new FormData();
             for(let i in values){
               let v = values[i]
               if(!isEmpty(v)){
                 if(v.fileList){
                    v.fileList.forEach(file => {
                      if(file.originFileObj){
                          formData.append(i, file.originFileObj);
                      }else{
                          formData.append(i, file);
                      }
                      
                    });
                 }else{
                    formData.append(i, v);
                 }
               }
            }
            this.createRotation(formData);
          }
         })
      },
      // 创建轮播图
      createRotation(formData){
        let that = this
        this.confirmLoading = true
        uploadAction(this.url.createRotation,formData).then((res)=>{
          if(res.code == 200){
            that.$message.success("创建成功");
            that.handleCancel()
            that.$emit('ok');
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
      },
      beforeUpload(file) {
        this.fileList = []
        this.fileList = [...this.fileList, file];
        return false;
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      handleCancel () {
        this.visible = false;
      },
    }
  }
</script>