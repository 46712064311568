<template>
    <a-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @cancel="handleCancel"
    :footer="null">
     <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
          <a-row>
            <a-col :span="24">
                <a-form-item label="商品是否上架" >
                  <template v-if="record.status == 1">
                      <a-tag color="green">
                          已上架
                      </a-tag>
                      <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
                           商品上架后 【商品现价】【初始库存量】无法修改
                  </template>
                   <template v-else>
                      <a-tag color="red" >
                          未上架
                      </a-tag>
                  </template>
                </a-form-item> 
              </a-col>
            <a-col :span="24">
              <a-form-item label="规格名称(中文)" >
                 {{record.specsNameZh}}
                 <a class="update-shop-edit" @click="showModalInput('规格名称(中文)','specsNameZh',record.specsNameZh)"> <a-icon type="edit" /></a> 
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="规格图">
                  <img  :src="record.readPath + record.specsImg" width="150px" height="150px" />
                  <a @click="showModalUpload('规格图','specsImg',record.specsImg)" class="update-shop-edit"> <a-icon type="edit" /></a>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品现价（元）">
                ￥ {{record.realMoney}}
                 <a class="update-shop-edit"  @click="showModalMoney('商品现价（元）','realMoney',record.realMoney)" v-if="record.status == 0" > <a-icon type="edit" /></a> 
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="初始库存量">
                {{record.stock}}
                <a @click="showModalStock('初始库存量','stock',record.stock)" v-if="record.status == 0"
                        class="update-shop-edit"> <a-icon type="edit" /></a> 
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品成本（元）">
                ￥ {{record.profit}}
                 <a class="update-shop-edit"  @click="showModalMoney('商品成本（元）','profit', record.profit)" > <a-icon type="edit" /></a> 
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="排序">
                {{record.sort}}
                <a @click="showModalSort('排序','sort',record.sort)"
                        class="update-shop-edit"> <a-icon type="edit" /></a> 
              </a-form-item>
            </a-col>
          </a-row>
     </a-form>


     <a-modal
          title="修改规格信息"
          :visible="alertVisible"
          :width="600"
          @ok="updateShopSpecs"
          @cancel="modalCancel"
          :confirm-loading="modalLoading"
        >
          <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18}">
              <a-form-item :label="updateLabel">
                <a-input v-if="showType =='input'" :maxLength="240"  v-decorator="[updateFiled, { rules: [{ required: true, message: '请输入' + updateLabel }], initialValue:filedValue }]"  ></a-input>
                <a-input-number v-else-if="showType =='money'" style="width: 100%"  :max="99999999" :precision="2" :min="0"  v-decorator="[updateFiled, { rules: [{ required: true, message: '请输入' + updateLabel }], initialValue:filedValue }]"  ></a-input-number>
                <a-input-number v-else-if="showType =='sort'" :max="99999" :min="-99999" :precision="0"
                  v-decorator="['sort', { rules: [{ required: true, message: '请输入排序' }] , initialValue:filedValue}]"
                   style="width: 100%" />
                <a-input-number 
                  :max="99999999" v-else-if="showType =='stock'" :precision="0" :min="0" 
                  v-decorator="['stock', { rules: [{ required: true, message: '请输入初始库存' }], initialValue:filedValue }]"
                  style="width: 35%" />   
                 <a-upload v-else-if="showType =='upload'"
                      v-decorator="[updateFiled, { rules: [{ required: true, message: '请选择图片!' }]  }]"
                      :file-list="fileList"
                      accept=".png,.jpg"
                      :before-upload="beforeUpload"
                      :remove="handleRemove"
                    >
                  <div>
                      <a-button type="primary"> <a-icon type="upload" />上传</a-button>
                      <a-icon style="margin-left:10px;margin-right:10px;color:#EA7500" type="info-circle" />上传后会覆盖当前{{updateLabel}}
                  </div>
                 </a-upload>
              </a-form-item>
          </a-form>
      </a-modal>
    </a-modal>

</template>

<script>

  import { httpAction, postAction,uploadAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'

  // 修改规格
  export default {
    name: 'UpdateShopSpecsModal',
    components: {
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        title:'商品规格',
        width:800,
        showType:'',
        alertVisible:false,
        updateLabel:'',
        updateFiled:'',
        filedValue:"",
        visible: false,
        openLanguage:false,
        fileList: [],
        confirmLoading: false,
        modalLoading:false,
        isGoToUpdate:false,
        shopGroupFormList:[],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        record:{},
        url: {
          updateShopSpecs:'/shop/web/shop/updateGoodsSpecs'
        }
      }
    },
    computed: {
      
    },
    created () {
    
    },
    methods: {
      show (param) {
        this.record = param
        this.openLanguage = param.openLanguage
        console.log(param)
        this.isGoToUpdate = false;
        this.visible = true;
        this.record = param;
        this.form.resetFields();
      },
      // 展示modal 输入框
      showModalInput(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'input';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalMoney(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'money';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalSort(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'sort';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalStock(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'stock';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalUpload(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'upload';
        this.fileList = []
        this.form.resetFields();
        this.alertVisible = true
      },
      // 修改规格
      updateShopSpecs(){
        let that = this
        this.form.validateFields((err, values) => {
          if (!err) {
             const formData = new FormData();
             formData.append("specsId", this.record.id);
             for(let i in values){
               let v = values[i]
               if(v == 0){
                  v = '0'
               }
               if(!isEmpty(v)){
                 if(v.fileList){
                    v.fileList.forEach(file => {
                      if(file.originFileObj){
                          formData.append(i, file.originFileObj);
                      }else{
                          formData.append(i, file);
                      }
                    });
                 }else{
                    formData.append(i, v);
                 }
               }
             }
            this.modalLoading = true
            uploadAction(this.url.updateShopSpecs,formData).then((res)=>{
              if(res.code == 200){
                that.isGoToUpdate = true
                that.record[that.updateFiled] = res.data[that.updateFiled]
                that.$message.success("修改成功");
                that.modalCancel();
              }else{
                that.$message.error(res.message);
              }
            }).finally(() => {
                that.modalLoading = false;
            }) 
          }})
      },
      beforeUpload(file) {
        this.fileList = [];
        this.fileList = [...this.fileList, file];
        return false;
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      close () {
        this.visible = false;
      },
      handleCancel () {
        if(this.isGoToUpdate){
           this.$emit('ok');
        }
        this.close()
      },
      modalCancel(){
        this.alertVisible = false
      }
    }
  }
</script>
<style scoped>
 .update-shop-edit{
   margin-left: 10px;
 }
</style>