<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24" class="searchclass" >
          <a-col :md="8" :sm="10">
            <a-form-item label="">
              <a-input-search v-model="queryParam.name" :maxLength="200" placeholder="请输联系人名称/手机号" enter-button="搜索" size="default"
                  @search="searchQuery" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24"  >
          <a-col :md="6" :sm="8">
            <a-form-item label="联系人范围">
              <a-select v-model="queryParam.range"  @change="changeRange" placeholder="请选择">
                <a-select-option value="1">全部客户联系人</a-select-option>
                <a-select-option value="2">本部门的客户联系人</a-select-option>
                <a-select-option value="3">我负责的客户联系人</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-button type="primary" @click="changeOwner"> <a-icon type="retweet" />变更负责人</a-button>
      <a-button style="margin-left: 8px" type="danger" @click="batchDel"> <a-icon type="delete"/>删除</a-button>
    </div>


    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择 <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="rowSelection"
        class="j-table-force-nowrap"
        @change="handleTableChange">
        
        <template slot="isLeader" slot-scope="text">
            <a-tag v-if="text==0" color="#108ee9">否</a-tag>
            <a-tag v-else color="red">是</a-tag>
        </template>


        <span slot="action" slot-scope="text, record" v-if="record.ownerUserId == userId">
          <a @click="handleEdit(record)">编辑</a>

          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleDetail(record)">详情</a>
              </a-menu-item>
              <a-menu-item>
                    <a @click="findOpt(record)">操作记录</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="followup(record)">写跟进</a>
                  </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>

      </a-table>
    </div>
    <opt-log-modal  ref="optLogForm"  />
    <followup-modal :tableType="'3'" ref="followupForm" @ok="followupFormOk" />
    <change-leader-modal ref="changeLeader" @ok="changeLeaderOk" />
    <khcrm-person-modal ref="modalForm" @ok="modalFormOk"></khcrm-person-modal>
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import KhcrmPersonModal from './modules/KhcrmPersonModal'
  import { Leads,Person } from "@/apis";
  import ChangeLeaderModal from '../customer/modules/ChangeLeaderModal.vue'
  import Vue from 'vue'
  import { USER_INFO } from '@/store/mutation-types'
  import FollowupModal from '../common/FollowupModal.vue'
  import OptLogModal from '../common/OptLogModal.vue'

  export default {
    name: 'KhcrmPersonList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      KhcrmPersonModal,
      ChangeLeaderModal,
      FollowupModal,
      OptLogModal
    },
    data () {
      return {
        description: 'khcrm_person管理页面',
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
        // 表头
        columns: [
          {
            title:'联系人名称',
            align:"center",
            ellipsis: true,
            width:147,
            fixed:"left",
            dataIndex: 'name'
          },
           {
            title:'客户名称',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'customerName'
          },
          {
            title:'性别',
            align:"center",
            ellipsis: true,
            width:80,
            dataIndex: 'sex'
          },
          {
            title:'手机',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'mobile'
          },
          {
            title:'职务',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'post'
          },
          {
            title:'是否关键决策人',
            align:"center",
            ellipsis: true,
            width:80,
            dataIndex: 'isLeader',
            scopedSlots: { customRender: 'isLeader' }

          },
          {
            title:'负责人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'ownerName'
          },
          {
            title:'下次联系时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'nextTime'
          },
          
          {
            title:'电话',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'telphone'
          },
          {
            title:'电子邮箱',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'email'
          },
          {
            title:'地址',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'address'
          },
          {
            title:'备注',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'remark'
          },
          {
            title:'最后跟进时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'lastTime'
          },
          
          {
            title:'创建人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'createName'
          },
          
          {
            title:'创建时间',
            align:"center",
            dataIndex: 'createTime'
          },
           {
            title:'更新时间',
            align:"center",
            dataIndex: 'updateTime'
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/khcrm/web/person/list",
          delete: "/khcrm/web/person/delete",
          deleteBatch: "/khcrm/web/person/deleteBatch",
          exportXlsUrl: "/khcrm/web/person/exportXls",
          importExcelUrl: "/khcrm/web/person/importExcel",
          
        },
        dictOptions:{},
        superFieldList:[],
        userOwnerId:undefined,
        userId:""
      }
    },
    created() {
      const userinfo = Vue.ls.get(USER_INFO)
      this.userId = userinfo.userId
      this.queryParam.range = "3"
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
      rowSelection() {
          const { selectedRowKeys } = this;
          return {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.selectedRowKeys = selectedRowKeys;
                this.selectionRows = selectedRows;
            },
            getCheckboxProps: record => ({
              props: {
                disabled: !(record.ownerUserId == this.userId || (record.rwUserId != null && record.rwUserId.indexOf(this.userId) >=0))
                ,
                selectionRows: this.selectionRows
              },
            }) 
         };
      }
    },
    methods: {
      initDictConfig(){
      },
      changeOwner(){
        this.$refs.changeLeader.show();
      },
      changeRange(){
        this.loadData(1)
      },
      followup(r){
        r.tableType = 3
        let param = {
          businessId : r.customerId,
          tableType : r.tableType,
          type:1
        }
        this.$refs.followupForm.show(r,param);
      },
      findOpt(r){
        r.tableType = 3
        let param = {
          businessId : r.id,
          tableType : r.tableType,
          type:2
        }
         this.$refs.optLogForm.show(r,param); 
      },
       followupFormOk(){
         this.loadData(1)
       },
      changeLeaderOk(userOwnerId){
         var ids = "";
          for (var a = 0; a < this.selectedRowKeys.length; a++) {
            ids += this.selectedRowKeys[a] + ",";
          }
          if(ids == ""){
            this.$message.warning("请先选择联系人");
            return
          }
          let param = {
             ids : ids,
             ownerUserId : userOwnerId
          }

          var that = this
          that.loading = true;
          Person.changePersonOwner(param).then((res) => {
              if (res.code ==200) {
                that.$message.success(res.message);
                that.loadData();
                that.changeOwnerVisible = false
                that.loading = false;
              } else {
                that.$message.warning(res.message);
              }
          }) 
       } 
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>