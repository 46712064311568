<template>
   <div>
        <a-drawer
          title="操作记录"
          placement="right"
          :closable="true"
          :visible="visible"
          :width="400"
          @close="onClose"
        >
              <div style="height:550px;overflow:auto;padding-top:20px;padding-bottom:50px">
                
                  <a-timeline>
                    <template v-for="(item,index) in dataList">
                          <a-timeline-item :key="index" >
                              <div style="color:#333;font-size:14px">{{item.createName}}  <span style="color:#A9A9A9;font-size:13px">{{item.createTime}}</span></div>
                              <div style="margin-top:10px;color:#333;">{{item.content}} </div>
                          </a-timeline-item>
                    </template>
                  </a-timeline>
              </div>
        </a-drawer>
  </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { Business,Leads } from "@/apis";
  import JImageUpload from '@/components/jeecg/JImageUpload'
  import JUpload from '@/components/jeecg/JUpload'

  export default {
    // 跟进
    name: 'OptLogModal',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate,
      JImageUpload,
      JUpload
        
    },
    data () {
      return {
        visible: false,
        modelVisible:false,
        fileList: [],
        dataList:[],
        uploading: false,
        confirmLoading:false,
        drawerLoading:false,
        model:{},
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
        content:"",
        record:'',
        sendParam:""
      }
    },
    computed: {
     
    },
    created() {
       
    },
    methods: {
      onClose() {
        this.visible = false;
      },
      show(r,param){
       this.drawerLoading = true
       this.record = r
       this.sendParam = param
       Leads.findFollowUp(param).then((res)=>{
            if(res.code == 200){
              this.dataList = res.data
            }else{
              this.$message.warning(res.message);
            }
            this.drawerLoading = false;
        })
       this.visible = true;
      },
      loadData(arg) {
        
      },
      findBusinessByCustomerId(arg) {
        //加载数据 若传入参数1则加载第一页的内容
        if (arg === 1) {
          this.ipagination.current = 1;
        }
        var params = this.getQueryParams();//查询条件
        params.customerId = this.customerId
        this.loading = true;
        Business.findBusinessByCustomerId(params).then((res)=>{
            if(res.code == 200){
              this.busdataSource = res.data || [];
              this.ipagination.total = res.total || 0;
            }else{
              this.$message.warning(res.message);
            }
             this.loading = false;
             this.loadflag = true
        })
      },
      handleOk(){
        this.cusvisible = false
      },
      handleCancel(){
         this.cusvisible = false
      },
    }
  }
</script>