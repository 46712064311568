import { getAction, postAction, formAction, formAction2 } from '@/api/manage'
const url = {
    // 锁定解锁
    toLock: '/khcrm/web/khcrmCustomer/toLock',
     // 修改成交状态
    changeDealStatus: '/khcrm/web/khcrmCustomer/changeDealStatus',
    //修改负责人
    changeCustomerOwner: '/khcrm/web/khcrmCustomer/changeCustomerOwner',
}
export default {
    toLock(params) {
        return postAction(url.toLock, params);
    },
    changeDealStatus(params) {
        return postAction(url.changeDealStatus, params);
    },
    changeCustomerOwner(params) {
        return postAction(url.changeCustomerOwner, params);
    },
  
}
