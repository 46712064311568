<template>
  <a-card :bordered="false">
      <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="10">
            <a-form-item label="手机号">
              <a-input
                allowClear
                placeholder="请输入手机号或手机号后4位"
                v-model="queryParam.phone"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="10">
            <a-form-item label="会员姓名">
              <a-input
               allowClear
                placeholder="请输入会员姓名"
                v-model="queryParam.memberName"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="8">
            <span
              style="float: left; overflow: hidden;margin-left:10px"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 15px">刷新</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->



    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <a-form layout="inline">
            <a-row >
              <a-col :md="5" :sm="10">
                <a-form-item label="当日散客累计消费">
                  <span style="color:red;font-weight:600">{{otherDataSource.fitSale}}元</span>
                </a-form-item>
              </a-col>
              <a-col :md="5" :sm="10">
                <a-form-item label="当日会员累计消费">
                  <span style="color:red;font-weight:600">{{otherDataSource.memberSale}}元</span>
                </a-form-item>
              </a-col>
              <a-col :md="5" :sm="10">
                <a-form-item label="当日总累计消费">
                  <span style="color:red;font-weight:600">{{otherDataSource.totalSale}}元</span>
                </a-form-item>
              </a-col>
              <a-col :md="4" :sm="10">
                <a-form-item label="会员总人数">
                  <span style="color:red;font-weight:600">{{otherDataSource.memberNum}}人</span>
                </a-form-item>
              </a-col>
              <a-col :md="5" :sm="10">
                <a-form-item label="会员总剩余金额">
                  <span style="color:red;font-weight:600">{{otherDataSource.memberLeaveMoney}}元</span>
                </a-form-item>
              </a-col>
            </a-row>
        </a-form>
      </div>
          <!-- 操作按钮区域 -->
      <div class="table-operator">
        <a-button @click="addMember" type="primary" icon="plus">录入会员</a-button>
      </div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">
 
        <template slot="phone" slot-scope="text, record">
           <div v-if="record.memberType == 1">
            <span style="color:red;font-weight:600">散客</span>
           </div>
           <div v-else>
             {{ text }}
           </div>
        </template>

        <template slot="memberName" slot-scope="text, record">
           <div v-if="record.memberType == 1">
            <span style="color:red;font-weight:600">散客</span>
           </div>
           <div v-else>
             {{ text }}  <a @click="updateMember(record)"><a-icon type="edit"  /></a>
           </div>
        </template>

        <template slot="money" slot-scope="text, record">
           <div v-if="record.memberType == 1">
             -
           </div>
           <div v-else>
              <template v-if="text <= 100" >
                  <div style="color:red;font-weight:600">{{ text }} 元</div>
              </template>
              <template v-else>
                  <div style="color:#000;font-weight:600">{{ text }} 元</div>
              </template>
           </div>
        </template>

        <template slot="createTime" slot-scope="text, record">
           <div v-if="record.memberType == 1">
             -
           </div>
           <div v-else>
             {{ text }}
           </div>
        </template>

        <template slot="recordLog" slot-scope="text, record">
          <a @click="showLog(record, 1)">点击查看消费记录</a>
        </template>

        <template slot="buyLog" slot-scope="text, record">
          <div v-if="record.memberType == 1">
             -
           </div>
           <div v-else>
            <a @click="showLog(record, 2)">点击查看充值记录</a>
           </div>
        </template>



        <span slot="action" slot-scope="text, record">
          <a-button @click="userMoney(record)" type="primary" >消费</a-button>
          <a-button v-if="record.memberType != 1" @click="buyMoney(record)" type="danger" style="margin-left:20px">充值</a-button>

        </span>

      </a-table>
    </div>

    <member-modal  ref="modalForm" @ok="modalFormOk" />
    <use-modal ref="useForm" @ok="modalFormOk" />
    <buy-modal ref="buyForm" @ok="modalFormOk" />
    <record-log-modal ref="recordLogForm"  />
    <update-member-modal ref="updateMemberInfoForm" @ok="modalFormOk"></update-member-modal>
  </a-card>
</template>
 
<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import MemberModal from './modules/member/MemberModal'
  // 消费
  import UseModal from './modules/member/UseModal'

  // 充值
  import BuyModal from './modules/member/BuyModal'
   // 记录
  import RecordLogModal from './modules/member/RecordLogModal'
  import UpdateMemberModal from './modules/member/UpdateMemberModal.vue'


  // 理发---会员管理
  export default {
    name: 'MemberList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      MemberModal,
      UseModal,
      BuyModal,
      RecordLogModal,
      UpdateMemberModal
    },
    data () {
      return {
        description: '理发会员管理',
        // 表头
        columns: [
          {
            title:'手机号',
            align:"center",
            dataIndex: 'phone',
            ellipsis: true,
            width:140,
            scopedSlots: { customRender: 'phone' }
          },
          {
            title:'姓名',
            align:"center",
            dataIndex: 'memberName',
            ellipsis: true,
            width:140,
            scopedSlots: { customRender: 'memberName' }
          },
          {
            title:'剩余金额(元)',
            align:"center",
            dataIndex: 'money',
            ellipsis: true,
            width:100,
            scopedSlots: { customRender: 'money' }
          },
          {
            title:'录入时间',
            align:"center",
            dataIndex: 'createTime',
            ellipsis: true,
            width:130,
            scopedSlots: { customRender: 'createTime' }
          },
          {
            title:'备注',
            align:"center",
            dataIndex: 'remark',
            ellipsis: true,
            width:180,
          },
          {
            title:'消费记录',
            align:"center",
            dataIndex: 'orderBySome',
            ellipsis: true,
            width:110,
            scopedSlots: { customRender: 'recordLog' }
          },
          {
            title:'充值记录',
            align:"center",
            dataIndex: 'id',
            ellipsis: true,
            width:110,
            scopedSlots: { customRender: 'buyLog' }
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            width:150,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/khcrm/web/haircut/getMemberList",
        },

      }
    },
    created() {
    },
    computed: {
    
    },
    methods: {
       addRotation(){
          this.$refs.modalForm.add();
       },
       addMember(){
        this.$refs.modalForm.add();
       },
       // 消费
       userMoney(record){
         this.$refs.useForm.show(record);
       },
       // 充值
       buyMoney(record){
         this.$refs.buyForm.show(record);
       },
       // 查询消费or充值记录
       showLog(record, type){
         this.$refs.recordLogForm.show(record, type);
       },
       // 修改会员信息
       updateMember(record){
         this.$refs.updateMemberInfoForm.update(record);
       },
       modalFormOk(){
          this.loadData();
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>