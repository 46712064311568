<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="账号">
              <!--<a-input placeholder="请输入账号查询" v-model="queryParam.username"></a-input>-->
              <a-input
                placeholder="输入账号查询"
                v-model="queryParam.username"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="手机号">
              <!--<a-input placeholder="请输入账号查询" v-model="queryParam.username"></a-input>-->
              <a-input
                placeholder="输入手机号查询"
                v-model="queryParam.telphone"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="用户状态">
              <a-select v-model="queryParam.status" placeholder="请选择">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="0">正常</a-select-option>
                <a-select-option :value="1">冻结</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
              <a-button
                type="primary"
                @click="searchReset"
                icon="reload"
                style="margin-left: 8px"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator" style="border-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus"
        >添加用户</a-button
      >
    </div>

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        bordered
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange"
      >
        <template slot="avatarslot" slot-scope="text, record, index">
          <div class="anty-img-wrap">
            <a-avatar
              shape="square"
              :src="getAvatarView(record.avatar)"
              icon="user"
            />
          </div>
        </template>

         <span slot="status" slot-scope="text, record">
            <a-tag v-if="text==0" color="#87d068">正常</a-tag>
            <a-tag v-else color="#f50">冻结</a-tag>
        </span>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>

          <a-divider type="vertical" />

          <a-dropdown>
            <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="handleDetail(record)">详情</a>
              </a-menu-item>

              <a-menu-item>
                <a href="javascript:;" @click="handleChangePassword(record)"
                  >密码</a
                >
              </a-menu-item>

              <a-menu-item v-if="record.status == 0">
                <a-popconfirm
                  title="确定冻结吗?"
                  @confirm="() => handleFrozen(record)"
                >
                  <a>冻结</a>
                </a-popconfirm>
              </a-menu-item>

              <a-menu-item v-if="record.status == 1">
                <a-popconfirm
                  title="确定解冻吗?"
                  @confirm="() => handleFrozen(record)"
                >
                  <a>解冻</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->

    <user-modal ref="modalForm" @ok="modalFormOk"></user-modal>

    <password-modal ref="passwordmodal" @ok="passwordModalOk"></password-modal>
  </a-card>
</template>

<script>
import UserModal from "./modules/UserModal";
import PasswordModal from "./modules/PasswordModal";
import { putAction, getFileAccessHttpUrl } from "@/api/manage";
import { frozenBatch } from "@/api/api";
import { JeecgListMixin } from "@/mixins/JeecgListMixin";
import JInput from "@/components/jeecg/JInput";

export default {
  name: "UserList",
  mixins: [JeecgListMixin],
  components: {
    UserModal,
    PasswordModal,
    JInput,
  },
  data() {
    return {
      description: "这是用户管理页面",
      queryParam: {},
      columns: [
        /*{
            title: '#',
            dataIndex: '',
            key:'rowIndex',
            width:60,
            align:"center",
            customRender:function (t,r,index) {
              return parseInt(index)+1;
            }
          },*/
        {
          title: "用户账号",
          align: "center",
          dataIndex: "username",
          width: 120,
        },
        {
          title: "用户姓名",
          align: "center",
          width: 100,
          dataIndex: "realname",
        },
        {
          title: "所属公司",
          align: "center",
          width: 180,
          dataIndex: "departName",
        },
        {
          title: "所属角色",
          align: "center",
          width: 130,
          dataIndex: "roleName",
        },
        {
          title: "手机号码",
          align: "center",
          width: 130,
          dataIndex: "telphone",
        },
        {
          title: "状态",
          align: "center",
          width: 80,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },

        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: 120,
        },
      ],
      url: {
        syncUser: "/process/extActProcess/doSyncUser",
        list: "/khcrm/web/sysuser/getUserList",
        delete: "/sys/user/delete",
      },
    };
  },

  methods: {
    getAvatarView: function (avatar) {
      return getFileAccessHttpUrl(avatar);
    },

    handleMenuClick(e) {
      if (e.key == 1) {
        this.batchDel();
      } else if (e.key == 2) {
        this.batchFrozen(2);
      } else if (e.key == 3) {
        this.batchFrozen(1);
      }
    },
    handleFrozen: function ({ id, status }) {
      frozenBatch({ id, status: status ? 0 : 1 }).then((res) => {
        const { code } = res;
        if (code == 200) {
          this.$message.success(res.message);
          this.loadData();
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    handleChangePassword(record) {
      this.$refs.passwordmodal.show(record);
    },
    passwordModalOk() {
      //TODO 密码修改完成 不需要刷新页面，可以把datasource中的数据更新一下
    },
  },
};
</script>
<style scoped>
@import "~@assets/less/common.less";
</style>