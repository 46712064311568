<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24" class="searchclass" >
          <a-col :md="8" :sm="10">
            <a-form-item label="">
              <a-input-search v-model="queryParam.businessName" :maxLength="200" placeholder="请输入商机名称" enter-button="搜索" size="default"
                  @search="searchQuery" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24"  >
          <a-col :md="6" :sm="8">
            <a-form-item label="客户场景">
              <a-select v-model="queryParam.range" @change="changeRange" placeholder="请选择">
                <a-select-option value="1">全部客户</a-select-option>
                <a-select-option value="2">本部门的客户</a-select-option>
                <a-select-option value="3">我负责的客户</a-select-option>
                <!-- <a-select-option value="4">我参与的客户</a-select-option> -->
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-button type="primary"  @click="changeOwner"> <a-icon type="retweet" />变更负责人</a-button>
      <a-button style="margin-left: 8px" type="danger" @click="batchDel"> <a-icon type="delete"/>删除</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择 <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="rowSelection"
        class="j-table-force-nowrap"
        @change="handleTableChange">



        <template slot="statusEnd" slot-scope="text">
            <a-tag v-if="text==0" color="#108ee9">未开始</a-tag>
            <a-tag v-if="text==2"  color="#77ac98">已开始</a-tag>
            <a-tag v-if="text==4"  color="#52c41a">赢单</a-tag>
            <a-tag v-if="text==6"  color="#f50">输单</a-tag>
            <a-tag v-if="text==8"  color="#46485f">无效单</a-tag>
        </template>


        <template slot="followup" slot-scope="text">
            <a-tag v-if="text==0" color="#108ee9">未跟进</a-tag>
            <a-tag v-if="text==1"  color="#87d068">已跟进</a-tag>
        </template>


        <span slot="action" slot-scope="text, record">
          <span v-if="record.ownerUserId == userId">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleDetail(record)">详情</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="findOpt(record)">操作记录</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="followup(record)">写跟进</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          </span>
          
        </span>

      </a-table>
    </div>
     <opt-log-modal  ref="optLogForm"  />
     <followup-modal :tableType="tableType" ref="followupForm" @ok="followupFormOk" />
    <change-leader-modal ref="changeLeader" @ok="changeLeaderOk" />
    <khcrm-business-modal ref="modalForm" @ok="modalFormOk"></khcrm-business-modal>
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import KhcrmBusinessModal from './modules/KhcrmBusinessModal'
  import ChangeLeaderModal from '../customer/modules/ChangeLeaderModal.vue'
  import { Business } from "@/apis";
  import FollowupModal from '../common/FollowupModal.vue'
  import OptLogModal from '../common/OptLogModal.vue'
  import Vue from 'vue'
  import { USER_INFO } from '@/store/mutation-types'

  export default {
    name: 'KhcrmBusinessList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      KhcrmBusinessModal,
      ChangeLeaderModal,
      FollowupModal,
      OptLogModal
    },
    data () {
      return {
        description: 'khcrm_business管理页面',
        // 表头
        columns: [
          {
            title:'商机名称',
            align:"center",
            ellipsis: true,
            width:147,
            fixed:"left",
            dataIndex: 'businessName'
          },
          {
            title:'客户名称',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'customerName'
          },
          {
            title:'商机金额(元)',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'money'
          },
         
          {
            title:'负责人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'ownerName'
          },
          {
            title:'商机阶段组',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'groupName'
          },
          {
            title:'商机阶段',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'statusName'
          },
          {
            title:'状态',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'statusEnd',
            scopedSlots: { customRender: 'statusEnd' }

          },
           {
            title:'预计成交日期',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'dealDate'
          },
          {
            title:'备注',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'remark'
          },
          
          {
            title:'跟进状态',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'followup',
            scopedSlots: { customRender: 'followup' }
          },
          {
            title:'最后跟进时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'lastTime'
          },
          {
            title:'创建人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'createName'
          },

           {
            title:'创建时间',
            align:"center",
            dataIndex: 'createTime'
          },
           {
            title:'更新时间',
            align:"center",
            dataIndex: 'updateTime'
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/khcrm/web/khcrmBusiness/list",
          delete: "/khcrm/web/khcrmBusiness/delete",
          deleteBatch: "/khcrm/web/khcrmBusiness/deleteBatch",
        },
        dictOptions:{},
        superFieldList:[],
        tableType:'5'
      }
    },
    created() {
      const userinfo = Vue.ls.get(USER_INFO)
      this.userId = userinfo.userId
      this.queryParam.range = "3"
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
      rowSelection() {
          const { selectedRowKeys } = this;
          return {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.selectedRowKeys = selectedRowKeys;
                this.selectionRows = selectedRows;
            },
            getCheckboxProps: record => ({
              props: {
                disabled: !(record.ownerUserId == this.userId || (record.rwUserId != null && record.rwUserId.indexOf(this.userId) >=0))
                ,
                selectionRows: this.selectionRows
              },
            }) 
         };
      }
    },
    methods: {
      initDictConfig(){
      } ,
      changeOwner(){
        this.$refs.changeLeader.show();
      },
      changeRange(){
        this.loadData(1)
      },
      followup(r){
        r.tableType = this.tableType
        let param = {
          businessId : r.id,
          tableType : r.tableType,
          type:1
        }
        this.$refs.followupForm.show(r,param);
      },
      findOpt(r){
        r.tableType = this.tableType
        let param = {
          businessId : r.id,
          tableType : r.tableType,
          type:2
        }
         this.$refs.optLogForm.show(r,param); 
      },
      followupFormOk(){
         this.loadData(1)
       },
      changeLeaderOk(userOwnerId){
         var ids = "";
          for (var a = 0; a < this.selectedRowKeys.length; a++) {
            ids += this.selectedRowKeys[a] + ",";
          }
          let param = {
             ids : ids,
             ownerUserId : userOwnerId
          }
          if(ids == ""){
            this.$message.warning("请先选择商机");
            return
          }
          var that = this
          that.loading = true;
          Business.changeBusinessOwner(param).then((res) => {
              if (res.code ==200) {
                that.$message.success(res.message);
                that.loadData();
                that.changeOwnerVisible = false
              } else {
                that.$message.warning(res.message);
              }
              that.loading = false;
          }) 
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>