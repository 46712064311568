<template>
    <a-modal
      :title="title"
      :width="800"
      :visible="visible"
      switchFullscreen
      @cancel="handleCancel"
      :footer="null">
        <a-table
          ref="table"
          size="middle"
          bordered
          rowKey="id"
          :columns="columns"
          :dataSource="logData"
          :pagination="ipagination"
          @change="handleTableChange"
          :loading="loading"
          class="j-table-force-nowrap">

          <template slot="details" slot-scope="text, record">
            <div v-if="recordType == 1">
              客户在 {{ record.createTime }} 进行了 <span style="color:red;font-weight:600">{{record.saleTypeName}}消费</span> 
              消费金额为: <span style="color:red;font-weight:600">{{record.money}}元</span> 
            </div>
            <div v-else>
              客户在 {{ record.createTime }} 进行了会员充值
              充值金额为: <span style="color:red;font-weight:600">{{record.money}}元</span> 
            </div>
             
          </template>
        </a-table>
    </a-modal>  

</template>

<script>

  import { postAction, getAction } from '@/api/manage'
  export default {
    name: 'RecordLogModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:600,
        visible: false,
        loading: false,
        logData:[],
        recordType:1,
        ipagination: {
          current: 1,
          pageSize: 10,
          pageSizeOptions: ['10', '20', '30'],
          showTotal: (total, range) => {
            return range[0] + '-' + range[1] + ' 共' + total + '条'
          },
          showQuickJumper: true,
          showSizeChanger: true,
          total: 0
        },
        record:{},
        columns:[
          {
            title:'详细信息',
            align:"center",
            dataIndex: 'money',
            scopedSlots: { customRender: 'details' }
          },
          {
            title:'备注',
            align:"center",
            dataIndex: 'remark',
            ellipsis: true,
            width:200,
          },
        ],
        url: {
          getMemberUseList: "/khcrm/web/haircut/getMemberUseList",
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      show (record, type) {
        this.record = record;
        this.recordType = type;
        this.logData = [];
        this.visible = true;
        if(type == 1){
           this.title = "会员消费记录: " + record.phone
        }else{
          this.title = "会员充值记录: " + record.phone 
        }
        this.getMemberUseList(1,10);
      },
      getMemberUseList(pageNum,sizeNum){
        let that = this
        this.loading = true
        let param = {
          "hcMemberId" : that.record.id,
          "moneyType": that.recordType,
          "pageNum":pageNum,
          "sizeNum":sizeNum
        }
        getAction(this.url.getMemberUseList,param).then((res)=>{
          if(res.code == 200){
            this.logData = res.data
            this.ipagination.current = res.currentPage;
            this.ipagination.total = res.total;
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
           that.loading = false;
        })
      },
      handleTableChange(pagination, filters, sorter) {
        this.ipagination = pagination;
        console.log(pagination)
        this.getMemberUseList(pagination.current,pagination.pageSize);
      },
      handleCancel () {
        this.visible = false;
      }
    }
  }
</script>