<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="订单号">
              <a-input
                placeholder="输入订单号查询"
                v-model="queryParam.orderCode"
                :maxLength="50"
                :allowClear="true"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="运货单号">
              <a-input
                placeholder="输入运货单号查询"
                v-model="queryParam.mailCode"
                :maxLength="50"
                :allowClear="true"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="收货人信息">
              <a-input
                placeholder="输入收货人电话或者姓名查询"
                v-model="queryParam.personInfo"
                :maxLength="200"
                :allowClear="true"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="收货人地址">
              <a-input
                placeholder="输入收货人地址查询"
                v-model="queryParam.addressInfo"
                :maxLength="200"
                :allowClear="true"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="下单时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-range-picker
                style="width: 100%"
                v-model="queryParam.pickerTimeRange"
                :format="dateFormat"
                @change="onDateChange"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
  
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
        提示: 点击列表前面的 + 号,可以展示订单子单详情
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:1200}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">
        
          <template slot="receptName" slot-scope="text, record">
             <div :title="record.receptName + '' + record.receptPhone">{{record.receptName}}  {{record.receptPhone}}</div>
             <div :title="record.receptAddress">{{record.receptAddress}}</div>
          </template>
           
          
          <span slot="action" slot-scope="text, record">
            <a  @click="sendFormSubmit(record)">发货</a>
          </span>

           <!-- 展开信息 -->
          <div slot="expandedRowRender"  :defaultExpandAllRows="true" slot-scope="record" style="margin: 0">
              <a-row >
                <a-col :span="index%2==0?4:18" v-for="(item,index) in record.orderDetailsList" :key="item.id">
                  <div class="order-detail">  
                      <div class="order-detail-title">
                         <div>
                          子单号: {{item.id}}
                         </div>
                         <div>
                          {{item.createTime}}
                         </div>
                      </div>
                      <div class="order-detail-body" >
                        <div>
                          <img :src=" item.goodsImg " width="80" height="80" />
                        </div>
                        <div class="order-detail-name">
                          <div style="color:#7A7E83;width: 300px;" class="order-overflow" :title="item.goodsNameZ"> {{item.goodsNameZh}}</div>
                          <div style="color:#FF2D2D;width: 290px;" class="order-overflow" :title="item.serviceDescZh"> {{item.serviceDescZh}}</div>
                          <div class="order-detail-money"> 
                            <div>￥{{item.money}} x {{item.count}}</div>
                            <div>
                              <span class="order-detail-money-count">合计: </span>
                              <span class="order-detail-total">{{item.money * item.count}} 元 </span>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>  
                </a-col>
              </a-row>
          </div>
      </a-table>
    </div>

   
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import Vue from 'vue'
  import { USER_INFO,ACCESS_TOKEN } from '@/store/mutation-types'
  import { getAction, postAction,uploadAction } from '@/api/manage'
  import moment from 'moment';
  import { getNowDate,getAfterDate } from '@/utils/commonUtil';

  export default {
    name: 'OrderList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
     
    },
    data () {
      return {
        description: '订单管理页面',
        loading:false,
        sendVisible:false,
        cancelVisible:false,
        sendForm:this.$form.createForm(this, { name: 'coordinated' }),
        dateFormat:'YYYY-MM-DD',
        // 表头
        columns: [
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            width:160,
            scopedSlots: { customRender: 'action' }
          },
          {
            title:'订单号',
            align:"center",
            dataIndex: 'id',
            ellipsis: true,
            width:200,
          },
          {
            title:'实付金额（元）',
            align:"center",
            dataIndex: 'realMoney',
            ellipsis: true,
            width:150,
            customRender : (values,res) => {
              return values + ' 元';
            }
          },
          {
            title:'优惠金额（元）',
            align:"center",
            dataIndex: 'couponMoney',
            ellipsis: true,
            width:150,
            customRender : (values,res) => {
              return values + ' 元';
            }
          },
          {
            title:'总件数',
            align:"center",
            dataIndex: 'totalNum',
            ellipsis: true,
            width:150,
            customRender : (values,res) => {
              return values + ' 件';
            }
          },
          {
            title:'订单状态',
            align:"center",
            dataIndex: 'statusZh',
            ellipsis: true,
            width:150,
            scopedSlots: { customRender: 'status' },
          }, 
          {
            title:'收货人信息',
            align:"center",
            ellipsis: true,
            width:250,
            dataIndex: 'receptName',
            scopedSlots: { customRender: 'receptName' },
          },
          {
            title:'订单备注',
            align:"center",
            dataIndex: 'remarks',
            ellipsis: true,
            width:250,
          },
          {
            title:'下单时间',
            align:"center",
            dataIndex: 'createTime',
            ellipsis: true,
            width:170,
          },
          {
            title:'应付总金额（元）',
            align:"center",
            dataIndex: 'totalMoney',
            ellipsis: true,
            width:150,
            customRender : (values,res) => {
              return values + ' 元';
            }
          },
      
          {
            title:'支付类型',
            align:"center",
            dataIndex: 'payTypeZh',
            ellipsis: true,
            width:150,
          },
          {
            title:'支付时间',
            align:"center",
            dataIndex: 'payTime',
            ellipsis: true,
            width:150,
          },
          {
            title:'支付失败原因',
            align:"center",
            dataIndex: 'payFailReasonZh',
            ellipsis: true,
            width:250,
          },
          {
            title:'发货时间',
            align:"center",
            dataIndex: 'sendTime',
            ellipsis: true,
            width:170,
          },
          {
            title:'快递公司名称',
            align:"center",
            dataIndex: 'mailNameZh',
            ellipsis: true,
            width:200,
          },
          {
            title:'运单号',
            align:"center",
            dataIndex: 'mailCode',
            ellipsis: true,
            width:200,
          },
        ],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        url: {
          list: "/shop/web/order/getTenantRefundOrderList",
        },
      }
    },
    created() {
      this.queryParam.status = ""
    },
    computed: {
  
    },
    methods: {
      moment,
      searchReset(){
        this.queryParam.status = this.orderStatus.waitPay
        this.loadData();
      },
      // 刷新数据
      refeshData(res){
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      handleCancel(){
        this.cancelVisible = false;
        this.sendVisible = false;
      },
      onDateChange: function (value, dateString) {
        this.queryParam.startTime=dateString[0];
        this.queryParam.endTime=dateString[1];
      },
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
   .order-detail{
      display: flex;
      flex-direction: column;
      background-color: #FFFFFF;
      box-shadow: 5px 5px 2px #888888;
      /* height: 200px; */
      width: 400px;
      font-size: 10px;
      padding-top: 10px;
      padding-left: 10px;
      margin-top: 20px;
   }
   .order-detail-title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 10px;
      font-size: 10px;
   }
  .order-detail-body{
    display: flex;
    flex-direction: row;
    border-top: #c4c6c9 solid 1px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 10px;
  }
  .order-detail-name{
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     margin-left: 10px;
     margin-right: 10px;
  }
  .order-detail-money{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color:#000;
    font-size: 17px;
    font-weight: 600;
  }
  .order-detail-money-count{
     font-size: 10px;
     color: #7A7E83;
     font-weight: 500;
     margin-left: 50px;
  }
  .order-detail-total{
     font-size: 17px;
     color: #FF2D2D;
     font-weight: 600;
     margin-left: 10px;
     margin-right: 10px;
  }
  .order-overflow{
      overflow: hidden;/*超出部分隐藏*/
      text-overflow:ellipsis;/* 超出部分显示省略号 */
      white-space: nowrap;/*规定段落中的文本不进行换行 */
  }
</style>