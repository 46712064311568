<template>
    <a-modal
      title="会员充值"
      :width="600"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @keyup.enter.native="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="会员信息">
                   {{record.phone}}  &nbsp; &nbsp; &nbsp; ( 姓名: {{ record.memberName  }} )
                </a-form-item>
              </a-col>
            </a-row>
            <!-- <a-row>
              <a-col :span="24">
                <a-form-item label="当前会员姓名">
                  {{record.memberName}}
                </a-form-item>
              </a-col>
            </a-row> -->
            <a-row>
              <a-col :span="24">
                <a-form-item label="当前余额">
                  <div style="color:red;font-weight:600">{{ record.money }} 元</div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="充值金额(元)">
                  <a-input-number :max="1000000" :min="0" v-decorator="['money', { rules: [{ required: true, message: '请输入充值金额(元)!' }], }]"  style="width:100%"
                      placeholder="充值金额(元)"  ></a-input-number>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="备注">
                  <a-auto-complete
                        :maxLength="200"
                        v-decorator="['remark', { rules: [{ required: false, message: '!' }] }]"
                        :data-source="remarkData"
                        style="width: 100%"
                        placeholder="请输入备注"
                      />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
    </a-modal>  

</template>

<script>

  import { postAction, getAction } from '@/api/manage'
  import { speakCharacter } from '@/utils/util'
  export default {
    name: 'BuyModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:600,
        visible: false,
        openLanguage:false,
        confirmLoading: false,
        record: {},
        remarkData:["会员充值满n元送x元"],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          buyMoney: "/khcrm/web/haircut/buyMoney",
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      show (record) {
        this.form.resetFields();
        this.visible = true;
        this.record = record;
      },
      submitForm (e) {
        const that = this;
        that.form.validateFields((err, values) => {
          if (!err) {
            that.buyMoney(values);
          }
         })
      },
      buyMoney(formData){
        let that = this
        if(formData.money <= 0){
          that.$message.error("会员充值金额需要大于0元");
          return;
        }
        this.confirmLoading = true
        let param = {
          "phone" : that.record.phone,
          "money" : formData.money,
          "remark": formData.remark
        }
        postAction(this.url.buyMoney,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("会员充值成功");
            that.handleCancel()
            that.$emit('ok');
            let name = that.record.memberName
            speakCharacter(name + "充值成功,充值金额" + param.money + "元");
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
           that.confirmLoading = false;
        })
      },
      handleCancel () {
        this.visible = false;
      }
    }
  }
</script>