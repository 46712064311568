<template>
  <a-spin :spinning="confirmLoading">
      <a-descriptions title="" size="default" layout="vertical" bordered class="base-setting-form">
          <a-descriptions-item label="是否开启多语言模式" :span="3">
            <template v-if="data.isOpenLanguage == 1">
              <a-badge status="processing" text="是" />  
              <a style="margin-left:50px;" @click="changeLanguage(0)"><a-icon type="close" /> 关闭</a>
            </template>
            <template v-else>
              <a-badge status="error" text="否" />  
              <a style="margin-left:50px" @click="changeLanguage(1)"><a-icon type="edit" />开启</a>
            </template>
            <a-icon style="margin-left:40px;color:#EA7500" type="info-circle" />
             提示: 当开启多语言模式时,系统会出现英文列,请您务必补全英文列信息,否则客户端会可能出现异常
          </a-descriptions-item>


          <a-descriptions-item label="是否开启优惠券模式" :span="3">
            <template v-if="data.isOpenCoupon == 1">
              <a-badge status="processing" text="是" />  
              <a style="margin-left:50px" @click="changeCoupon(0)"><a-icon type="close" /> 关闭</a>
            </template>
            <template v-else>
              <a-badge status="error" text="否" />  
              <a style="margin-left:50px;" @click="changeCoupon(1)"><a-icon type="edit" />开启</a>
            </template>
             <a-icon style="margin-left:40px;color:#EA7500" type="info-circle" />
             提示: 当关闭优惠券模式时,系统会自动下架全平台全部优惠券(包括用户已经领取的优惠券),届时整个平台无法使用优惠券,请谨慎操作
          </a-descriptions-item>


          <a-descriptions-item label="是否开启积分模式">
            <template v-if="data.isOpenSorce == 1">
              <a-badge status="processing" :text="'是  ( 每单积分回扣百分比: '+data.sorceRate+' % )'" />  
              <a style="margin-left:50px" @click="changeSorce(0)"><a-icon type="close" /> 关闭</a>
            </template>
            <template v-else>
              <a-badge status="error" text="否" />  
              <a style="margin-left:50px;" @click="changeSorce(1)"><a-icon type="edit" />开启</a>
            </template>
             <a-icon style="margin-left:40px;color:#EA7500" type="info-circle" />
             提示: 当关积分模式时,用户下单不会获得积分,请谨慎操作
          </a-descriptions-item>
      </a-descriptions>
       
        <a-modal
          title="提示"
          :visible="visible"
          :confirm-loading="modalLoading"
          @ok="changeBaseSetting"
          @cancel="handleCancel"
        >
        <div v-if="isOpenSorce">
            <a-form  :label-col="{ span: 9 }" :wrapper-col="{ span: 15}">
              <a-row>
                  <a-col :span="24">
                    <a-form-item label="每单积分回扣百分比(%)">
                      <a-input-number 
                        :max="99999999"  :min="0" :precision="0"
                        v-model="rate"
                        placeholder="请输入每单积分回扣百分比" style="width: 90%" />
                    </a-form-item>
                  </a-col>
              </a-row>
            </a-form>
        </div>
        <p v-else>{{ modalText }}</p>
    </a-modal>
    
  </a-spin>

  
</template>

<script>

  import { postAction, getAction } from '@/api/manage'

  export default {
    name: 'ShopGoodsCouponForm',
    components: {
     
    },
    data () {
      return {
        confirmLoading:false,
        visible:false,
        modalLoading:false,
        // 修改类型  language  coupon source
        changeType:'',
        modalText:'',
        status:0,
        rate:0,
        data:{},
        isOpenSorce:false,
        url: {
          getBaseSettingInfo: "/shop/web/shopTotalSet/getBaseSettingInfo",
          changeBaseSetting: "/shop/web/shopTotalSet/changeBaseSetting",
          data:{},
        }
      }
    },
    computed: {
      
    },
    created () {
      this.getBaseSettingInfo();
    },
    methods: {
      getBaseSettingInfo () {
        let that = this;
        that.confirmLoading = true;
        getAction(this.url.getBaseSettingInfo,{}).then((res)=>{
            if(res.code == 200){
               that.data = res.data
            }else{
              that.$message.error(res.message);
            }
        }).finally(() => {
            that.confirmLoading = false;
        })
      },
      // 修改多语言
      changeLanguage(status){
        this.isOpenSorce = false;
        this.changeType = "language"
        this.status = status
        this.rate = 0;
        this.modalText = "请确认要改变多语言模式吗?";
        this.visible = true;
      },
      // 修改优惠券
      changeCoupon(status){
        this.isOpenSorce = false;
        this.changeType = "coupon"
        this.rate = 0;
        this.status = status
        this.modalText = "请确认要改变优惠券模式吗?";
        this.visible = true;
      },
      // 积分模式
      changeSorce(status){
        this.isOpenSorce = false;
        this.rate = 0;
        if(status == 0){
          this.modalText = "请确认要关积分模式吗?";
        }else{
          this.isOpenSorce = true;
        }
        this.changeType = "source"
        this.status = status
        this.visible = true;
      },
      // 修改
      changeBaseSetting(){
        let that = this;
        if(this.rate == null){
           that.$message.warn("请输入每单积分回扣百分比");
           return false;
        }
        let param = {
          status : this.status,
          rate : this.rate,
          changeType : this.changeType
        }
        that.modalLoading = true;
        postAction(this.url.changeBaseSetting,param).then((res)=>{
            if(res.code == 200){
              that.$message.success("操作成功");
              that.getBaseSettingInfo();
              that.handleCancel();
            }else{
              that.$message.error(res.message);
            }
        }).finally(() => {
            that.modalLoading = false;
        })
      },
      handleCancel(){
        this.visible = false
      },

    }
  }
</script>
<style scoped>
 .base-setting-form{
   background-color: #FFFFFF;
 }
</style>