<template>
  <div class="j-area-linkage">

  </div>
</template>

<script>
  // import { pcaa } from 'area-data'
  import Area from '@/components/_util/Area'

  export default {
    name: 'JAreaLinkage',
    props: {
      value: {
        type: String,
        required:false
      },
      // 组件的类型，可选值：
      // select 下拉样式
      // cascader 级联样式（默认）
      type: {
        type: String,
        default: 'cascader'
      },
      width: {
        type: String,
        default: '100%'
      }
    },
    data() {
      return {
        innerValue: [],
        usedListeners: ['change'],
        enums: {
          type: ['cascader', 'select']
        },
        reloading: false,
        areaData:''
      }
    },
    methods: {

      /** 重新加载组件 */
      reload() {
        this.reloading = true
        this.$nextTick(() => this.reloading = false)
      },

      /** 通过 value 反推 options */
      loadDataByValue(value) {
        if (!value || value.length === 0) {
          this.innerValue = []
        } else {
          this.initAreaData()
          let arr = this.areaData.getRealCode(value)
          this.innerValue = arr
        }
        this.reload()
      },
      /** 通过地区code获取子级 */
      loadDataByCode(value) {
        let options = []
        let data = pcaa[value]
        if (data) {
          for (let key in data) {
            if (data.hasOwnProperty(key)) {
              options.push({ value: key, label: data[key], })
            }
          }
          return options
        } else {
          return []
        }
      },
      /** 判断是否有子节点 */
      hasChildren(options) {
        options.forEach(option => {
          let data = this.loadDataByCode(option.value)
          option.isLeaf = data.length === 0
        })
      },
      handleChange(values) {
        let value = values[values.length - 1]
        this.$emit('change', value)
      },
      initAreaData(){
        if(!this.areaData){
          this.areaData = new Area();
        }
      },

    },
    model: { prop: 'value', event: 'change' },
  }
</script>

<style lang="less" scoped>
  .j-area-linkage {
    height:40px;
    /deep/ .area-cascader-wrap .area-select {
      width: 100%;
    }

    /deep/ .area-select .area-selected-trigger {
      line-height: 1.15;
    }
  }

</style>