<template>
   <div>
    
        <a-drawer
          title="跟进"
          placement="right"
          :closable="true"
          :visible="visible"
          :width="650"
          @close="onClose"
        >
          <a-spin :spinning="drawerLoading">
              <div>
                <!-- 线索 -->
                 <a-descriptions title="重要信息" v-if="tableType == '1'">
                    <a-descriptions-item label="线索名称">
                      {{record.leadsName}}
                    </a-descriptions-item>
                    <a-descriptions-item label="线索来源">
                      {{record.source}}
                    </a-descriptions-item>
                    <a-descriptions-item label="客户级别">
                      {{record.level}}
                    </a-descriptions-item>
                    <a-descriptions-item label="下次联系时间">
                       {{record.nextTime}}
                    </a-descriptions-item>
                    <a-descriptions-item label="最后跟进时间">
                       {{record.lastTime}}
                    </a-descriptions-item>
                    <a-descriptions-item label="最后一条跟进记录">
                      {{record.lastContent}}
                    </a-descriptions-item>
                  </a-descriptions>
                  <!-- 客户 -->
                  <a-descriptions title="重要信息" v-if="tableType == '2'">
                    <a-descriptions-item label="客户名称">
                      {{record.name}}
                    </a-descriptions-item>
                    <a-descriptions-item label="客户来源">
                      {{record.source}}
                    </a-descriptions-item>
                     <a-descriptions-item label="手机号">
                      {{record.mobile}}
                    </a-descriptions-item>
                    <a-descriptions-item label="客户级别">
                      {{record.level}}
                    </a-descriptions-item>
                    <a-descriptions-item label="下次联系时间">
                       {{record.nextTime}}
                    </a-descriptions-item>
                    <a-descriptions-item label="最后跟进时间">
                       {{record.lastTime}}
                    </a-descriptions-item>
                    <a-descriptions-item label="最后一条跟进记录">
                      {{record.lastContent}}
                    </a-descriptions-item>
                  </a-descriptions>
                  <!-- 联系人 -->
                  <a-descriptions title="重要信息" v-if="tableType == '3'">
                    <a-descriptions-item label="联系人名称">
                      {{record.name}}
                    </a-descriptions-item>
                    <a-descriptions-item label="客户名称">
                      {{record.customerName}}
                    </a-descriptions-item>
                     <a-descriptions-item label="手机号">
                      {{record.mobile}}
                    </a-descriptions-item>
                     <a-descriptions-item label="职务">
                      {{record.post}}
                    </a-descriptions-item>
                    <a-descriptions-item label="是否为关键决策人">
                      {{record.isLeader == 0?'否':'是'}}
                    </a-descriptions-item>
                    <a-descriptions-item label="下次联系时间">
                       {{record.nextTime}}
                    </a-descriptions-item>
                    <a-descriptions-item label="最后跟进时间">
                       {{record.lastTime}}
                    </a-descriptions-item>
                    <a-descriptions-item label="最后一条跟进记录">
                      {{record.lastContent}}
                    </a-descriptions-item>
                  </a-descriptions>

                  <span v-if="tableType == '5'">
                      <!-- 商机 -->
                      <a-descriptions title="重要信息" >
                        <a-descriptions-item label="商机名称">
                          {{record.businessName}}
                        </a-descriptions-item>
                        <a-descriptions-item label="客户名称">
                          {{record.customerName}}
                        </a-descriptions-item>
                        <a-descriptions-item label="商机金额（元）">
                          {{record.money}}
                        </a-descriptions-item>
                        <a-descriptions-item label="商机组">
                          {{record.groupName}}
                        </a-descriptions-item>
                        <a-descriptions-item label="负责人">
                          {{record.ownerName}}
                        </a-descriptions-item>
                      </a-descriptions>
                      <a-divider />
                      <a-steps   :current="current" @change="stepChange" style="margin-top:10px;margin-bottom:20px">
                        <template v-for="(item,index) in statusGroup">
                            <a-step   :key="index" :title="item.name" :description=" '赢单率' +item.rate + '%'" />
                         </template>
                          <a-step :title="endTitle">
                              <a-icon :style="endStepStyle" slot="icon" :type="endStepType"/>
                          </a-step>
                      </a-steps>
                </span>  
              </div>
               <a-divider />   
              <a-descriptions title="跟进记录" ></a-descriptions>
              <div style="height:550px;overflow:auto;padding-top:20px;padding-bottom:50px">
                
                  <a-timeline >
                    <template v-for="(item,index) in dataList">
                          <a-timeline-item :key="index" >
                              <div style="color:#333;font-size:14px">
                                {{item.createName}}  
                                <span style="color:#A9A9A9;font-size:13px">{{item.createTime}}</span>
                                <a style="margin-left:30px" download="w3logo" v-if="item.filePath" @click="downFileEnclosure(item.filePath)">点击下载附件</a>
                              </div>
                              <div v-if="item.tallType" style="color:#A9A9A9;font-size:12px;margin-top:6px"> <a-icon type="phone" />
                                 与对方交流方式: {{item.tallType}}
                              </div>
                              <div v-if="item.nextTime" style="color:#A9A9A9;font-size:12px;margin-top:6px"> <a-icon slot="dot" type="clock-circle-o" style="font-size: 12px;color:#A9A9A9" />
                                 下次联系时间: {{item.nextTime}}
                              </div>
                              <div style="margin-top:10px;color:#333;">{{item.content}} </div>
                              
                          </a-timeline-item>
                    </template>
                 
                  </a-timeline>
              </div>
              <div
              :style="{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
              }"
            >
              <a-button style="marginRight: 8px" @click="onClose">
                关闭
              </a-button>
              <a-button v-if="isShowBut" type="primary" @click="addFollow">
                新增跟进
              </a-button>
            </div>
          </a-spin>
        </a-drawer>


     <!-- modal -->
      <a-modal v-model="modelVisible" title="新增跟进记录" @ok="modelOk">
          <a-spin :spinning="confirmLoading">

            <a-form-model ref="form" :model="model" :rules="validatorRules">
                <a-row>   
                    <a-col :span="24">
                        <a-form-model-item label="与对方交流方式" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="tallType">
                        <a-select v-model="model.tallType" placeholder="请选择与对方交流方式">
                            <a-select-option value="打电话">打电话</a-select-option>
                            <a-select-option value="发微信">发微信</a-select-option>
                            <a-select-option value="QQ">QQ</a-select-option>
                            <a-select-option value="短信">短信</a-select-option>
                            <a-select-option value="见面">见面</a-select-option>
                            <a-select-option value="发邮箱">发邮箱</a-select-option>
                            <a-select-option value="线下活动">线下活动</a-select-option>
                            <a-select-option value="其他">其他</a-select-option>
                        </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>     
                <a-row>   
                    <a-col :span="24">
                        <a-form-model-item label="下次联系时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="nextTime">
                            <j-date :disabledToday="true" placeholder="请选择下次联系时间" v-model="model.nextTime" :dateFormat="dateFormat"  style="width: 100%" />
                        </a-form-model-item>
                    </a-col>
                </a-row>     
                <a-row>   
                    <a-col :span="24">
                        <a-form-model-item label="跟进结果" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="content">
                            <a-dropdown>
                                <a class="ant-dropdown-link" >
                                  常用语 <a-icon type="down" />
                                </a>
                                <a-menu slot="overlay">
                                    <a-menu-item key="0" @click="dropdownOK('电话无人接听')">
                                        电话无人接听
                                    </a-menu-item>
                                    <a-menu-item key="1" @click="dropdownOK('加微信未通过')">
                                        加微信未通过
                                    </a-menu-item>
                                    <a-menu-item key="3"  @click="dropdownOK('微信聊天未回复')">
                                        微信聊天未回复
                                    </a-menu-item>
                                    <a-menu-item key="4"  @click="dropdownOK('客户意向轻微,需加强跟踪')">
                                        客户意向轻微,需加强跟踪
                                    </a-menu-item>
                                    <a-menu-item key="5"  @click="dropdownOK('客户意向适中,后续持续跟进')">
                                        客户意向适中,后续持续跟进
                                    </a-menu-item>
                                     <a-menu-item key="6"  @click="dropdownOK('客户意向强烈,成功率较大')">
                                        客户意向强烈,成功率较大
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                            <a-textarea v-model="content" rows="4" placeholder="请输入跟进结果" />
                        </a-form-model-item>
                    </a-col>
                </a-row>       
                <a-row>   
                    <a-col :span="24">
                        <a-form-model-item label="上传附件" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="file">
                            <a-upload :file-list="fileList" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload">
                              <a-button> <a-icon type="upload" />点击上传</a-button>
                            </a-upload>
                        </a-form-model-item>
                    </a-col>
                </a-row>     

                     
            </a-form-model>
          </a-spin>   
      </a-modal>

       <!-- modal -->
      <a-modal v-model="endStepVisible" title="结束阶段" @ok="endStepModelOk">
          <a-spin :spinning="endStepspinloading">
            <a-row>  
              <a-col :span="24">
              <a-form-model-item label="切换状态为" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-select v-model="endStatus" style="width: 100%"  placeholder="请选择" @change="endStepSelcetChange">
                      <a-select-option value="4">赢单</a-select-option>
                      <a-select-option value="6">输单</a-select-option>
                      <a-select-option value="8">无效单</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>  
            <a-col :span="24" v-if="reasonShow">
              <a-form-model-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-textarea v-model="reason" rows="3" placeholder="请输入未成功原因" />
                </a-form-model-item>
              </a-col>
            </a-row>
        </a-spin>    
      </a-modal>
  </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { Business,Leads } from "@/apis";
  import JImageUpload from '@/components/jeecg/JImageUpload'
  import JUpload from '@/components/jeecg/JUpload'
  import { deepClone } from '@/utils/util'
  import { downFile,getAction } from '@/api/manage'

  export default {
    // 跟进
    name: 'FollowupModal',
    mixins:[JeecgListMixin, mixinDevice],
    props: {
      tableType:{
        type: String,
        default: "1",
        required: false
      },
       isShowBut:{
        type: Boolean,
        default: true,
        required: false
      },
    },
    components: {
      JDate,
      JImageUpload,
      JUpload
        
    },
    data () {
      return {
        
        visible: false,
        endStepVisible:false,
        modelVisible:false,
        fileList: [],
        dataList:[],
        endStepspinloading:false,
        uploading: false,
        confirmLoading:false,
        drawerLoading:false,
        model:{},
        
        dateFormat:"YYYY-MM-DD HH:mm",
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
        
        validatorRules: {
            tallType : [
              { required: true, message: '请选择与对方交流方式!'},
            ],
            nextTime: [
              { required: true, message: '请选择下次联系时间!'},
            ],
        },
        content:"",
        record:'',
        sendParam:"",
        statusGroup:[],
        statusGroupLength:0,
        statusId:"",
        endStatus:"4",
        nwodata:{},
        current: 0,
        selectcurrent:0,
        endTitle:'结束',
        endStepStyle:"",
        endStepType:"meh",
        reason:"",
        reasonShow:false
      }
    },
    computed: {
     
    },
    created() {
       
    },
    methods: {
      modelOk() {
         const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
              const { fileList } = this;
              const formData = new FormData();
              if(fileList.length >0){
                fileList.forEach(file => {
                  formData.append('file', file);
                });
              }
             formData.append('tallType', that.model.tallType); 
             formData.append('content', that.content); 
             formData.append('nextTime', that.model.nextTime); 
             formData.append('businessId', that.sendParam.businessId); 
             formData.append('tableType', that.sendParam.tableType); 

             Leads.followUp(formData).then((res)=>{
                  if(res.code == 200){
                    this.$message.success(res.message);
                    this.modelVisible = false;
                    this.show(this.record,this.sendParam)
                    this.$emit('ok');
                  }else{
                    this.$message.warning(res.message);
                  }
                  that.confirmLoading = false;
              })
          }
         
        })
      },
      addFollow(){
        this.model ={}
        this.content = ""
        this.fileList=[]
        this.modelVisible = true;
      },
      onClose() {
        this.visible = false;
      },
      downFileEnclosure(path){
        window.open(path)
          // getAction(path, null).then((data) => {
          //    console.log(data)
          // })
      },
      stepChange(current){
         this.selectcurrent = current
         let nowCur = deepClone(this.current)
         if(nowCur >= this.statusGroupLength){
              return
         }
         let nwodata = {}
         let statusEnd = 2
         if(current >= this.statusGroupLength){
              nwodata.name = '结束'
         }else{
              statusEnd = 2
              nwodata = this.statusGroup[current]
         }
         this.nwodata = nwodata
         console.log(nwodata)
         let that = this;
         this.$confirm({
          title: "提示",
          content: "您确定要进入 ‘"+nwodata.name+"’ 阶段吗?",
          onOk: function () {
            if(current >= that.statusGroupLength){
                // 结束
                that.endStepVisible = true
            }else{
              that.changeStep(nwodata,statusEnd);
            }
          }
        });
      },
      endStepModelOk(){
        if(this.reasonShow){
          console.log(this.reason)
            if(!this.reason){
               this.$message.warning("请填写原因!");
               return
            }
        }
        this.changeStep(this.nwodata,this.endStatus);
      },
      show(r,param){
       if(this.tableType == 5){
         this.reasonShow = false
         this.reason = ""
         this.endStepStyle = "",
         this.endStepType = "meh"
         this.statusId = r.statusId
         this.getBusStatusByGroupId(r.groupStatusId);
       }
       this.drawerLoading = true
       this.record = r
       this.sendParam = param
       Leads.findFollowUp(param).then((res)=>{
            if(res.code == 200){
              console.log(res)
              this.dataList = res.data
            }else{
              this.$message.warning(res.message);
            }
            this.drawerLoading = false;
        })
       this.visible = true;
      },
      changeStep(nwodata,statusEnd){
        let that = this;
        that.loading = true;
        that.endStepspinloading = true;
        let param = {
          id : that.record.id,
          statusEnd : statusEnd,
          statusId : nwodata.id,
          reason:that.reason
        }
        Business.changeStep(param).then((res) => {
          if (res.code ==200) {
            that.$message.success(res.message);
            that.record.statusId = nwodata.id;
            that.record.statusEnd = statusEnd;

            that.show(that.record,that.sendParam)
            that.$emit('ok');
            that.current = this.selectcurrent;
            that.endStepVisible = false
          } else {
            that.$message.warning(res.message);
          }
          that.endStepspinloading = false;
          that.loading = false;
        })
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      beforeUpload(file) {
        // this.fileList = [...this.fileList, file];
        this.fileList = []
        this.fileList = [...this.fileList, file];
        return false;
      },
      dropdownOK(e){
        this.content = e
      },
      loadData(arg) {
       
      },
      handleOk(){
        this.cusvisible = false
      },
      handleCancel(){
         this.cusvisible = false
      },
      getBusStatusByGroupId(groupId){
        let param = {
          id:groupId
        }
          Business.getBusStatusByGroupId(param).then((res) => {
              if (res.code ==200) {
                let data = res.data
                 this.statusGroup = data
                 this.statusGroupLength = data.length
                 this.current = data.length
                 if(this.record.statusEnd == 4){
                    this.endTitle = '赢单'
                    this.endStepStyle = "font-size:30px;color:#52c41a",
                    this.endStepType = "smile"
                 }else if(this.record.statusEnd == 6){
                    this.endTitle = '输单'
                    this.endStepStyle = "font-size:30px;color:#f50",
                    this.endStepType = "frown"
                 }else if(this.record.statusEnd == 8){
                    this.endTitle = '无效单'
                    this.endStepStyle = "font-size:30px;color:#46485f",
                    this.endStepType = "frown"
                 }else{
                   this.endStepStyle = "",
                    this.endStepType = "meh"
                   this.endTitle = '结束'
                    for(let i in data){
                    let d = data[i]
                    if(d.id == this.statusId){
                        this.current = Number(i);
                    }
                   }
                 }
              } else {
                this.$message.warning(res.message);
              }
          }) 
      },
      endStepSelcetChange(){
        if(this.endStatus == 4){
            this.reasonShow = false
        }else{
            this.reasonShow = true
        }
      }
      
    }
  }
</script>