<template>
  <a-modal
    title="Excel 随机抽取数据"
    :width="width"
    :visible="visible"
    switchFullscreen
    @ok="handleOk"
    :okButtonProps="{ class:{'jee-hidden': disableSubmit} }"
    @cancel="handleCancel"
    cancelText="关闭">
      <a-spin :spinning="confirmLoading">
        <j-form-container>
          <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
            <a-row>
              <a-col :span="24">
                <a-form-model-item label="专业"    prop="majors">
                  <a-input :disabled="false" v-model="model.majors" placeholder="请输入限制专业,不输入则默认全部专业"  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item label="抽取人数"  prop="everyNum">
                      <a-input-number v-model="model.everyNum" :min="1" :max="10000"  style="width:100%" />
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item label="每个导师限制抽取人数"  prop="tutorNum">
                      <a-input-number v-model="model.tutorNum" :min="1" :max="10000"  style="width:100%" />
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item label="选择抽取的文件"  prop="auxfileList">
                <a-upload :accept="accept" :file-list="auxfileList" :multiple="false" :remove="auxhandleRemove" :before-upload="auxbeforeUpload">
                      <a-button> <a-icon type="upload" />点击上传辅文件</a-button>
                    </a-upload>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </j-form-container>
    </a-spin>
  </a-modal>
</template>

<script>
  import { httpAction, getAction,uploadBlobAction,toDownExcel } from '@/api/manage'
  import JFormContainer from '@/components/jeecg/JFormContainer'
  export default {
    name: 'ExcelRandomModal',
    components: {
      JFormContainer
    },
    data () {
      return {
        title:'',
        model:{
          tutorNum : 1,
          everyNum : 5
        },
        validatorRules: {
           tutorNum: [
              { required: true, message: '请输入每个导师限制抽取人数!'},
           ],
           everyNum: [
              { required: true, message: '请输入抽取人数!'},
           ],
        },
        width:600,
        accept:'xlsx',
        visible: false,
        disableSubmit: false,
        confirmLoading:false,
        auxfileList:[],
        httpurl:{
          randomGetExcelData :'/khcrm/web/auxExcel/randomGetExcelData'
        }
      }
    },
    methods: {
      show () {
        this.visible=true
      },
      close () {
        this.visible = false;
      },
      handleOk () {
         let that = this;
         this.$refs.form.validate(valid => {
          if (valid) {

            const { auxfileList } = that;
            if(auxfileList == null || auxfileList.length == 0){
                that.$message.warning("请上传要抽取的excel文件");
                return
            }
            const formData = new FormData();
            formData.append('tutorNum', that.model.tutorNum); 
            formData.append('majors', that.model.majors);
            formData.append('everyNum', that.model.everyNum);
            auxfileList.forEach(file => {
                formData.append('file', file);
            }); 
            that.confirmLoading = true;
            let httpurl = that.httpurl.randomGetExcelData;
            uploadBlobAction(httpurl,formData).then((res)=>{
                let fileReader = new FileReader();
                fileReader.onload = function() {
                  try {
                      let jsonData = JSON.parse(fileReader.result);  // 说明是普通对象数据，后台转换失败
                      that.$message.warning(jsonData.message);
                  } catch (err) { 
                      // 解析成对象失败，说明是正常的文件流
                      var fileName = '随机抽取文件' + Math.ceil(Math.random()*100000);+'.xlsx';
                      toDownExcel(res,fileName);
                      that.$message.success("操作成功");
                      that.$emit('ok');
                  } 
                }; 
                fileReader.readAsText(res) 
                that.confirmLoading = false;
            })
          }
         
        })
      },
      submitCallback(){
        this.visible = false;
      },
      auxbeforeUpload(file) {
        this.auxfileList = [];
        this.auxfileList = [...this.auxfileList, file];
        return false;
      },
      auxhandleRemove(file) {
        const index = this.auxfileList.indexOf(file);
        const newFileList = this.auxfileList.slice();
        newFileList.splice(index, 1);
        this.auxfileList = newFileList;
      },
      handleCancel () {
        this.close()
      }
    }
  }
</script>