<template>
   <div>
        <a-drawer
          title="客户详情"
          placement="right"
          :closable="true"
          :visible="visible"
          :width="900"
          @close="onClose"
        >
          <div>
            <a-tabs size="large" :tabBarGutter="80" v-model="defaultActive" @change="tabsChange">
              <a-tab-pane key="1" tab="联系人" >
                 <a-button style="margin-bottom:10px" @click="addPerson" type="primary" icon="plus">新增联系人</a-button>
                 <a-table
                    ref="table"
                    size="middle"
                    :scroll="{x:true}"
                    bordered
                    rowKey="id"
                    :columns="personcolumns"
                    :dataSource="persondataSource"
                    :pagination="false"
                    :loading="personLoading"
                    class="j-table-force-nowrap">
                    
                    <template slot="isLeader" slot-scope="text">
                        <a-tag v-if="text==0" color="#108ee9">否</a-tag>
                        <a-tag v-else color="red">是</a-tag>
                    </template>


                    <span slot="action" slot-scope="text, record">
                      <a @click="handleEdit(record)">编辑</a>

                      <a-divider type="vertical" />
                      <a-dropdown>
                        <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
                        <a-menu slot="overlay">
                          <a-menu-item>
                            <a @click="handleDetail(record)">详情</a>
                          </a-menu-item>
                          <a-menu-item>
                                <a @click="findOpt(record)">操作记录</a>
                              </a-menu-item>
                              <a-menu-item>
                                <a @click="followup(record)">写跟进</a>
                              </a-menu-item>
                          <a-menu-item>
                            <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                              <a>删除</a>
                            </a-popconfirm>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </span>
                  </a-table>
              </a-tab-pane>
              <a-tab-pane key="2" tab="商机" force-render>
                 <a-button @click="addBusiness" type="primary" icon="plus" style="margin-bottom:10px">新增商机</a-button>
                 <a-table
                      ref="table"
                      size="middle"
                      :scroll="{x:true}"
                      bordered
                      rowKey="id"
                      :columns="businesscolumns"
                      :dataSource="businessdataSource"
                      :pagination="false"
                      :loading="businessloading"
                      class="j-table-force-nowrap">
                      <template slot="statusEnd" slot-scope="text">
                          <a-tag v-if="text==0" color="#108ee9">未开始</a-tag>
                          <a-tag v-if="text==2"  color="#77ac98">已开始</a-tag>
                          <a-tag v-if="text==4"  color="#52c41a">赢单</a-tag>
                          <a-tag v-if="text==6"  color="#f50">输单</a-tag>
                          <a-tag v-if="text==8"  color="#46485f">无效单</a-tag>
                      </template>
                      <template slot="followup" slot-scope="text">
                          <a-tag v-if="text==0" color="#108ee9">未跟进</a-tag>
                          <a-tag v-if="text==1"  color="#87d068">已跟进</a-tag>
                      </template>
                      <span slot="action" slot-scope="text, record">
                        <a @click="handleEdit(record)">编辑</a>
                        <a-divider type="vertical" />
                        <a-dropdown>
                          <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
                          <a-menu slot="overlay">
                            <a-menu-item>
                              <a @click="handleDetail(record)">详情</a>
                            </a-menu-item>
                            <a-menu-item>
                              <a @click="findOpt(record)">操作记录</a>
                            </a-menu-item>
                            <a-menu-item>
                              <a @click="followup(record)">写跟进</a>
                            </a-menu-item>
                            <a-menu-item>
                              <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                                <a>删除</a>
                              </a-popconfirm>
                            </a-menu-item>
                          </a-menu>
                        </a-dropdown>
                      </span>
                </a-table>
              </a-tab-pane>
              <a-tab-pane key="3" tab="合同">
                <a-table
                    ref="table"
                    size="middle"
                    :scroll="{x:true}"
                    bordered
                    rowKey="id"
                    :columns="contractcolumns"
                    :dataSource="contractdataSource"
                    :pagination="false"
                    :loading="contractloading"
                    class="j-table-force-nowrap">
                    <span slot="action" slot-scope="text, record">
                      <a @click="handleEdit(record)">编辑</a>
                      <a-divider type="vertical" />
                      <a-dropdown>
                        <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
                        <a-menu slot="overlay">
                          <a-menu-item>
                            <a @click="handleDetail(record)">详情</a>
                          </a-menu-item>
                          <a-menu-item>
                            <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                              <a>删除</a>
                            </a-popconfirm>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </span>
                  </a-table>
              </a-tab-pane>
            </a-tabs>
          </div>
        </a-drawer>
        <khcrm-person-modal ref="personForm" @ok="personFormOk"></khcrm-person-modal>
        <khcrm-business-modal ref="businessForm" @ok="businessFormOk"  />
  </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { Business,Leads,Person,Contract } from "@/apis";
  import JUpload from '@/components/jeecg/JUpload'
  import KhcrmBusinessModal from '../../business/modules/KhcrmBusinessModal'
  import KhcrmPersonModal from '../../person/modules/KhcrmPersonModal'

  export default {
    // 客户详情
    name: 'CustomerDetail',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate,
      JUpload,
      KhcrmBusinessModal,
      KhcrmPersonModal
        
    },
    data () {
      return {
        visible: false,
        modelVisible:false,
        dataList:[],
        drawerLoading:false,
        model:{},
        personLoading:false,
        persondataSource:[],
        defaultActive:"1",
        personcolumns:[
          {
            title:'联系人名称',
            align:"center",
            ellipsis: true,
            width:147,
            fixed:"left",
            dataIndex: 'name'
          },
          {
            title:'性别',
            align:"center",
            ellipsis: true,
            width:80,
            dataIndex: 'sex'
          },
          {
            title:'手机',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'mobile'
          },
          {
            title:'职务',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'post'
          },
          {
            title:'是否关键决策人',
            align:"center",
            ellipsis: true,
            width:80,
            dataIndex: 'isLeader',
            scopedSlots: { customRender: 'isLeader' }

          },
          {
            title:'负责人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'ownerName'
          },
          {
            title:'下次联系时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'nextTime'
          },
          
          {
            title:'电话',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'telphone'
          },
          {
            title:'电子邮箱',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'email'
          },
          {
            title:'地址',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'address'
          },
          {
            title:'备注',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'remark'
          },
          {
            title:'最后跟进时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'lastTime'
          },
          
        ],
        businessloading:false,
        businessdataSource:[],
        businesscolumns:[
          {
            title:'商机名称',
            align:"center",
            ellipsis: true,
            width:147,
            fixed:"left",
            dataIndex: 'businessName'
          },
          {
            title:'商机金额(元)',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'money'
          },
          {
            title:'负责人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'ownerName'
          },
          {
            title:'商机阶段组',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'groupName'
          },
          {
            title:'商机阶段',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'statusName'
          },
          {
            title:'状态',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'statusEnd',
            scopedSlots: { customRender: 'statusEnd' }

          },
           {
            title:'预计成交日期',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'dealDate'
          },
          {
            title:'备注',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'remark'
          },
          {
            title:'跟进状态',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'followup',
            scopedSlots: { customRender: 'followup' }
          },
          {
            title:'最后跟进时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'lastTime'
          },
        ],
        contractdataSource:[],
        contractcolumns:[
          {
            title:'合同名称',
            align:"center",
            fixed:"left",
            ellipsis: true,
            width:147,
            dataIndex: 'name'
          },
           {
            title:'合同编号',
            align:"center",
            fixed:"left",
            ellipsis: true,
            width:147,
            dataIndex: 'num'
          },
           {
            title:'商机',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'businessName'
          },
          {
            title:'合同金额(元)',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'money'
          },
          {
            title:'合同类型',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'type'
          },
          {
            title:'下单日期',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'orderDate'
          },
          {
            title:'开始时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'startTime' 
          },
          {
            title:'结束时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'endTime' 
          },
          {
            title:'客户联系人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'personName'
          },
          {
            title:'公司签约人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'companyUserName'
          },
          {
            title:'负责人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'ownerName'
          },
          {
            title:'备注',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'remark'
          },
        ],
        contractloading:false,
        cusrecord:{},
        initPage:1,
        initSize:20,
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
      }
    },
    computed: {
     
    },
    created() {
       
    },
    methods: {
      show(r){
        console.log(this.queryParam)
        this.defaultActive = "1"
        this.cusrecord = r
        this.findPerson(this.cusrecord.id,this.initPage,this.initSize);
        this.visible = true;
      },
      tabsChange(e){
        if(e == 1){
           this.findPerson(this.cusrecord.id,this.initPage,this.initSize);
        }else if(e == 2){
           this.findBusinessByCustomerId(this.cusrecord.id,this.initPage,this.initSize);
        }else if(e == 3){
          this.findContractByCustomerId(this.cusrecord.id,this.initPage,this.initSize);
        }
           console.log(e)
      },
      onClose() {
        this.visible = false;
      },
      loadData(arg) {
        console.log(arg)
      },
      //联系人
      addPerson(){
        this.$refs.personForm.title = "新增联系人";
        this.$refs.personForm.disableSubmit = false;
        let res = {
           customerId:this.cusrecord.id,
           customerName:this.cusrecord.name,
           _isOnlyAddCus:true
        }
        this.$refs.personForm.edit(res); 
      },
      personFormOk(){
        this.findPerson(this.cusrecord.id,this.initPage,this.initSize);
      },
      // 查询联系人
      findPerson(customerId,pageNum,sizeNum) {
        let param = {
           range: 3,
           customerId : customerId,
           pageNum:pageNum,
           sizeNum:sizeNum
        }
        this.personLoading = true
        Person.findPersonByCustomerId(param).then((res) => {
            if(res.code == 200){
              this.persondataSource = res.data || [];
              this.ipagination.total = res.total || 0;
            }else{
              this.$message.warning(res.message);
            }
            this.personLoading = false
        })
      },
      // 商机
      addBusiness(){
        this.$refs.businessForm.title = "新增商机";
        this.$refs.businessForm.disableSubmit = false;
        let res = {
           customerId:this.cusrecord.id,
           customerName:this.cusrecord.name,
           _isOnlyAddCus:true
        }
        this.$refs.businessForm.edit(res); 
      },
      businessFormOk(){
        this.findBusinessByCustomerId(this.cusrecord.id,this.initPage,this.initSize);
      },
      findBusinessByCustomerId(customerId,pageNum,sizeNum) {
        let param = {
           range: 3,
           customerId : customerId,
           pageNum:pageNum,
           sizeNum:sizeNum
        }
        this.businessloading = true
        Business.findBusinessByCustomerId(param).then((res) => {
            if(res.code == 200){
              this.businessdataSource = res.data || [];
              this.ipagination.total = res.total || 0;
            }else{
              this.$message.warning(res.message);
            }
            this.businessloading = false
        })
      },
      // 查询合同
      findContractByCustomerId(customerId,pageNum,sizeNum) {
        let param = {
           range: "3",
           customerId : customerId,
           pageNum:pageNum,
           sizeNum:sizeNum
        }
        this.contractloading = true
        Contract.findContractByCustomerId(param).then((res) => {
            if(res.code == 200){
              this.contractdataSource = res.data || [];
              this.ipagination.total = res.total || 0;
            }else{
              this.$message.warning(res.message);
            }
            this.contractloading = false
        })
      },
    }
  }
</script>