<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form-model  ref="form" :model="model" :rules="validatorRules">

        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="itemName"
          label="名称">
          <a-input placeholder="请输入名称" v-model="model.itemName"/>
        </a-form-model-item>

        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="itemCode"
          label="数据值">
          <a-input placeholder="请输入数据值" :disabled="disabled" v-model="model.itemCode" />
        </a-form-model-item>

        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="描述">
          <a-input v-model="model.description" />
        </a-form-model-item>

        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="sort"
          label="排序">
          <a-input-number :min="1" v-model="model.sort" />
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
  import pick from 'lodash.pick'
  import {addDictItem, editDictItem} from '@/api/api'
  import { getAction } from '@api/manage'

  export default {
    name: "DictItemModal",
    data() {
      return {
        title: "操作",
        visible: false,
        visibleCheck: true,
        model: {},
        dictId: "",
        status: 1,
        disabled:false,
        labelCol: {
          xs: {span: 24},
          sm: {span: 5},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
        confirmLoading: false,
        validatorRules: {
          itemName:  [{required: true, message: '请输入名称!'}],
          itemCode:  [{required: true, message: '请输入数据值!'}],
          sort:[{required: true, message: '请输入排序值!'}],
        },
      }
    },
    created() {
    },
    methods: {
      add(dictId) {
        this.dictId = dictId;
        //初始化默认值
        this.edit({sortOrder:1,status:1});
      },
      edit(record) {
        if (record.id) {
          this.dictId = record.dictId;
        }
        console.log(record)
        if(record.isUpdate && record.isUpdate == 1){
           this.disabled = true
        }else{
           this.disabled = false
        }
        this.status = record.status;
        this.visibleCheck = (record.status == 1) ? true : false;
        this.model = Object.assign({}, record);
        this.model.dictId = this.dictId;
        this.model.status = this.status;
        this.visible = true;
      },
      onChose(checked) {
        if (checked) {
          this.status = 1;
          this.visibleCheck = true;
        } else {
          this.status = 0;
          this.visibleCheck = false;
        }
      },
      // 确定
      handleOk() {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            this.model.itemText = (this.model.itemText || '').trim()
            this.model.itemValue = (this.model.itemValue || '').trim()
            this.model.description = (this.model.description || '').trim()
            this.model.status = this.status;
            let obj;
            if (!this.model.id) {
              obj = addDictItem(this.model);
            } else {
              obj = editDictItem(this.model);
            }
            obj.then((res) => {
              if (res.code == 200) {
                that.$message.success(res.message);
                that.$emit('ok');
                 that.close();
              } else {
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
             
            })
          }else{
            return false;
          }
        })
      },
      // 关闭
      handleCancel() {
        this.close();
      },
      close() {
        this.$emit('close');
        this.visible = false;
        this.$refs.form.resetFields();
      },
      
    }
  }
</script>
