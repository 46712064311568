<template>
    <a-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="submitForm"
    @cancel="handleCancel"
    cancelText="关闭">
     <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
          <a-row>
            <a-col :span="24">
              <a-form-item label="商品名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                 {{record.goodsNameZh}}
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="规格名称(中文)" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input :maxLength="200"  v-decorator="['specsNameZh', { rules: [{ required: true, message: '请输入规格名称!' }] }]" placeholder="请输入规格名称"  ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="24" v-if="openLanguage">
              <a-form-item label="规格名称(英文)" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input :maxLength="200" v-decorator="['specsNameEn', { rules: [{ required: true, message: '请输入规格名称!' }] }]"  placeholder="请输入规格名称"  ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="规格图" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-upload
                      v-decorator="['imgFile', { rules: [{ required: true, message: '请选择规格图!' }] }]"
                      :file-list="fileList"
                      :before-upload="beforeUpload"
                      accept=".png,.jpg"
                      :remove="handleRemove"
                    >
                  <div v-if="fileList.length < 8">
                      <a-button type="primary"> <a-icon type="upload" />上传规格图</a-button>
                      <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />注意: 规格图最多上传1个
                  </div>
               </a-upload>

              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品现价（元）" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number 
                  :max="99999999" :precision="2" :min="0.01" 
                  v-decorator="['realMoney', { rules: [{ required: true, message: '请输入商品现价（元）!' }] }]"
                  placeholder="请输入商品现价（元）" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="商品成本（元）" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number 
                  :max="99999999" :precision="2" :min="0.01" 
                  v-decorator="['profit', { rules: [{ required: true, message: '请输入商品成本（元）!' }] }]"
                  placeholder="请输入商品成本（元）" style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="初始库存" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number 
                  :max="99999999" :precision="0" :min="0" 
                  v-decorator="['stock', { rules: [{ required: true, message: '请输入初始库存!' }] }]"
                  placeholder="请输入初始库存" style="width: 35%" />
                  <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />每次上架商品都会将库存初始化为该值
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number :max="99999" :min="-99999" :precision="0"
                  v-decorator="['sort', { rules: [{ required: true, message: '请输入排序!' }] , initialValue:'1'}]"
                  placeholder="请输入排序" style="width: 100%" />
              </a-form-item>
            </a-col>
          </a-row>
     </a-form>
    </a-modal>

</template>

<script>

  import { httpAction, postAction,uploadAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'

  // 添加商品规格
  export default {
    name: 'AddShopSpecsForm',
    components: {
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        title:'新增商品规格',
        width:800,
        visible: false,
        openLanguage:false,
        fileList: [],
        goodsDetailFileList:[],
 
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        shopGroupFormList:[],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        record:{},
        url: {
          add: "",
          edit: "",
          addGoodsSpecs:'/shop/web/shop/addGoodsSpecs'
        }
      }
    },
    computed: {
      
    },
    created () {
    
    },
    methods: {
      add (param) {
        this.visible = true;
        this.openLanguage = param.openLanguage;
        this.shopGroupFormList = param.shopGroupList;
        this.fileList = [];
        this.goodsDetailFileList = [];
        this.record = param;
        this.form.resetFields();
      },
      edit (record) {
        this.visible = true;
      },
      submitForm () {
        this.form.validateFields((err, values) => {
          if (!err) {
            const formData = new FormData();
             for(let i in values){
               let v = values[i]
               if(!isEmpty(v)){
                 if(v.fileList){
                    v.fileList.forEach(file => {
                      if(file.originFileObj){
                          formData.append(i, file.originFileObj);
                      }else{
                          formData.append(i, file);
                      }
                      
                    });
                 }else{
                    formData.append(i, v);
                 }
               }
             }
             this.addGoodsSpecs(formData);
          }
         })
      },
      addGoodsSpecs(formData){
        let that = this
        formData.append("goodsId", this.record.id);
        this.confirmLoading = true
        uploadAction(this.url.addGoodsSpecs,formData).then((res)=>{
          if(res.code == 200){
            that.$message.success("创建规格成功");
            that.close()
            that.$emit('ok',res.data);
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
      },
      beforeUpload(file) {
        this.fileList = [];
        this.fileList = [...this.fileList, file];
        return false;
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      goodsDetailBeforeUpload(file){
        this.goodsDetailFileList = [...this.goodsDetailFileList, file];
        return false;
      },
      goodsDetailHandleRemove(file) {
        const index = this.goodsDetailFileList.indexOf(file);
        const newFileList = this.goodsDetailFileList.slice();
        newFileList.splice(index, 1);
        this.goodsDetailFileList = newFileList;
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      close () {
        this.visible = false;
      },
      handleCancel () {
        this.close()
      }
    }
  }
</script>