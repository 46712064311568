<template>
    <a-modal
    :title="title"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="submitForm"
    @cancel="handleCancel"
    cancelText="关闭">
     <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
          <a-row>
            <a-col :span="24">
              <a-form-item label="当前库存剩余" :labelCol="labelCol" :wrapperCol="wrapperCol">
                 {{record.realStock == null ? 0 : record.realStock}} 件
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="补充库存" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number 
                  :max="999999" :precision="0" :min="-999999" 
                  v-decorator="['suppleStock', { rules: [{ required: true, message: '请输入库存!' }] }]"
                  placeholder="请输入库存" style="width: 35%" />
                  <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />库存为正数则补充库存,为负数则扣除库存
              </a-form-item>
            </a-col>
           
          </a-row>
     </a-form>
    </a-modal>

</template>

<script>

  import { httpAction, postAction,uploadAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'

  // 补充库存modal
  export default {
    name: 'ChangeStockModal',
    components: {
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        title:'商品库存补充',
        width:800,
        visible: false,
        fileList: [],
        goodsDetailFileList:[],
 
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        shopGroupFormList:[],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        record:{},
        url: {
          add: "",
          edit: "",
          changeStock:'/shop/web/shop/changeStock'
        }
      }
    },
    computed: {
      
    },
    created () {
    
    },
    methods: {
      add (param) {
        this.visible = true;
        this.record = param;
        console.log(this.record)
        this.form.resetFields();
      },
      submitForm () {
        let that = this
        this.form.validateFields((err, values) => {
          if (!err) {
             that.changeStock(values)
          }
         })
      },
      changeStock(values){
        let that = this
        let param = {
           id : this.record.goodsId,
           specsId : this.record.id,
           suppleStock:values.suppleStock
        }
        this.confirmLoading = true
        postAction(this.url.changeStock,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("补充库存成功");
            that.close()
            that.$emit('ok');
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
      },
      close () {
        this.visible = false;
      },
      handleCancel () {
        this.close()
      }
    }
  }
</script>