<template>
    <div>
      <a-drawer
        :title="'新增【'+ processName + '】流程环节'"
        placement="right"
        :closable="true"
        width="80%"
        :visible="visible"
        @close="onClose"
      >
      <a-spin :spinning="spinning">
       <!-- <div >  
         <a-button  @click="delOnesteps" type="danger">
            <a-icon type="left" />删除一个环节
          </a-button>
          <a-button  @click="addOnesteps"  type="primary" style="margin-left:20px">
            添加一个环节<a-icon type="right" />
          </a-button>
          
        </div>   -->
        <!-- <a-steps :current="current" style="margin-top:30px">
          <a-step v-for="item in steps" :key="item.title" :title="item.title" />
        </a-steps> -->
        <div style="margin-top:30px">
          <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
            <a-form-item  label="当前环节标题" >
              <a-input 
                  v-decorator="['title', { rules: [{ required: true, message: '请输入当前环节标题!' }] }]"
                  placeholder="当前环节标题" @blur="titleBlur"></a-input>
            </a-form-item>  
            <a-form-item  label="是否需要审批" >
               <a-radio-group v-decorator="['isApproval', { initialValue: 0 , rules: [{ required: true, message: '请选择是否需要审批!' }] }]">
                  <a-radio :value="0" @click="approvalChange(0)">不需要 </a-radio> 
                  <a-radio :value="1" @click="approvalChange(1)">需要</a-radio>
                </a-radio-group>
            </a-form-item> 
            <a-form-item label="选择审批角色" v-if="needApproval">
               <a-select v-decorator="['roleCode', { rules: [{ required: true, message: '请选择审批角色!' }] }]"  placeholder="请选择审批角色">
                 <template v-if="roleList">
                      <a-select-option v-for="item in roleList" :key="item.id" :value="item.roleCode" >{{item.roleName}}</a-select-option>
                 </template>
                 <template v-else>
                      <a-select-option value="" >您还未建任何角色</a-select-option>
                 </template>
              </a-select>
            </a-form-item> 
           <a-divider />
           <div style="margin-bottom:20px">
            <a-dropdown v-model="dropvisible">
                <a-button type="primary">添加一个控件</a-button>
                <a-menu slot="overlay">
                  <a-menu-item @click="addControl('text')">
                    文本输入框
                  </a-menu-item>
                  <a-menu-item @click="addControl('number')">
                    数值输入框
                  </a-menu-item>
                  <a-menu-item @click="addControl('textarea')">
                    文本域
                  </a-menu-item>
                  <a-menu-item @click="addControl('time')">
                    时间控件
                  </a-menu-item>
                  <a-menu-item @click="addControl('select')">
                    下拉控件
                  </a-menu-item>
                  <a-menu-item @click="addControl('file')">
                    文件控件
                  </a-menu-item>
                </a-menu>
             </a-dropdown>
          </div>  

          <a-row>
          <!-- 开始 -->
           <template v-for="(item,index) in controlData">
             <!-- 文本框 -->
            <a-col :key="index" :span="12"> 
             <template v-if="item.type == 'text'">
              <a-form-item  label="文本输入框标题">
                <a-input
                    style="width:80%"
                    v-decorator="[item.type+(index+1), { rules: [{ required: true, message: '请输入文本框的标题!' }] }]" placeholder="请输入文本框的标题"></a-input> 
                    <a class="del-a-btn" @click="delControl(index)">删除</a>
                <div>
                  是否必填: &nbsp;
                  <a-radio-group v-decorator="['ismust'+(index+1), { initialValue: 0 , rules: [{ required: true, message: '请输入!' }] }]">
                    <a-radio :value="0">非必须 </a-radio> <a-radio :value="1">必须</a-radio>
                  </a-radio-group>
                </div>
              </a-form-item>  
             </template>
             <!-- 文本域 -->
             <template v-else-if="item.type == 'textarea'">
              <a-form-item :key="index" label="文本域标题" >
                <a-input
                    style="width:80%"
                    v-decorator="[item.type+(index+1), { rules: [{ required: true, message: '请输入文本域标题!' }] }]"  placeholder="请输入文本域标题"  ></a-input>
                   <a class="del-a-btn" @click="delControl(index)">删除</a>
                <div>
                  是否必填: &nbsp;
                   <a-radio-group  v-decorator="['ismust'+(index+1), { initialValue: 0 ,  rules: [{ required: true, message: '请输入!' }] }]">
                      <a-radio :value="0">非必须 </a-radio> <a-radio :value="1">必须</a-radio>
                   </a-radio-group>
                </div>
                
                
              </a-form-item>  
             </template>

             <!-- 数值框 -->
             <template v-else-if="item.type == 'number'">
              <a-form-item :key="index" label="数值输入框标题" >
                <a-input
                  style="width:80%"
                  v-decorator="[item.type+(index+1), { rules: [{ required: true, message: '请输入数值框标题!' }] }]" placeholder="请输入数值框标题"></a-input>
                  <a class="del-a-btn" @click="delControl(index)">删除</a>
                <div>
                  是否必填: &nbsp;
                  <a-radio-group  v-decorator="['ismust'+(index+1), { initialValue: 0 ,  rules: [{ required: true, message: '请选择!' }] }]">
                      <a-radio :value="0">非必须 </a-radio> <a-radio :value="1">必须</a-radio>
                  </a-radio-group>
                  <div>
                    能否为负数: &nbsp;
                      <a-radio-group  v-decorator="['negative'+(index+1), { initialValue: 0 ,  rules: [{ required: true, message: '请选择!' }] }]">
                       <a-radio :value="0">能</a-radio> <a-radio :value="1">否</a-radio>
                      </a-radio-group>
                  </div>
                  <div>
                    能否为小数: &nbsp;
                     <a-radio-group  v-decorator="['decimal'+(index+1), { initialValue: 0 ,  rules: [{ required: true, message: '请选择!' }] }]">
                       <a-radio :value="0">能 </a-radio> <a-radio :value="1">否</a-radio>
                     </a-radio-group>
                  </div>
                </div>
              </a-form-item>  
             </template>
              <!-- 时间控件 -->
             <template v-else-if="item.type == 'time'">
              <a-form-item :key="index" label="日期/时间标题" >
                <a-input
                  style="width:80%"
                  v-decorator="[item.type+(index+1), { rules: [{ required: true, message: '请输入日期/时间标题!' }] }]" placeholder="请输入日期/时间标题" ></a-input>
                  <a class="del-a-btn" @click="delControl(index)">删除</a>
                <div>
                  是否必填: &nbsp;
                   <a-radio-group  v-decorator="['ismust'+(index+1), { initialValue: 0 ,  rules: [{ required: true, message: '请选择!' }] }]">
                     <a-radio :value="0">非必须 </a-radio> <a-radio :value="1">必须</a-radio>
                   </a-radio-group>
                </div>
                <div>
                  精确到: &nbsp;
                  <a-radio-group  v-decorator="['accurate'+(index+1), { initialValue: 0 ,  rules: [{ required: true, message: '请选择!' }] }]">
                     <a-radio :value="0">月</a-radio> <a-radio :value="1">天</a-radio> <a-radio :value="2">时分秒</a-radio>
                  </a-radio-group>
                </div>
              </a-form-item>  
             </template>

            <!-- 下拉控件 -->
             <template v-else-if="item.type == 'select'">
              <a-form-item :key="index" label="下拉控件标题" >
                <a-input
                  style="width:80%"
                  v-decorator="[item.type+(index+1), { rules: [{ required: true, message: '请输入下拉控件标题!' }] }]"  placeholder="请输入下拉控件标题"></a-input>
                  <a class="del-a-btn" @click="delControl(index)">删除</a>
                <div>
                  是否必填: &nbsp;
                    <a-radio-group  v-decorator="['ismust'+(index+1), { initialValue: 0 ,  rules: [{ required: true, message: '请选择!' }] }]">
                      <a-radio :value="0">非必须 </a-radio> <a-radio :value="1">必须</a-radio>
                    </a-radio-group>
                </div>
                <div>
                  能否多选: &nbsp;
                   <a-radio-group  v-decorator="['multiple'+(index+1), { initialValue: 1 ,  rules: [{ required: true, message: '请选择!' }] }]">
                     <a-radio :value="0">能</a-radio> <a-radio :value="1">否</a-radio>
                   </a-radio-group>
                </div>
              </a-form-item>  
              <a-form-item :key="index + 7" label="下拉控件数据源" >
                  <a-input
                       class="input-class"
                        v-decorator="['data'+(index+1), { rules: [{ required: true, message: '请输入下拉控件数据内容,逗号分隔!' }] }]"  placeholder="请输入数据内容,逗号分隔"></a-input>
              </a-form-item> 
             </template>
              <!-- 文件 -->
              <template v-else-if="item.type == 'file'">
                <a-form-item :key="index" label="文件附件标题" >
                  <a-input
                      style="width:80%"
                      v-decorator="['file'+(index+1), { rules: [{ required: true, message: '请输入!' }] }]" placeholder="请输入文件附件标题"></a-input>
                    <a class="del-a-btn" @click="delControl(index)">删除</a>
                  <div>
                    是否必填: &nbsp;
                      <a-radio-group  v-decorator="['ismust'+(index+1), { initialValue: 0 ,  rules: [{ required: true, message: '请输入!' }] }]">
                        <a-radio :value="0">非必须 </a-radio> <a-radio :value="1">必须</a-radio>
                      </a-radio-group>
                  </div>
                  <!-- <div>
                    能否上传多个文件: &nbsp;
                    <a-radio-group  v-decorator="['multiple'+(index+1), { initialValue: 1 ,  rules: [{ required: true, message: '请输入!' }] }]">
                      <a-radio :value="0">能</a-radio> <a-radio :value="1">否</a-radio>
                    </a-radio-group>
                  </div> -->
                </a-form-item>  
              </template>
            </a-col>
           </template>
          </a-row>
           <div class="submit-btn">
              <a-button 
                  type="primary" 
                  @click="handleSubmit(false)"
               >
                 提交
              </a-button>
              <!-- <a-button v-else type="primary"  @click="handleSubmit(true)">
                    保存并进入下一环节
              </a-button> -->
           </div>
          </a-form>
        </div>


        <!-- <div class="steps-action">
          <a-button v-if="current < steps.length - 1" type="primary" @click="next">
            下一个
          </a-button>
          <a-button
            v-if="current == steps.length - 1"
            type="primary"
            @click="$message.success('Processing complete!')"
          >
            完成
          </a-button>
          <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">
            上一个
          </a-button>
        </div> -->

         
       


       </a-spin>
     </a-drawer> 
    </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { MakeLink } from "@/apis";

  export default {
    // 自定义流程
    name: 'ProcessTemplate',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate
    },
    data () {
      return {
         current:0,
         spNum:1,
         dropvisible:false,
         visible:false,
         spinning:false,
         processName:'',
         steps:[
           {
             title: '第1步',
          },
        ],
        //控件数据
        controlData:[],
        needApproval:false,
        isSelectFile:false,
        roleList:[],
        record:{},
        form: this.$form.createForm(this, { name: 'coordinated' }),
      }
    },
    created() {
    },
    methods: {
      loadData(arg) {
        // 加载数据 若传入参数1则加载第一页的内容
        if (arg === 1) {
          this.ipagination.current = 1
        }
        let params = this._getQueryParams() // 查询条件
        console.log(params)
      },
      show(record){
        console.log(record)
        this.form.resetFields();
        this.controlData = [];
        this.record = record
        this.needApproval = false
        this.isSelectFile = false
        this.processName = record.name
        this.getUsRoleInfo();
        this.visible = true
      },
      handleSubmit(e){
        var that = this
        this.form.validateFields((err, values) => {
          if (!err) {
             console.log(values)
             values.processId = this.record.id
             this.spinning = true
             MakeLink.makeStepLink(values).then((res) => {
                  if (res.code ==200) {
                      this.$message.success("保存成功")
                      this.initData();
                      this.form.resetFields();
                      // this.visible = false;
                  } else {
                    that.$message.warning(res.message);
                  }
                  that.spinning = false;
             }) 
          }
        });
      },
      addOnesteps(){
        if(this.steps.length >= 15){
           this.$message.warning("最多只可以添加15个流程")
           return
        }
        this.spNum ++;
        this.steps.push({
          title: '第' + this.spNum + "步",
        });
      },
      delOnesteps(){
        if(this.steps.length <= 1){
          return
        }
        this.spNum --;
        this.steps.pop();
      },
      // 下一步
      next() {
         this.current++;
         this.initData();
      },
      // 上一步
      prev() {
        this.current--;
      },
      titleBlur(e){
        this.form.validateFields((err, values) => {
           let title = values.title
           if(title){
             this.steps[this.current].title = title
           }
        })
      },
      approvalChange(e){
          if(e == 1){
            // 需要
            this.needApproval = true
          }else{
            this.needApproval = false
          }
      },
      addControl(e){
        // if(e =='file'){
        //   if(this.isSelectFile){
        //        this.$message.warning("您已选择了上传文件控件,不可再次选择！")
        //        return
        //   }else{
        //        this.isSelectFile = true;
        //   }
        // }
        let p ={
           type:e
        }
        this.controlData.push(p)
      },
      delControl(e){
        if(this.controlData[e].type == 'file'){
             this.isSelectFile = false;
        }
         this.controlData.splice(e,1);
      },
      getUsRoleInfo(){
         MakeLink.getUsRoleInfo(null).then((res) => {
            if (res.code ==200) {
                this.roleList = res.data
                console.log(this.roleList)
            } else {
              this.$message.warning(res.message);
            }
        }) 
      },
      initData(){
        this.controlData = []
        this.needApproval = false
        this.isSelectFile = false
      },
      onClose(){
        this.refreshTable()
        this.visible = false;
      },
      refreshTable(){
        this.$emit('ok')
      }
    }
  }
</script>
<style lang="less" scoped>
  @import '~@assets/less/common.less';
  .seg-title-all{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .submit-btn{
    display: flex;
    flex-direction:row-reverse;
    justify-content: center;
  }
  .seg-line{
    background-color: @primary-color;
    width: 4px;
    height: 20px;
  }
  .seg-font{
    margin-left: 10px;
    font-size: 16px;
    font-weight: 800;
    
  }
  .del-a-btn{
    margin-left: 20px;
  }
  .seg-row{
    margin-left:20px;
    margin-top:20px
  }
  .seg-col{
    display: flex;
    flex-direction: row;
    
  }
  .seg-col-div{
    width: 100px;
    text-align: right;
    margin-right: 20px;
    /* background-color: red; */
  }

</style>
