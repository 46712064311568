<template>
  <a-card :bordered="false">
      <div class="pdf-title">
        <div class="pdf-title-line"></div>
        <div><strong style="margin-left:5px;font-size:16px">PDF转WORD</strong></div>
      </div>
      <a-upload-dragger
        :file-list="fileList" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload"
        accept=".pdf"
      >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">
            点击或者拖拽PDF文件
          </p>
          <p class="ant-upload-hint">
            仅支持pdf转为docx文件,并且pdf页数不能超过10页,若有pdf大文件,请先拆分
          </p>
      </a-upload-dragger>
      <div class="pdf-opt-btn">
        <a-button :loading="confirmLoading"  @click="castToWord" type="primary" icon="file-word">转换</a-button>
        <a-button @click="cleanFile"  icon="reload"  style="margin-left: 8px" >清空</a-button >
      </div>
      <a-divider />
      <div class="pdf-title">
        <div class="pdf-title-line"></div>
        <div><strong style="margin-left:5px;font-size:16px">PDF合并</strong></div>
      </div>
      <a-upload-dragger
        :file-list="mergeList" :multiple="true" :remove="mergeListRemove" :before-upload="mergeListbeforeUpload"
        accept=".pdf"
      >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">
            点击或者拖拽多个PDF文件
          </p>
          <p class="ant-upload-hint">
            pdf合并,合并结果顺序按照上传或者拖拽顺序
          </p>
      </a-upload-dragger>
      <div class="pdf-opt-btn">
        <a-button :loading="mergeLoading"  @click="mergePdf" type="primary" icon="block">合并</a-button>
        <a-button @click="cleanMergeFile"  icon="reload"  style="margin-left: 8px" >清空</a-button >
      </div>


      <a-divider />
      <div class="pdf-title">
        <div class="pdf-title-line"></div>
        <div><strong style="margin-left:5px;font-size:16px">PDF拆分</strong></div>
      </div>
      <a-upload-dragger
        :file-list="splitList" :multiple="true" :remove="splitRemove" :before-upload="splitbeforeUpload"
        accept=".pdf"
      >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">
            点击上传或者拖拽PDF文件
          </p>
          <p class="ant-upload-hint">
            pdf拆分,请输入拆分后每个文档包含页数,如果最后页数不足,按照实际算
          </p>
      </a-upload-dragger>
       <a-row style="margin-top:20px">   
          <a-col :span="24">
            <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="拆分后每个页数" prop="remark">
              <a-input-number style="width:50%;" :min="1" :max="100" :precision="0" v-model="splitPage" placeholder="请输入拆分后每个文档的页数" />
            </a-form-model-item>
          </a-col>
        </a-row>
      <div class="pdf-opt-btn">
        <a-button :loading="splitLoading"  @click="splitPdf" type="primary" icon="api">拆分</a-button>
        <a-button @click="cleanSplitFile"  icon="reload"  style="margin-left: 8px" >清空</a-button >
      </div>
      
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { httpAction, getAction,uploadBlobAction,toDownDocx,toDownPdf } from '@/api/manage'

  export default {
    name: 'AuxExcelList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate
    },
    data () {
      return {
        description: 'pdf操作页面',
        splitPage:'',
        url:{
          pdfToWord:'/khcrm/web/pdf/pdfToWord',
          mergePdf:'/khcrm/web/pdf/mergePdf',
          splitterPdf:'/khcrm/web/pdf/splitterPdf'
        },
        // 转换
        fileList:[],
        confirmLoading:false,
        
        // 合并
        mergeLoading:false,
        mergeList:[],

        // 拆分
        splitLoading:false,
        splitList:[],
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
      }
    },
    created() {
      
    },
     
    methods: {
       loadData(){

       },
       handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      beforeUpload(file) {
        this.fileList = []
        this.fileList = [...this.fileList, file];
        return false;
      },
      mergeListRemove(file){
        const index = this.mergeList.indexOf(file);
        const newFileList = this.mergeList.slice();
        newFileList.splice(index, 1);
        this.mergeList = newFileList;
      },
      mergeListbeforeUpload(file){
        this.mergeList = [...this.mergeList, file];
        return false;
      },
      splitRemove(file){
        const index = this.splitList.indexOf(file);
        const newFileList = this.splitList.slice();
        newFileList.splice(index, 1);
        this.splitList = newFileList;
      },
      splitbeforeUpload(file){
        this.splitList = []
        this.splitList = [...this.splitList, file];
        return false;
      },
      cleanFile(){
        this.fileList = []
      },
      cleanMergeFile(){
        this.mergeList = []
      },
      cleanSplitFile(){
         this.splitPage = ''
         this.splitList = []
      },
      // 转换
      castToWord(){
        let fileList = this.fileList
        if(fileList == null || fileList.length <= 0){
          this.$message.warning("请先选择要上传的文件！");
          return
        }
          const formData = new FormData();
          if(fileList.length >0){
              fileList.forEach(file => {
                  formData.append('file', file);
              });
          }
          this.confirmLoading = true;
          let that = this
          uploadBlobAction(this.url.pdfToWord,formData).then((res)=>{
            let fileReader = new FileReader();
            fileReader.onload = function() {
              try {
                  let jsonData = JSON.parse(fileReader.result);  // 说明是普通对象数据，后台转换失败
                  that.$message.warning(jsonData.message);
              } catch (err) { 
                  // 解析成对象失败，说明是正常的文件流
                  var fileName = '转换后的文件' + Math.ceil(Math.random()*100000)+'.docx';
                  toDownDocx(res,fileName);
                  that.$message.success("操作成功");
                  that.cleanFile();
                  that.$emit('ok');
              } 
            }; 
            fileReader.readAsText(res) 
            that.confirmLoading = false;
          })
      },
      // 合并
      mergePdf(){
        let mergeList = this.mergeList
        if(mergeList == null || mergeList.length <= 0){
          this.$message.warning("请先选择要上传的文件！");
          return
        }
        const formData = new FormData();
          mergeList.forEach(file => {
            formData.append('fileList', file);
          });
          this.mergeLoading = true;
          let that = this
          uploadBlobAction(this.url.mergePdf,formData).then((res)=>{
            let fileReader = new FileReader();
            fileReader.onload = function() {
              try {
                  let jsonData = JSON.parse(fileReader.result);  // 说明是普通对象数据，后台转换失败
                  that.$message.warning(jsonData.message);
              } catch (err) { 
                  // 解析成对象失败，说明是正常的文件流
                  var fileName = '合并后的文件' + Math.ceil(Math.random()*100000)+'.pdf';
                  toDownPdf(res,fileName);
                  that.$message.success("操作成功");
                  that.cleanMergeFile();
                  that.$emit('ok');
              } 
            }; 
            fileReader.readAsText(res) 
            that.mergeLoading = false;
          })
      },
      // 拆分
      splitPdf(){
        let splitList = this.splitList
        if(splitList == null || splitList.length <= 0){
          this.$message.warning("请先选择要上传的文件！");
          return
        }
        if(this.splitPage == ''){
          this.$message.warning("请输入拆分后每个页数！");
          return
        }
        const formData = new FormData();
          splitList.forEach(file => {
            formData.append('file', file);
          });
         formData.append('splitPage', this.splitPage);
          this.splitLoading = true;
          let that = this
          uploadBlobAction(this.url.splitterPdf,formData).then((res)=>{
            let fileReader = new FileReader();
            fileReader.onload = function() {
              try {
                  let jsonData = JSON.parse(fileReader.result);  // 说明是普通对象数据，后台转换失败
                  that.$message.warning(jsonData.message);
              } catch (err) { 
                  // 解析成对象失败，说明是正常的文件流
                  var fileName = '压缩文件' + Math.ceil(Math.random()*100000)+'.zip';
                  toDownPdf(res,fileName);
                  that.$message.success("操作成功");
                  that.cleanSplitFile();
                  that.$emit('ok');
              } 
            }; 
            fileReader.readAsText(res) 
            that.splitLoading = false;
          })
      }
    }
  }
</script>
<style lang="less">
  @import '~@assets/less/common.less';
  .pdf-title{
    display: flex;
    flex-direction:row;
    align-items: center;
    margin-bottom:20px;
  }
  .pdf-title-line{
    background-color: @primary-color;
    width: 4px;
    height: 20px;
  }
  .pdf-opt-btn{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>