<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
    wrapClassName="ant-modal-cust-warp"
    style="top: 5%; height: 85%; overflow-y: hidden"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="角色名称"
        >
          <a-input
            placeholder="请输入角色名称"
            v-decorator.trim="['roleName', validatorRules.roleName]"
          />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="角色编码"
        >
          <a-input
            placeholder="请输入角色编码"
            :disabled="roleDisabled"
            v-decorator.trim="['roleCode', validatorRules.roleCode]"
          />
        </a-form-item>

        <!-- <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="描述">
          <a-textarea
            :rows="5"
            placeholder="..."
            v-decorator="['description', validatorRules.description]"
          />
        </a-form-item> -->
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from "lodash.pick";
import { addRole, editRole, duplicateCheck } from "@/api/api";

export default {
  name: "RoleModal",
  data() {
    return {
      title: "操作",
      visible: false,
      roleDisabled: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        roleName: {
          rules: [
            { required: true, message: "请输入角色名称!" },
            {
              min: 2,
              max: 30,
              message: "长度在 2 到 30 个字符",
              trigger: "blur",
            },
          ],
        },
        roleCode: {
          rules: [
            { required: true, message: "请输入角色名称!" },
            {
              min: 0,
              max: 64,
              message: "长度不超过 64 个字符",
              trigger: "blur",
            },
            { validator: this.validateRoleCode },
          ],
        },
        description: {
          rules: [
            {
              min: 0,
              max: 126,
              message: "长度不超过 126 个字符",
              trigger: "blur",
            },
          ],
        },
      },
    };
  },
  created() {},
  methods: {
    add() {
      this.edit({});
    },
    edit(record) {
      this.form.resetFields();
      this.model = Object.assign({}, record);
      this.visible = true;

      //编辑页面禁止修改角色编码
      if (this.model.id) {
        this.roleDisabled = true;
      } else {
        this.roleDisabled = false;
      }
      this.$nextTick(() => {
        this.form.setFieldsValue(
          pick(this.model, "roleName", "description", "roleCode")
        );
      });
    },
    close() {
      this.$emit("close");
      this.visible = false;
    },
    handleOk() {
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = Object.assign(this.model, values);
          let obj;
          if (!this.model.id) {
            obj = addRole(formData);
          } else {
            obj = editRole(formData);
          }
          obj
            .then((res) => {
              const { code, message } = res;
              if (code == 200) {
                that.$message.success(message);
                that.$emit("ok");
              } else {
                that.$message.warning(message);
              }
            })
            .finally(() => {
              that.confirmLoading = false;
              that.close();
            });
        }
      });
    },
    handleCancel() {
      this.close();
    },
    validateRoleCode(rule, value, callback) {
      console.log(/[^a-zA-Z]/g.test(value));
      if (/[^a-zA-Z]/g.test(value)) {
        callback("角色编码只可以输入字母!");
      } else {
        callback();
      }
    },
  },
};
</script>

<style scoped>
</style>