<template>
  <div>
  <a-card>
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24" class="companyClass" >
          <a-col :md="10" :sm="12">
            <a-form-item label="关键词">
              <a-input-search v-model="queryParam.name" :maxLength="200" placeholder="请输入公司名称查询" enter-button="搜一搜" size="default"
                  @search="searchQuery" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-form-item label="所在地区">
            <a-col :md="3" :sm="5">
                <a-select v-model="queryParam.province" placeholder="省" @change="provinceChange" :labelInValue="true" allowClear>
                  <a-select-option v-for="(item,index) in provinceList" :key="index" :value="item.id">{{item.cityname}}</a-select-option>
                </a-select>
            </a-col>
            <a-col :md="3" :sm="5" style="margin-left:10px">
                <a-select v-model="queryParam.city" placeholder="市" @change="cityChange" :labelInValue="true" allowClear>
                   <a-select-option v-for="(item,index) in cityList" :key="index" :value="item.id">{{item.cityname}}</a-select-option>
                </a-select>
            </a-col>
            <a-col :md="3" :sm="5" style="margin-left:10px">
                <a-select v-model="queryParam.county" placeholder="区" allowClear :labelInValue="true">
                  <a-select-option v-for="(item,index) in countyList" :key="index" :value="item.cityname">{{item.cityname}}</a-select-option>
                </a-select>
            </a-col>
            <a-col :md="6" :sm="8">
                <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                  <a @click="handleToggleSearch" style="margin-left: 8px">
                    {{ toggleSearchStatus ? '收起' : '展开' }}
                    <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
                  </a>
                </span>
            </a-col>
          </a-form-item>
          <div v-if="toggleSearchStatus" style="margin-top:-20px">
              <!-- <a-form-item label="所属行业">
                <a-col :md="3" :sm="5">
                    <a-select v-model="queryParam.belongInd" placeholder="一级行业">
                      <a-select-option value="">请选择</a-select-option>
                      <a-select-option :value="0">正常</a-select-option>
                      <a-select-option :value="1">冻结</a-select-option>
                    </a-select>
                </a-col>
                <a-col :md="3" :sm="5" style="margin-left:10px">
                    <a-select v-model="queryParam.belongInd1" placeholder="二级行业">
                      <a-select-option value="">请选择</a-select-option>
                      <a-select-option :value="0">正常</a-select-option>
                      <a-select-option :value="1">冻结</a-select-option>
                    </a-select>
                </a-col>
              </a-form-item> -->
              <a-form-item label="营业状态">
                <a-col :md="18" :sm="20">
                  <a-checkbox-group v-model="queryParam.businessData" :options="businessListOptons" />
                </a-col>
              </a-form-item>
              <a-form-item label="联系方式">
                <a-col :md="12" :sm="12">
                  <a-checkbox-group v-model="queryParam.phoneData" :options="contactOptons"/>
                </a-col>
              </a-form-item>
              <a-form-item label="成立年限">
                <a-col :md="18" :sm="20">
                  <a-checkbox-group v-model="queryParam.establishData" :options="yearOptons"  />
                </a-col>
              </a-form-item>
              <a-form-item label="企业类型">
                <a-col :md="18" :sm="20">
                   <a-checkbox-group v-model="queryParam.companyType" :options="companyOptons"  />
                </a-col>
              </a-form-item>
          </div>
        </a-row>
      </a-form>
    </div>
      
  </a-card>
  <a-card style="margin-top: 10px">
      <!-- table区域-begin -->
    <div>
      <strong >搜索结果</strong>
      <a-table
        bordered
        style="margin-top:10px"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange"
      >
          <template slot="phone" slot-scope="text, record">
            <a-tag color="#1890FF"><a-icon type="phone" style="margin-right:5px" />点击获取号码</a-tag>
          </template>
          <template slot="opt" slot-scope="text, record">
            <a @click="showDetail(record)">详情</a>
          </template>


           <template slot="companyInfo" slot-scope="text, record">
              <div class="companyInfo-body">
                <div style="color:#708090;font-weight:800;"><span v-html="record.name"> </span> | <a-tag :color="record.regStatus < 5?'#DA70D6':'#FF2D2D'">{{record.regStatusStr}}</a-tag></div>
                <div style="margin-top:5px;">{{record.legalPerson}} | {{record.creatDate}} | {{record.regMoneyStr}}</div>
                 <div class="companyInfo-middle">
                  <div><strong>参保人数: </strong>{{record.insuredNum}}人</div>
                  <div style="margin-left:35px;"><strong>成立年限: </strong>{{record.regYear}}年</div>
                </div>
                <div class="companyInfo-middle">
                  <div><strong>所属行业: </strong>{{record.belongInd}}</div>
                  <div style="margin-left:35px;"><strong>地址: </strong>{{record.address}}</div>
                  <div style="margin-left:35px;"><strong>网址: </strong><span style="color:#1890FF;">{{record.website}}</span></div>
                </div>
                <div style="margin-top:5px;"> 
                  <a-tag color="#87d068" v-if="record.isWx == 1">可添微信</a-tag>
                  <a-tag color="#6C6C6C" v-else>不可添微信</a-tag>
                  <a-tag color="#108ee9" v-if="record.isAlipay == 1">可添支付宝</a-tag>
                  <a-tag color="#6C6C6C" v-else>不可添支付宝</a-tag>
                </div>

              </div>
              
          </template>
      
      </a-table>
    </div>  
  </a-card>
   <a-card style="margin-top: 10px">
     <div class="guess-title">
      <strong>猜您喜欢</strong>
      <span style="color:#1890FF;cursor:pointer" @click="getGussetCompanyList()"><a-icon type="redo"  /> 换一批</span>
     </div>
     <a-spin tip="加载中...." :spinning="spinning">
        <div class="guess-card-body">
          <a-card v-for="(item,index) in cardData" :key="index" size="small" :title="item.name"  class="guess-card" @click="showDetail(item)">
            <p style="color:#C0C0C0;" class="than-hidden">{{item.legalPerson}} | {{item.creatDate}} | {{item.regMoneyStr}}</p>
            <p style="margin-top:-10px;" class="than-hidden"><span style="color:	#696969;">所属行业:</span> {{item.belongInd}}</p>
            <p style="margin-top:-10px">
              <a-tag :color="item.regStatus < 5?'#DA70D6':'#FF2D2D'">{{item.regStatusStr}}</a-tag>
              <a-tag color="#87d068" v-if="item.isWx == 1">可添微信</a-tag>
              <a-tag color="#6C6C6C" v-else>不可添微信</a-tag>
              <a-tag color="#108ee9" v-if="item.isAlipay == 1">可添支付宝</a-tag>
              <a-tag color="#6C6C6C" v-else>不可添支付宝</a-tag>
            </p>
          </a-card>
        </div>
      </a-spin>  
     
   </a-card>



   <!-- 弹出层 -->
   <a-drawer width="680" placement="right" :closable="true" :visible="visible" @close="onClose">
      <a-row>
        <strong style="font-size:18px"><span v-html="recordData.name"> </span></strong>
      </a-row>
      <a-divider />

      <a-row>
        <a-col :span="6"><span style="color:#C0C0C0;">法人:</span>  {{recordData.legalPerson}}</a-col>
        <a-col :span="8"><span style="color:#C0C0C0;">成立时间:</span>  {{recordData.creatDate}}</a-col>
        <a-col :span="10"><span style="color:#C0C0C0;">注册资金:</span>  {{recordData.regMoneyStr}}</a-col>
      </a-row>
      <a-row style="margin-top:10px">
        <a-col :span="6"><span style="color:#C0C0C0;">登记状态:</span>  {{recordData.regStatusStr}}</a-col>
        <a-col :span="8"><span style="color:#C0C0C0;">参保人数:</span>  {{recordData.insuredNum}} 人</a-col>
        <a-col :span="10"><span style="color:#C0C0C0;">核准日期:</span>  {{recordData.checkDate}}</a-col>
      </a-row>
      <a-row style="margin-top:10px">
        <a-col :span="14"><span style="color:#C0C0C0;">地址:</span>  {{recordData.address}}</a-col>
        <a-col :span="10"><span style="color:#C0C0C0;">网址:</span>  {{recordData.website}}</a-col>
      </a-row>
      <a-tabs default-active-key="1" @change="tabcallback">
          <a-tab-pane key="1" tab="基本信息">
            <div style="margin-left:20px">
                <a-row>
                    <a-col :span="8"><span style="color:#C0C0C0;">是否可添加微信:</span>  {{recordData.isWx == 1?'是' : '否'}}</a-col>
                    <a-col :span="14"><span style="color:#C0C0C0;">上次校验日期:</span>  {{recordData.wxDate}}</a-col>
                </a-row>
                <a-divider />
                <a-row>
                    <a-col :span="8"><span style="color:#C0C0C0;">是否可添加支付宝:</span>  {{recordData.isAlipay == 1?'是' : '否'}}</a-col>
                    <a-col :span="14"><span style="color:#C0C0C0;">上次校验日期:</span>  {{recordData.alipayDate}}</a-col>
                </a-row>
                <a-divider />
                <a-row>
                  <a-col :span="8"><span style="color:#C0C0C0;">注册号:</span>  {{recordData.regCode}}</a-col>
                  <a-col :span="14"><span style="color:#C0C0C0;">统一社会信用代码:</span>  {{recordData.creditCode}}</a-col>
                </a-row>
                <a-divider />
                <a-row>
                  <a-col :span="8"><span style="color:#C0C0C0;">成立年限:</span>  {{recordData.regYear}} 年</a-col>
                  <a-col :span="14"><span style="color:#C0C0C0;">纳税人识别号:</span>  {{recordData.taxpayer}}</a-col>
                </a-row>
                <a-divider />
                <a-row>
                  <a-col :span="8"><span style="color:#C0C0C0;">组织机构代码:</span>  {{recordData.orgCode}}</a-col>
                  <a-col :span="14"><span style="color:#C0C0C0;">企业类型:</span>  {{recordData.companyTypeStr}}</a-col>
                </a-row>
                <a-divider />
                <a-row>
                  <a-col :span="8"><span style="color:#C0C0C0;">曾用名:</span>  {{recordData.usedName}}</a-col>
                  <a-col :span="14"><span style="color:#C0C0C0;">所属行业:</span>  {{recordData.belongInd}}</a-col>
                </a-row>
                <a-divider />
                <a-row>
                  <a-col :span="24"><span style="color:#C0C0C0;">经营范围:</span>  {{recordData.busNature}}</a-col>
                </a-row>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="招标投标" force-render>
            暂无数据
          </a-tab-pane>
        </a-tabs>
      <a-divider />
      <a-row>
         <a-button @click="onClose">关闭</a-button>
      </a-row>
    </a-drawer>
  </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { Company } from "@/apis";
  import { deepClone} from '@/utils/util'

  import moment from 'moment'

  export default {
    name: "SearchCompany",
    mixins: [JeecgListMixin],
    components: {
    },
    data() {
      return {
        provinceList:[],// 省
        cityList:[],//市
        countyList:[],//区
        businessListOptons:[
          { label: '在业', value: '1' },
          { label: '续存', value: '2' },
          { label: '迁入', value: '3' },
          { label: '迁出', value: '4' },
          { label: '吊销', value: '5' },
          { label: '注销', value: '6' },
          { label: '停业', value: '7' },
          { label: '撤销', value: '8' },
          { label: '其他', value: '30' },
        ],
        contactOptons:[
          { label: '有手机号', value: '1' },
          { label: '有固话', value: '2' },
          { label: '有邮箱', value: '3' },
          { label: '可添加微信', value: '4' },
          { label: '可添加支付宝', value: '5' }
        ],
        yearOptons:[
          { label: '1年以下', value: '1' },
          { label: '1-3年', value: '2' },
          { label: '3-5年', value: '3' },
          { label: '5-10年', value: '4' },
          { label: '10-15年', value: '5' },
          { label: '15-20年', value: '6' },
          { label: '20年以上', value: '7' }
        ],
        companyOptons:[
          { label: '个人独资', value: '1' },
          { label: '股份制', value: '2,3' },
          { label: '合伙企业', value: '4' },
          { label: '集团所有制', value: '5' },
          { label: '有限责任公司', value: '8' },
          { label: '台港澳公司', value: '12,14,15' },
          { label: '外企', value: '17' },
          { label: '其他', value: '6,7,9,10,11,13,16,18,19,20,21,22,30' }
        ],
        cardData:[],
        spinning:false,
        visible:false,
        businessData:[], // 营业状态
        phoneData:[], // 联系方式
        establishData:[],// 成立年限
        columns:[
          {
            title: '联系方式',
            dataIndex: 'phone',
            width: '15%',
            scopedSlots: { customRender: 'phone' },
          },
          {
            title: '公司信息',
            dataIndex: 'companyInfo',
            scopedSlots: { customRender: 'companyInfo' },
          },
          {
            title: '操作',
            dataIndex: '',
            scopedSlots: { customRender: 'opt' },
          }
        ],
        loading:false,
        dataSource:[],
        recordData:[],
        isfind:false,
        // rowClick: record => ({
        //   on: {
        //     click: () => {
        //       this.showDetail(record);
        //     }
        //   }
        // }),
        url: {
          exportXlsUrl: '/'
        }
      }
    },
    created(){
        this.getGussetCompanyList();
        this.getProvince()
    },
    computed: {
      importExcelUrl: function () {
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
      }
    },
    methods: {
      // 表格数据
      getTableListByParam(params){
        this.loading = true;
        Company.findTableListByParam(params).then((res) => {
            if (res.code === 200) {
                this.dataSource = res.data
                this.ipagination.total = res.total
            } else {
              this.$message.warning(res.message)
            }
            this.loading = false;
         })
      },
      // 猜您喜欢
      getGussetCompanyList(){
        let params={};
        this.spinning = true;
        Company.getGussetCompanyList(params).then((res) => {
            if (res.code === 200) {
                this.cardData = res.data
            } else {
              this.$message.warning(res.message)
            }
            this.spinning = false;
         })
      },
      // 获取省
      getProvince(){
         let params = {
            pid : 1
         }
         Company.getCityList(params).then((res) => {
            if (res.code === 200) {
               this.provinceList = res.data
            } else {
              this.$message.warning(res.message)
            }
         })
      },
      provinceChange(e){
        let params = {
          pid:e.key
        }
        this.queryParam.city = undefined
        this.getCity(params);
      },
       // 获取市
      getCity(params){
         Company.getCityList(params).then((res) => {
            if (res.code === 200) {
               this.cityList = res.data
            } else {
              this.$message.warning(res.message)
            }
         })
      },
      cityChange(e){
        let params = {
          pid:e.key
        }
        this.getCounty(params);
      },
      // 获取区
      getCounty(params){
         Company.getCityList(params).then((res) => {
            if (res.code === 200) {
               this.countyList = res.data
            } else {
              this.$message.warning(res.message)
            }
         })
      },
      showDetail(record){
        this.visible = true;
        this.recordData = record;
      },
      searchQuery() {
        this.isfind = true;
        this.loadData(1);
      },
      loadData(arg) {
        // 加载数据 若传入参数1则加载第一页的内容
        if (arg === 1) {
          this.ipagination.current = 1
        }
        let params = this._getQueryParams() // 查询条件PayFinance
        let obj =  deepClone(params);
        if(obj.city){
          obj.city = obj.city.label 
        }
        if(obj.province){
          obj.province = obj.province.label 
        }
        if(obj.county){
          obj.county = obj.county.label 
        }
        
        if(this.isfind){
          this.getTableListByParam(obj);
        }
      },
      onClose() {
        this.visible = false;
      },
      tabcallback(key){

      }
    }
  }
</script>

<style scoped>
  .layout-item{
    width: 100%;
  }
  .table-page-search-wrapper{
    margin-left: 20px;
  }

  .companyClass{
    display:flex;
    justify-content:center;
  }

  .companyInfo-body{
     display: flex;
     flex-direction: column;
     color:	#8E8E8E;
  }
  .companyInfo-middle{
     display: flex;
     flex-direction: row;
     margin-top: 5px;
  }
  .than-hidden{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .guess-title{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
  }
  .guess-card-body{
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     cursor:pointer;
  }
  .guess-card{
    width: 260px;
    margin-top:10px;
    height: 130px;
    margin-left: 15px;
  }

</style>