<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="商机名称" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="businessName">
              <a-input v-model="model.businessName" placeholder="请输入商机名称"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <div @click="selectCustomer">
              <a-form-model-item  label="客户" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="customerId">
                <a-select style="width: 100%" v-model="model.customerId" placeholder="请选择客户" :open="false">
                  <template v-for="(item,i) in cusrecord" >
                        <a-select-option :key="i" :value="item.id">{{item.name}}</a-select-option>
                  </template>
                </a-select>
              </a-form-model-item>
            </div>
          </a-col>
        </a-row>
        <a-row>  
          <a-col :span="12">
            <a-form-model-item label="商机金额(元)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="money">
              <a-input-number v-model="model.money" placeholder="请输入商机金额" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="整单折扣(%)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="discountRate">
              <a-input-number v-model="model.discountRate" placeholder="请输入整单折扣" style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>   
          <a-col :span="12">
            <a-form-model-item label="预计成交日期" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="dealDate">
              <j-date placeholder="请选择预计成交日期" v-model="model.dealDate" :dateFormat="dateFormat"  style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="下次联系时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="nextTime">
                <j-date placeholder="请选择下次联系时间" v-model="model.nextTime" :dateFormat="dateFormat"  style="width: 100%" />              </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>   
          <a-col :span="24">
            <a-form-model-item label="备注" prop="remark">
              <a-textarea v-model="model.remark" rows="3" placeholder="请输入备注" />
            </a-form-model-item>
          </a-col>
           
        </a-row>
      </a-form-model>
    </j-form-container>
    <select-customer-modal ref="modalForm" @ok="modalFormOk" />
  </a-spin>
</template>

<script>

  import { httpAction, getAction } from '@/api/manage'
  import { deepClone } from '@/utils/util'
  import JFormContainer from '@/components/jeecg/JFormContainer'
  import JDate from '@/components/jeecg/JDate.vue'
  import SelectCustomerModal from '../../customer/modules/SelectCustomerModal.vue'
  import { Business } from "@/apis";

  export default {
    name: 'KhcrmBusinessForm',
    components: {
      JFormContainer,
      JDate,
      SelectCustomerModal

    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        model:{
         },
        dateFormat:"YYYY-MM-DD HH:mm",
        cusrecord: [],
        isNotShow: false,
        customerDisabled:true,
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
        confirmLoading: false,
        validatorRules: {
           businessName: [
              { required: true, message: '请输入商机名称!'},
           ],
           customerId:[
              { required: false, message: '请选择客户!'},
           ],
           statusEnd: [
              { required: false, message: '请输入0未开始 1赢单2输单3无效!'},
           ],
        },
        url: {
          add: "/khcrm/web/khcrmBusiness/add",
          edit: "/khcrm/web/khcrmBusiness/edit",
          queryById: "/khcrm/web/khcrmBusiness/queryById"
        },
        statusGroup:[],
        statusList:[],
        form: this.$form.createForm(this),

      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
      this.getBusStatusGroup();
    },
    methods: {
      add () {
        this.customerDisabled = true
        this.isNotShow = false
        this.edit(this.modelDefault);
      },
      edit (record) {
        console.log(record)
        if(record.id){
          this.isNotShow  = true
          let data = {
              id:record.customerId,
              name:record.customerName
          }
          this.cusrecord = [];
          this.cusrecord.push(data)
        }
        if(record._isOnlyAddCus){
           let data = {
              id:record.customerId,
              name:record.customerName
          }
          this.cusrecord = [];
          this.cusrecord.push(data)
          this.customerDisabled = false
        }else{
          this.customerDisabled = true
        }
       
        this.model = Object.assign({}, record);
        this.visible = true;
      },
      selectCustomer(){
        if(this.customerDisabled){
           this.$refs.modalForm.show();
        }
      },
      getBusStatusGroup(){
          Business.getBusStatusGroup(null).then((res) => {
              if (res.code ==200) {
                 this.statusGroup = res.data
             
              } else {
                this.$message.warning(res.message);
              }
          }) 
      },
      groupchange(id){
        delete this.model['statusId']
        let param = {
            id:id
        }
        Business.getBusStatusByGroupId(param).then((res) => {
              if (res.code ==200) {
                 this.statusList = res.data
              } else {
                this.$message.warning(res.message);
              }
          }) 
      },
      modalFormOk(e){
          this.cusrecord = [];
          this.cusrecord.push(e)
        //  this.form.setFieldsValue({ customerId: e.id });
          this.model.customerId = e.id
          this.cusvisible = false
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            httpAction(httpurl,this.model,method).then((res)=>{
              if(res.code == 200){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },
    }
  }
</script>