<template>
    <div>
        <a-modal v-model="visible" title="修改控件信息" @ok="handleOk" :confirmLoading="confirmLoading">
          <a-form  :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12}">
             <a-form-item label="控件类型">
                  <a-select @change="controlChange" v-decorator="['type', { initialValue: conType , rules: [{ required: true, message: '请选择控件类型!' }] }]"  placeholder="请选择控件类型">
                      <a-select-option v-for="item in controlType" :key="item.id" :value="item.code" >{{item.name}}</a-select-option>
                  </a-select>
              </a-form-item>
             <a-form-item label="控件标题名称">
                  <a-input 
                    v-decorator="['title', { initialValue: record.title ,rules: [{ required: true, message: '请输入控件标题名称!' }] }]"
                    placeholder="请输入控件标题名称"></a-input>
              </a-form-item>
              <a-form-item label="是否必填">
                  <a-radio-group v-decorator="['isMust', { initialValue: record.isMust , rules: [{ required: true, message: '请选择!' }] }]">
                    <a-radio :value="0">非必须 </a-radio> <a-radio :value="1">必须</a-radio>
                  </a-radio-group>
              </a-form-item>
               
               <a-form-item label="能否为负数" v-if="conType == 'number'">
                  <a-radio-group  v-decorator="['isNegative', { initialValue: record.isNegative ,  rules: [{ required: true, message: '请选择!' }] }]">
                       <a-radio :value="0">能</a-radio> <a-radio :value="1">否</a-radio>
                  </a-radio-group>
              </a-form-item>

              <a-form-item label="能否为小数" v-if="conType == 'number'">
                  <a-radio-group  v-decorator="['isDecimal', { initialValue: record.isDecimal ,  rules: [{ required: true, message: '请选择!' }] }]">
                       <a-radio :value="0">能 </a-radio> <a-radio :value="1">否</a-radio>
                  </a-radio-group>
              </a-form-item>

              <a-form-item label="精确到" v-if="conType == 'time'">
                  <a-radio-group  v-decorator="['accurate', { initialValue: record.accurate ,  rules: [{ required: true, message: '请选择!' }] }]">
                     <a-radio :value="0">月</a-radio> <a-radio :value="1">天</a-radio> <a-radio :value="2">时分秒</a-radio>
                  </a-radio-group>
              </a-form-item>

               <a-form-item label="能否多选" v-if="conType == 'select'">
                   <a-radio-group  v-decorator="['multiple', { initialValue: record.multiple ,  rules: [{ required: true, message: '请选择!' }] }]">
                     <a-radio :value="0">能</a-radio> <a-radio :value="1">否</a-radio>
                   </a-radio-group>
              </a-form-item>

              <a-form-item label="下拉控件数据源" v-if="conType == 'select'" >
                  <a-input
                       class="input-class"
                        v-decorator="['dataSource', { initialValue: record.dataSource , rules: [{  required: true, message: '请输入下拉控件数据内容,逗号分隔!' }] }]"  placeholder="请输入数据内容,逗号分隔"></a-input>
              </a-form-item> 
                         
          </a-form>
        </a-modal>   
    </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { MakeLink } from "@/apis";
  import moment from 'moment'

  export default {
    // 修改控件信息
    name: 'UpdateControlModal',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate
    },
    data () {
      return {
        spinning:false,
        visible:false,
        confirmLoading:false,
        record:{},
        addData:[],//添加控件的数据
        // 控件类型
        conType:'',
        controlType:[
          {
             code:'text',
             name:'文本输入框',
             id:1
          },{
             code:'number',
             name:'数值输入框',
             id:2
          }, {
             code:'textarea',
             name:'文本域',
             id:3
          } ,{
             code:'time',
             name:'时间控件',
             id:4
          }, {
             code:'select',
             name:'下拉控件',
             id:5
          }, {
             code:'file',
             name:'文件控件',
             id:6
          }
        ],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        isAdd : false
      }
    },
    created() {
    },
    methods: {
      loadData(arg) {
        // 加载数据 若传入参数1则加载第一页的内容
        if (arg === 1) {
          this.ipagination.current = 1
        }
        let params = this._getQueryParams() // 查询条件
        console.log(params)
      },
      show(record,isAdd){
        console.log(record)
        this.form.resetFields();
        if(isAdd){
          this.addData = record
          this.record = {}
          this.conType = undefined;
        }else{
          this.addData = {}
          this.conType = record.type
          this.record = record
        }
        this.isAdd = isAdd
        this.visible = true
      },
      handleOk(){
         this.form.validateFields((err, values) => {
           if (!err) {
              if(this.isAdd){
                  // 添加控件
                 let addData = this.addData
                 values.processId = addData.processId
                 values.stepId = addData.id
              }else{
                // 修改控件
                  values.id = this.record.id
                  
              }
              this.confirmLoading = true
              MakeLink.saveOrUpdatePage(values).then((res) => {
                if (res.code ==200) {
                  this.$emit('ok');
                  this.visible = false
                } else {
                  this.$message.warning(res.message);
                }
              }).finally(() => {
                this.confirmLoading = false
              })
           }
        })
      },
      controlChange(e){
         this.conType = e
      }
    }
  }
</script>
<style lang="less" scoped>
  @import '~@assets/less/common.less';
  .seg-title-all{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .submit-btn{
    display: flex;
    flex-direction:row-reverse;
    justify-content: center;
  }
  .input-class{
    width:75%;
    margin-left:10px
  }

  .seg-line{
    background-color: @primary-color;
    width: 4px;
    height: 20px;
  }
  .seg-font{
    margin-left: 10px;
    font-size: 16px;
    font-weight: 800;
    
  }
  .del-a-btn{
    margin-left: 20px;
  }
  .seg-row{
    margin-left:20px;
    margin-top:20px
  }
  .seg-col{
    display: flex;
    flex-direction: row;
    
  }
  .seg-col-div{
    width: 100px;
    text-align: right;
    margin-right: 20px;
    /* background-color: red; */
  }
 .control-width{
   width: 75%;
 }
</style>
