<template>
    <a-modal
      :title="title + '-【' + titleShop + '】'" 
      :width="width"
      :visible="visible"
      :confirmLoading="confirmLoading"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :footer="null"
      cancelText="关闭">
       <!-- 操作按钮区域 -->
      <div class="table-operator" style="margin-bottom:10px">
        <a-button @click="addShopSpecs" type="primary" icon="plus">新增规格</a-button>
         <a-button type="primary" icon="reload" style="margin-left: 8px"
                @click="resetData">刷新数据</a-button>
      </div>
        <a-table
          ref="table"
          size="middle"
          :scroll="{x:1000}"
          bordered
          rowKey="id"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="false"
          :loading="loading"
          class="j-table-force-nowrap">

          <template slot="realMoney" slot-scope="text, record">
            <a-tag color="green">
              {{record.realMoney}} 元
            </a-tag>
          </template>
          <template slot="stock" slot-scope="text, record">
            <div>
              {{record.stock}} 件
            </div>
          </template>
          <template slot="realStock" slot-scope="text, record">
            <div style="color:red" v-if="record.realStock <= 10">
              {{record.realStock == null? 0 : record.realStock}} 件
            </div>
            <div style="color:green" v-else>
              {{record.realStock}} 件
            </div>
          </template>

          <template slot="specsImg" slot-scope="text, record">
              <img style="width:'50px'; height: 50px ;"
                @click="showImg(record.specsImg)"
               :src="record.specsImg " />
          </template>

          <span slot="action" slot-scope="text, re">
            <a @click="changeStock(re)">补充库存</a>
            <a-divider type="vertical" />
            <a @click="updateShopSpecs(re)">编辑</a>
            <a-divider type="vertical" />
            <a @click="deleteShopSpecs(re)">删除</a>
            <a-divider type="vertical" />
          </span>
       </a-table>
       <img-show-modal ref="imgShowModal" />
       <add-shop-specs-form ref="addShopSpecsForm" @ok="addShopSpecsFormOk" />
       <change-stock-modal ref="changeStockModal" @ok="changeStockModalOk" />
       <update-shop-specs-modal ref="udpateShopSpecsForm" @ok="changeStockModalOk" />
    </a-modal>
</template>

<script>

  import { getAction, postAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'
  import ImgShowModal from '@/components/common/ImgShowModal'
  import AddShopSpecsForm from './AddShopSpecsForm.vue'
  import ChangeStockModal from './ChangeStockModal.vue'
  import UpdateShopSpecsModal from './UpdateShopSpecsModal.vue'

  // 商品规格 modal
  export default {
    name: 'ShopSpecsModal',
    components: {
      ImgShowModal,
      AddShopSpecsForm,
      ChangeStockModal,
      UpdateShopSpecsModal
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        title:'商品规格详情',
        titleShop:'',
        width:1000,
        confirmLoading:false,
        loading:false,
        visible: false,
        dataSource:[],
        columns: [
          {
            title:'排序',
            align:"center",
            dataIndex: 'sort',
            ellipsis: true,
            width:80,
          },
          {
            title:'规格名称(中文)',
            align:"center",
            dataIndex: 'specsNameZh',
            ellipsis: true,
            width:150,
          },
          {
            title:'规格图',
            align:"center",
            dataIndex: 'specsImg',
            scopedSlots: { customRender: 'specsImg' },
            ellipsis: true,
            width:150,
          },{
            title:'商品现价（元）',
            align:"center",
            dataIndex: 'realMoney',
            scopedSlots: { customRender: 'realMoney' },
            ellipsis: true,
            width:150,
          },{
            title:'初始库存',
            align:"center",
            dataIndex: 'stock',
            scopedSlots: { customRender: 'stock' },
            ellipsis: true,
            width:150,
          },{
            title:'实际剩余库存',
            align:"center",
            dataIndex: 'realStock',
            scopedSlots: { customRender: 'realStock' },
            ellipsis: true,
            width:150,
          },{
            title:'商品成本（元）',
            align:"center",
            dataIndex: 'profit',
            ellipsis: true,
            width:150,
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            width:160,
            scopedSlots: { customRender: 'action' }
          },
        ],
        record:{},
        accessToken:'',
        readPath:'',
        openLanguage:false,
        url: {
          add: "",
          edit: "",
          getShopSpecsListByGoodsId:'/shop/web/shop/getShopSpecsListByGoodsId',
          deleteShopSpecs:'/shop/web/shop/deleteGoodsSpecs',
        }
      }
    },
    computed: {
      
    },
    created () {
    
    },
    methods: {
      show (record) {
        this.record = record;
        this.accessToken = record.accessToken
        this.readPath = record.readPath
        this.dataSource = record.shopGoodsSpecsList
        this.titleShop = record.goodsNameZh
        this.openLanguage = record.openLanguage
        this.visible = true;
      },
      addShopSpecs () {
        this.$refs.addShopSpecsForm.add(this.record);
      },
      addShopSpecsFormOk(data){
        this.dataSource.push(data)
      },
      updateShopSpecs(re) {
        re.openLanguage = this.openLanguage
        re.readPath = this.readPath
        re.status = this.record.status
        this.$refs.udpateShopSpecsForm.show(re);
      },
      getShopSpecsListByGoodsId(){
        let that = this
        let param = {
           id:that.record.id
        }
        this.loading = true;
        getAction(this.url.getShopSpecsListByGoodsId,param).then((res)=>{
          if(res.code == 200){
            that.dataSource = res.data
            that.$emit('refeshData',res.data);
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.loading = false;
        })
      },
      deleteShopSpecs(re){
        let that = this
        let param = {
           goodsId:that.record.id,
           specsId:re.id
        }
        this.$confirm({
          title: "确认删除规格",
          content: "您确定要删除规格吗?",
          onOk: function () {
              that.loading = true;
              postAction(that.url.deleteShopSpecs,param).then((res)=>{
                if(res.code == 200){
                  that.getShopSpecsListByGoodsId()
                  that.$message.success("删除规格成功");
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                that.loading = false;
              })
           }
        });
      },
      changeStock(record){
         this.$refs.changeStockModal.add(record);
      },
      changeStockModalOk(){
         this.getShopSpecsListByGoodsId()
      },
      showImg(url){
        url = url.split(",");
        let param = {
          url:url,
        }
        this.$refs.imgShowModal.show(param);
      },
      resetData(){
        this.getShopSpecsListByGoodsId()
      },
      close () {
        this.visible = false;
      },
      submitForm(){

      },
      handleCancel () {
        this.close()
      }
    }
  }
</script>