<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="分组名称">
              <!--<a-input placeholder="请输入账号查询" v-model="queryParam.username"></a-input>-->
              <a-input
                placeholder="输入分组名称查询"
                v-model="queryParam.groupNameZh"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
              <a-button
                type="primary"
                @click="searchReset"
                icon="reload"
                style="margin-left: 8px"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>删除</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 批量操作 <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>

          <a-divider type="vertical" />
          <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </span>

      </a-table>
    </div>

    <shop-goods-group-form ref="realForm" @ok="submitCallback" ></shop-goods-group-form>
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import ShopGoodsGroupForm from './modules/ShopGoodsGroupForm'
  import { USER_INFO } from '@/store/mutation-types'
  import Vue from 'vue'

  export default {
    name: 'ShopGoodsGroupList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
      ShopGoodsGroupForm
    },
    data () {
      return {
        description: '商品分组管理页面',
        openLanguage:false,
        // 表头
        columns: [
          {
            title:'排序',
            align:"center",
            dataIndex: 'sort',
          },
          {
            title:'分组名称(中文)',
            align:"center",
            dataIndex: 'groupNameZh',
            ellipsis: true,
            width:350,
          },
          {
            title:'分组名称(英文)',
            align:"center",
            dataIndex: 'groupNameEn',
            ellipsis: true,
            width:350,
          },
          {
            title:'分组id',
            align:"center",
            dataIndex: 'id',
            ellipsis: true,
            width:350,
          },
          {
            title:'创建人时间',
            align:"center",
            dataIndex: 'createTime',
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            scopedSlots: { customRender: 'action' },
          }
        ],
        url: {
          list: "/shop/web/shopGroup/getShopGroup",
          delete: "/shop/web/shopGroup/deleteShopGroup",
          deleteBatch: "",
        },
      }
    },
    created() {
      const userinfo = Vue.ls.get(USER_INFO)
      this.openLanguage = userinfo.openLanguage
      if(!userinfo.openLanguage){
        this.columns.splice(2, 1);
      }
    },
    computed: {
    
    },
    methods: {
      initDictConfig(){
      },
      handleAdd(){
        this.$refs.realForm.add(this.openLanguage);
      },
      handleEdit(record){
        record._openLanguage = this.openLanguage
        this.$refs.realForm.edit(record);
      },
      submitCallback(){
        this.loadData();
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>