<template>
  
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
          <a-form-model ref="form" :model="model" :rules="validatorRules" >
            <a-row>
              <a-col :span="24" v-if="openLanguage">
                <a-form-model-item label="分组名称(英文)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="groupNameEn">
                  <a-input v-model="model.groupNameEn" placeholder="请输入分组名称(英文)"  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item label="分组名称(中文)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="groupNameZh">
                  <a-input v-model="model.groupNameZh" placeholder="请输入分组名称(中文)"  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="sort">
                  <a-input-number :max="99999" :min="-99999" :precision="0" v-model="model.sort" placeholder="请输入排序" style="width: 100%" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
    </a-modal>  

</template>

<script>

  import { postAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/commonUtil'
  export default {
    name: 'ShopGoodsGroupForm',
    components: {
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        title:'',
        width:800,
        visible: false,
        openLanguage:false,
        model:{
           sort:1
        },
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
          groupNameZh: [
              { required: true, message: '请输入分组名称(中文)'},
          ],
          groupNameEn: [
              { required: true, message: '请输入分组名称(英文)'},
          ],
          sort: [
              { required: true, message: '请输入排序'},
          ],
            
        },
        url: {
          add: "/shop/web/shopGroup/createShopGroup",
          edit: "/shop/web/shopGroup/updateShopGroup",
          queryById: "/com.wx/shopGoodsGroup/queryById"
        }
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add (openLanguage) {
        this.visible = true;
        this.openLanguage = openLanguage;
        this.title = "新增分组"
        this.model = Object.assign({}, this.modelDefault);
      },
      edit (record) {
        console.log(record)
        this.title = "修改分组"
        this.model = Object.assign({}, record);
        this.visible = true;
        this.openLanguage = record._openLanguage;
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            if(!this.model.id){
              httpurl =this.url.add;
            }else{
              httpurl =this.url.edit;
            }
            postAction(httpurl,this.model).then((res)=>{
              if(res.code == 200){
                that.$message.success("操作成功");
                that.close()
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },
      close () {
        this.visible = false;
      },
      handleCancel () {
        this.close()
      }
    }
  }
</script>