<template>
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="手机号">
                  <a-input :maxLength="30"  v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号!' }] }]"  style="width:100%"
                      placeholder="请输入手机号"  ></a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="会员金额(元)">
                  <a-input-number :max="1000000" :min="0" v-decorator="['money', { rules: [{ required: true, message: '请输入会员金额(元)!' }],  }]"  style="width:100%"
                      placeholder="请输入会员金额(元)"  ></a-input-number>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="姓名">
                  <div class="speech-layout">
                      <a-input :maxLength="100" v-decorator="['memberName', { rules: [{ required: false, message: '!' }] }]"  style="width:100%"
                         placeholder="请输入姓名"  ></a-input>
                      <a-icon @click="speechRecognitionName" type="audio" style="color:#1890FF;padding-left: 20px;" />
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="备注">
                  <div class="speech-layout">
                  <a-auto-complete
                        :maxLength="200"
                        v-decorator="['remark', { rules: [{ required: false, message: '!' }] }]"
                        :data-source="remarkData"
                        style="width: 100%"
                        placeholder="请输入备注"
                      />
                  </div>  
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
               
            </a-row>
          </a-form>
    </a-modal>  

</template>

<script>

  import { postAction, getAction } from '@/api/manage'
  import { speakCharacter,speechRecogn } from '@/utils/util'
  export default {
    name: 'MemberModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:600,
        visible: false,
        openLanguage:false,
        confirmLoading: false,
        remarkData:["会员卡消费打n折","会员充值满n元送x元"],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          createMember: "/khcrm/web/haircut/createMember",
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      add () {
        this.form.resetFields();
        this.visible = true;
        this.title = "新增会员信息"
      },
      edit (record) {
        this.visible = true;
        this.title = "新增分组"
      },
      submitForm (e) {
        const that = this;
        that.form.validateFields((err, values) => {
          if (!err) {
            that.createMember(values);
          }
         })
      },
      createMember(formData){
        let that = this
        this.confirmLoading = true
        let param = {
          "phone" : formData.phone,
          "money" : formData.money,
          "memberName" : formData.memberName,
          "remark" : formData.remark,
        }
        postAction(this.url.createMember,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("创建会员成功");
            that.handleCancel()
            that.$emit('ok');
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
           that.confirmLoading = false;
        })
      },
      speechRecognitionName(){
        this.$message.warn("请讲话！！！（请注意目前仅支持Microsoft Edge浏览器）  ");
        let recognition = speechRecogn();
        let that = this;
        recognition.onspeechend = (e)=>{
            recognition.stop();
        }
        recognition.onresult = function(event) {
            const result = event.results[event.results.length - 1]
            let info = result[0].transcript;
            if(info != ''){
              that.setName(info.replace('。',''))
            }
        }
      },
      handleCancel () {
        this.visible = false;
      },
      setName(name){
        this.$nextTick(() => {
          setTimeout(() => {
            this.form.setFieldsValue({
              memberName: name,
            })
          })
        });
      }
    }
  }
</script>
<style scoped>
.speech-layout{
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>