<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="合同名称" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name">
              <a-input v-model="model.name" placeholder="请输入合同名称"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="合同编号" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="num">
              <a-input v-model="model.num" placeholder="请输入合同编号"  ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>  
          <a-col :span="12">
            <a-form-model-item label="客户" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="customerId" >
              <div @click="selectCustomer">
              <a-select style="width: 100%" v-model="model.customerId" placeholder="请选择客户" :open="false"  >
                  <template v-for="(item,i) in cusrecord" >
                        <a-select-option :key="i" :value="item.id">{{item.name}}</a-select-option>
                  </template>
                </a-select>
              </div>  
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="商机" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="businessId" >
              <div @click="selectBusiness">
                <a-select style="width: 100%" v-model="model.businessId" placeholder="请选择商机" :open="false">
                  <template v-for="(item,i) in businessrecord" >
                        <a-select-option :key="i" :value="item.id">{{item.name}}</a-select-option>
                  </template>
                </a-select>
              </div>  
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="客户联系人" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="personId">
              <div @click="selectPerson">
                <a-select style="width: 100%" v-model="model.personId" placeholder="请选择客户联系人" :open="false">
                  <template v-for="(item,i) in personrecord" >
                        <a-select-option :key="i" :value="item.id">{{item.name}}</a-select-option>
                  </template>
                </a-select>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="公司签约人" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="companyUserId">
              <a-select v-model="model.companyUserId" style="width: 100%"  placeholder="请选择公司签约人">
                <template v-for="(item,i) in userOwnerList" >
                   <a-select-option :key="i" :value="item.userId">{{item.userName}}</a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row> 
        <a-row>  
          <a-col :span="12">
            <a-form-model-item label="合同金额(元)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="money">
              <a-input-number v-model="model.money" placeholder="请输入合同金额" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="下单时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="orderDate">
              <j-date placeholder="请输入下单时间" v-model="model.orderDate" :dateFormat="dateFormat"  style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
         

        <a-row>  
          <a-col :span="12">
            <a-form-model-item label="开始时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="startTime">
              <j-date placeholder="请选择开始时间" v-model="model.startTime" :dateFormat="dateFormat"  style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="结束时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="endTime">
              <j-date placeholder="请选择结束时间" v-model="model.endTime" :dateFormat="dateFormat"  style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>  
          <a-col :span="12">
            <a-form-model-item label="合同类型" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="type">
              <a-input v-model="model.type" placeholder="请输入合同类型"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="上传附件" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="file">
              <a-upload :file-list="fileList" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload">
                <a-button> <a-icon type="upload" />点击上传</a-button>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>    
          <a-col :span="24">
            <a-form-model-item label="备注" prop="remark">
              <a-textarea v-model="model.remark" rows="3" placeholder="请输入备注" />
            </a-form-model-item>
          </a-col>
           
        </a-row>
      </a-form-model>
    </j-form-container>
    <select-persono-modal ref="personForm" @ok="personFormOk" />
    <select-business-modal ref="businessForm" @ok="businessFormOk" />
    <select-customer-modal ref="modalForm" @ok="modalFormOk" />
  </a-spin>
</template>

<script>

  import { httpAction, getAction,uploadAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'
  import JFormContainer from '@/components/jeecg/JFormContainer'
  import JDate from '@/components/jeecg/JDate.vue'
  import SelectCustomerModal from '../../customer/modules/SelectCustomerModal.vue'
  import SelectBusinessModal from '../../business/modules/SelectBusinessModal.vue'
  import SelectPersonoModal from '../../person/modules/SelectPersonoModal.vue'
  import { Leads } from "@/apis";


  export default {
    name: 'KhcrmContractForm',
    components: {
      JFormContainer,
      JDate,
      SelectCustomerModal,
      SelectBusinessModal,
      SelectPersonoModal
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        model:{
         },
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
        cusrecord:[],
        confirmLoading: false,
        dateFormat:"YYYY-MM-DD HH:mm",
        validatorRules: {
           name: [
              { required: true, message: '请输入合同名称!'},
           ],
           num: [
              { required: false, message: '请输入合同编号!'},
           ],
           customerId: [
              { required: false, message: '请选择客户!'},
           ],
           businessId: [
              { required: false, message: '请选择商机!'},
           ],
           companyUserId: [
              { required: true, message: '请选择公司签约人!'},
           ],
           money: [
              { required: false, message: '请输入合同金额!'},
           ],
           orderDate: [
              { required: false, message: '请选择下单时间!'},
           ],
           personId: [
              { required: false, message: '请选择客户联系人!'},
           ],
           startTime: [
              { required: true, message: '请选择开始时间!'},
           ],
        },
        url: {
          add: "/khcrm/web/khcrmContract/add",
          edit: "/khcrm/web/khcrmContract/edit",
          queryById: "/khcrm/web/khcrmContract/queryById"
        },
        businessrecord:[],
        personrecord:[],
        userOwnerList:[],
        fileList:[]
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
      this.getOwnerList();
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        if(record.fileUrl){
          this.fileList = [
            {
                uid: 'czawe12',
                name: '附件',
                status: 'done',
                url: '',
              },
          ]
        }else{
          this.fileList = []
        }
        // 回填客户
        let cus = {
          id:record.customerId,
          name:record.customerName
        }
        this.cusrecord = [];
        this.cusrecord.push(cus)
        this.model.customerId = cus.id
        // 回填联系人
        let p = {
          id:record.personId,
          name:record.personName
        }
        this.personrecord = [];
        this.personrecord.push(p)
        this.model.personId = p.id

        // 商机
        let b = {
          id:record.businessId,
          name:record.businessName
        }
        this.businessrecord = [];
        this.businessrecord.push(b)
        this.model.businessId = b.id


        this.model = Object.assign({}, record);
        this.visible = true;
      },
      selectBusiness(){
          let curid = this.model.customerId;
          if(curid){
              this.$refs.businessForm.show(curid);
          }else{
             this.$message.warning("请先选择客户！");
          }
      },
      selectPerson(){
          let curid = this.model.customerId;
          if(curid){
              this.$refs.personForm.show(curid);
          }else{
             this.$message.warning("请先选择客户！");
          }
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      beforeUpload(file) {
        this.fileList = []
        this.fileList = [...this.fileList, file];
        return false;
      },
      personFormOk(e){
          this.personrecord = [];
          this.personrecord.push(e)
          this.model.personId = e.id
      },
      businessFormOk(e){
          this.businessrecord = [];
          this.businessrecord.push(e)
          this.model.businessId = e.id
      },
      selectCustomer(){
          this.$refs.modalForm.show();
      },
      modalFormOk(e){
          this.cusrecord = [];
          this.cusrecord.push(e)
          this.model.customerId = e.id
          delete this.model['businessId']
          delete this.model['personId']
      },
      getOwnerList(){
        if(this.userOwnerList == null || this.userOwnerList.length == 0){
            Leads.queryUsCompanyUser(null).then((res) => {
              if (res.code ==200) {
                console.log(res.data)
                  this.userOwnerList = res.data
              } else {
                that.$message.warning(res.message);
              }
            })
        }
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            if(!this.model.id){
              httpurl+=this.url.add;
            }else{
              httpurl+=this.url.edit;
            }
            const fileList = this.fileList;
            const formData = new FormData();
            if(fileList.length >0){
              if(fileList[0].uid != 'czawe12'){
                  fileList.forEach(file => {
                    formData.append('file', file);
                  });
              }
            }
            for(let i in this.model){
              if(this.model[i]){
                  formData.append(i, this.model[i]); 
              }
            }
            uploadAction(httpurl,formData).then((res)=>{
              if(res.code == 200){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },
    }
  }
</script>