import { getAction, postAction, formAction, uploadAction } from '@/api/manage'
const url = {
    // 锁定解锁
    changeToCustomer: '/khcrm/web/khcrmLeads/changeToCustomer',
    // 查询本公司下的所有用户
    queryUsCompanyUser :  '/khcrm/web/khcrmLeads/queryUsCompanyUser',
    // 修改负责人
    changeOwner: '/khcrm/web/khcrmLeads/changeOwner',
    // 跟进
    followUp:'/khcrm/web/khcrmLeads/followUp',

    // 查询业务日志
    findFollowUp:'/khcrm/web/khcrmLeads/findFollowUp',

    importLeaderExcel:'/khcrm/web/khcrmLeads/importLeaderExcel',
 
}
export default {
    changeToCustomer(params) {
        return postAction(url.changeToCustomer, params);
    },
    queryUsCompanyUser(params) {
        return getAction(url.queryUsCompanyUser, params);
    },
    changeOwner(params) {
        return postAction(url.changeOwner, params);
    },
    followUp(params) {
        return uploadAction(url.followUp, params);
    },
    findFollowUp(params) {
        return postAction(url.findFollowUp, params);
    },
    importLeaderExcel(params) {
        return uploadAction(url.importLeaderExcel, params);
    },
    
}
