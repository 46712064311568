<template>
   <div>
        <a-drawer
          title="流程详情"
          placement="right"
          :closable="true"
          :visible="visible"
          :width="500"
          @close="onClose"
        >
           <div>

              <a-timeline>
                  <a-timeline-item v-for="(item,index) in processLogList" :key="item.id">
                    <div style="">
                       <span style="font-size:14px" v-if="item.approvalStatus == 2 || item.approvalStatus == 3"> 审批人: {{item.createName}} </span>   
                       <span style="font-size:14px" v-else> 申请人: {{item.createName}} </span>   
                       <span style="margin-left:40px">{{item.createTime}}</span>
                     </div>
                     <div style="font-size:16px;margin-top:10px">
                       <span>{{item.msg}} </span>
                       <a-tag v-if="item.approvalStatus == 0" color="green" style="margin-left:40px">无需审批直接通过</a-tag> 
                       <!-- <a-tag v-else-if="item.approvalStatus == 1" color="#108ee9" style="margin-left:40px">审批中</a-tag>  -->
                       <a-tag v-else-if="item.approvalStatus == 2" color="#87d068" style="margin-left:40px">审批通过</a-tag> 
                       <a-tag v-else-if="item.approvalStatus == 3" color="#f50" style="margin-left:40px">审批拒绝</a-tag> 
                     </div>
                     
                  </a-timeline-item>
              
                </a-timeline>

           </div>
        </a-drawer>
  </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { MakeLink } from "@/apis";

  export default {
    // 流程详情
    name: 'ProcessDetail',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate,
        
    },
    data () {
      return {
        visible: false,
        record:{},
        processLogList:[]
      }
    },
    computed: {
     
    },
    created() {
       
    },
    methods: {
      show(record){
        this.record = record
        this.processLogList = []
        this.findProcessLogByDataId()
        this.visible = true
      },
      loadData(arg) {
        
      },
      onClose() {
        this.visible = false;
      },
      findProcessLogByDataId() {
        let params = {
           dataId : this.record.id
        }
        MakeLink.findProcessLogByDataId(params).then((res)=>{
            if(res.code == 200){
               this.processLogList = res.data
            }else{
              this.$message.warning(res.message);
            }
             this.loading = false;
             this.loadflag = true
        })
      },
    }
  }
</script>