<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24" class="searchclass" >
          <a-col :md="8" :sm="10">
            <a-form-item label="">
              <a-input-search v-model="queryParam.processName" :maxLength="200" placeholder="请输入流程名称" enter-button="搜索" size="default"
                  @search="searchQuery" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="makeLink" type="primary" icon="plus">新增流程</a-button>
    </div>
    <div>
    <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap">



        <template slot="status" slot-scope="text">
            <a-tag v-if="text==0" color="red">未开始</a-tag>
            <a-tag v-else-if="text==1"  color="#FF00FF">制作中</a-tag>
            <a-tag v-else-if="text==2"  color="#52c41a">已完成</a-tag>
        </template>


        <template slot="isUse" slot-scope="text">
            <a-tag v-if="text==0" color="#FF0000">未发布</a-tag>
            <a-tag v-if="text==1"  color="#87d068">已发布</a-tag>
        </template>


        <span slot="action" slot-scope="text, record">
          <a @click="preShow(record)">预览</a>
          <a-divider type="vertical" />
           <a-popconfirm v-if="record.isUse == 0"  title="发布后无法修改,你确定要发布流程?" @confirm="() => sendProcess(record.id,1)">
             <a>发布</a>
          </a-popconfirm>
          <a-popconfirm v-else-if="record.isUse == 1"  title="你确定要取消发布流程?" @confirm="() => sendProcess(record.id,0)">
             <a>取消发布</a>
          </a-popconfirm>
          
          <template v-if="record.isUse == 0">
              <a-divider type="vertical" />
              <a @click="makeProcessLink(record)">制作环节</a>
              <a-divider type="vertical" />
              <a-dropdown>
                <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a @click="updateLink(record)">编辑</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                      <a>删除</a>
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
          </template>
          
        </span>

      </a-table>
    </div> 
    <process-template ref="processTemplateForm" @ok="processTemplateFormOK"  />
    <control-template  ref="controlForm" />
    <a-modal
      title="新增流程"
      :visible="modalvisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="流程名称">
          <a-input
            v-decorator="['processName', { rules: [{ required: true, message: '请输入流程名称!' }] }]"
          />
      </a-form-item>
  </a-form>
    </a-modal>
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import ProcessTemplate from '../common/ProcessTemplate.vue'
  import { MakeLink } from "@/apis";
  import ControlTemplate from '../common/ControlTemplate.vue'
  import pick from "lodash.pick";
  export default {
    name: 'KhcrmBusinessList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
       ProcessTemplate,
       ControlTemplate
    },
    data () {
      return {
        description: '客户自定义审批、工作流程',
        // 表头
        columns: [
          {
            title:'流程名称',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'name'
          },
          {
            title:'流程状态',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' }
          },
          {
            title:'是否发布',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'isUse',
            scopedSlots: { customRender: 'isUse' }
          },{
            title:'制作当前的环节',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'curStep'
          },
          {
            title:'创建时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'createTime'
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        dataSource:[],
        modalvisible:false,
        loading:false,
        confirmLoading:false,
        processId:'',
        form:this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          list: "/khcrm/web/process/getTableList",
          delete:'/khcrm/web/process/deleteMainProcess'
        },
      }
    },
    created() {
      
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
       makeLink(){
         this.processId = '';
         this.form.resetFields();
         this.modalvisible = true;
       },
       updateLink(record){
         console.log(record)
          record.processName = record.name
          this.processId = record.id
          let data = Object.assign({}, record)
          this.$nextTick(() => {
            setTimeout(() => {
                this.form.setFieldsValue(pick(data, 'processName'))
            })
          });
          this.modalvisible = true;
       },
       makeProcessLink(record){
          this.$refs.processTemplateForm.show(record);
       },
       sendProcess(id,isUse){
           let param = {
              processId : id,
              isUse:isUse
           }
           this.loading = true
           MakeLink.changeProcessIsUse(param).then((res) => {
              if (res.code == 200) {
                this.loadData(1)
              } else {
                this.$message.warning(res.message);
              }
               this.loading  = false;
           }) 
       },
       handleOk(){
         this.form.validateFields((err, values) => {
           if (!err) {
              let param = {
                id : this.processId,
                processName : values.processName
              }
              this.confirmLoading = true;
              var that = this
              MakeLink.addMainProcess(param).then((res) => {
                  if (res.code ==200) {
                     this.loadData(1)
                     this.handleCancel();
                  } else {
                    this.$message.warning(res.message);
                  }
                  that.confirmLoading = false;
              }) 
            }
         });
          
       },
       preShow(record){
          this.$refs.controlForm.show(record);
       },
       handleCancel(){
         this.modalvisible = false;
       },
       processTemplateFormOK(){
         this.loadData(1)
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>