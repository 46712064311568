<template>
  <a-modal
    :title="title"
    :width="800"
    :ok="false"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :okButtonProps="{ props: { disabled: disableSubmit } }"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="disname + '名称'"
          :hidden="false"
          hasFeedback
        >
          <a-input
            id="departName"
            :placeholder="'请输入'+disname+'名称'"
            v-decorator="['departName', validatorRules.departName]"
          />
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="联系人电话">
          <a-input
            placeholder="请输入联系人电话"
            v-decorator="['mobile', validatorRules.mobile]"
          />
        </a-form-item>
        <a-form-item v-if="!mainId && parentId == 0" :labelCol="labelCol" :wrapperCol="wrapperCol" label="允许添加最大人数">
          <a-input-number
            placeholder="请输入允许添加最大人数"
            :min="1" :max="999999999"
            style="width:100%"
            v-decorator="['allowNum', validatorRules.allowNum]"
          />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="disname + '简称'"
          :hidden="false"
          hasFeedback
        >
          <a-input
            :placeholder="'请输入'+disname+'简称'"
            v-decorator="['departNameAbbr', validatorRules.departNameAbbr]"
          />
        </a-form-item>
         <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="disname + '英文名称'"
          :hidden="false"
          hasFeedback
        >
          <a-input
            :placeholder="'请输入'+disname+'英文名称'"
            v-decorator="['departNameEn', validatorRules.departNameEn]"
          />
        </a-form-item>
        
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="编码"
          :hidden="false"
          hasFeedback
        >
          <a-input
            placeholder="请输入编码"
            v-decorator="['orgCode', validatorRules.orgCode]"
          />
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="传真">
          <a-input placeholder="请输入传真" v-decorator="['fax', {}]" />
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="详细地址">
          <a-input placeholder="请输入详细地址" v-decorator="['address', validatorRules.address]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { httpAction } from "@/api/manage";
import pick from "lodash.pick";
import ATextarea from "ant-design-vue/es/input/TextArea";
export default {
  name: "SysDepartModal",
  components: { ATextarea },
  data() {
    return {
      departTree: [],
      orgTypeData: [],
      phoneWarning: "",
      parentId:0,
      departName: "",
      title: "操作",
      visible: false,
      condition: true,
      disableSubmit: false,
      model: {},
      menuhidden: false,
      menuusing: true,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },

      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        departName: {
          rules: [{ required: true, message: "请输入公司/部门名称!" }],
        },
        allowNum: {
          rules: [{ required: true, message: "请输入允许添加最大人数!" }],
        },
        departNameAbbr: {
          rules: [{ required: false, message: "请输入公司/部门简称!" }],
        },
        departNameEn: {
          rules: [{ required: false, message: "请输入公司/部门英文名称!" }],
        },
        orgCode: { rules: [{ required: false, message: "请输入编码!" }] },
        address: { rules: [{ required: false, message: "请输入公司详细地址!" }] },
        mobile: { rules: [{ required: true, message: "请输入联系人电话!" }] },
      },
      url: {
        add: "/khcrm/web/sysuser/creatDepart",
      },
      disname:'公司',
      mainId:''
    };
  },
  created() {},
  methods: {
    add(parentId) {
      this.edit({ parentId:parentId });
    },
    edit(record) {
      let parentId = record.parentId
      if (parentId && parentId != '0') {
        this.disname = '部门'
      } else {
        this.disname = '公司'
      }
      this.parentId = parentId
      this.mainId = record.id
      this.form.resetFields();
      this.model = Object.assign({}, record);
      this.visible = true;
      this.$nextTick(() => {
        this.form.setFieldsValue(
          pick(
            this.model,
            "departName",
            "departNameAbbr",
            "departNameEn",
            "mobile",
            "fax",
            "address",
            "orgCode"
          )
        );
      });
    },
    close() {
      this.$emit("close");
      this.disableSubmit = false;
      this.visible = false;
    },
    handleOk() {
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          values.parentId = this.parentId
          values.id = this.mainId
          let formData = Object.assign(this.model, values);
          //时间格式化
          httpAction(this.url.add, formData, "post")
            .then((res) => {
              const { code, message } = res;
              if (code == 200) {
                that.$message.success(message);
                that.$emit("ok");
                that.close();
              } else {
                that.$message.warning(message);
              }
              that.confirmLoading = false;
            })
        }
      });
    },
    handleCancel() {
      this.close();
    },
    validateMobile(rule, value, callback) {
      if (
        !value ||
        new RegExp(
          /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
        ).test(value)
      ) {
        callback();
      } else {
        callback("您的手机号码格式不正确!");
      }
    },
  },
};
</script>

<style scoped>
</style>