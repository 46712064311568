import { getAction, postAction, formAction, formAction2 } from '@/api/manage'
const url = {
    // 根据客户查询旗下合同
    findContractByCustomerId: '/khcrm/web/khcrmContract/findContractByCustomerId',
    // 修改合同负责人
    changeContractOwner: '/khcrm/web/khcrmContract/changeContractOwner',

    getReturnMoneyByContract:'/khcrm/web/khcrmContract/getReturnMoneyByContract',
}
export default {
    findContractByCustomerId(params) {
        return postAction(url.findContractByCustomerId, params);
    } ,
    changeContractOwner(params) {
        return postAction(url.changeContractOwner, params);
    } ,
    getReturnMoneyByContract(params) {
        return getAction(url.getReturnMoneyByContract, params);
    } ,
}
