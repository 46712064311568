import { getAction, postAction, formAction, formAction2 } from '@/api/manage'
const url = {
    // 修改负责人
    changeReturnMoneyOwner: '/khcrm/web/khcrmReturnMoney/changeReturnMoneyOwner',
    
 
}
export default {
    changeReturnMoneyOwner(params) {
        return postAction(url.changeReturnMoneyOwner, params);
    } 
}
