var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{staticStyle:{"overflow":"auto","padding-bottom":"53px"},attrs:{"title":_vm.title,"maskClosable":true,"width":"650","placement":"right","closable":true,"visible":_vm.visible},on:{"close":_vm.close}},[_c('a-form',[_c('a-form-item',{attrs:{"label":"所拥有的权限"}},[_c('a-tree',{attrs:{"checkable":"","checkedKeys":_vm.checkedKeys,"treeData":_vm.treeData,"selectedKeys":_vm.selectedKeys,"expandedKeys":_vm.expandedKeysss,"checkStrictly":_vm.checkStrictly,"replaceFields":{
          title: 'name',
          key: 'id',
          value: 'id',
        }},on:{"check":_vm.onCheck,"expand":_vm.onExpand,"select":_vm.onTreeNodeSelect},scopedSlots:_vm._u([{key:"hasDatarule",fn:function(ref){
        var slotTitle = ref.slotTitle;
        var ruleFlag = ref.ruleFlag;
return _c('span',{},[_vm._v(" "+_vm._s(slotTitle)),(ruleFlag)?_c('a-icon',{staticStyle:{"margin-left":"5px","color":"red"},attrs:{"type":"align-left"}}):_vm._e()],1)}}])})],1)],1),_c('div',{staticClass:"drawer-bootom-button"},[_c('a-dropdown',{staticStyle:{"float":"left"},attrs:{"trigger":['click'],"placement":"topCenter"}},[_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:"1",on:{"click":function($event){return _vm.switchCheckStrictly(1)}}},[_vm._v("父子关联")]),_c('a-menu-item',{key:"2",on:{"click":function($event){return _vm.switchCheckStrictly(2)}}},[_vm._v("取消关联")]),_c('a-menu-item',{key:"3",on:{"click":_vm.checkALL}},[_vm._v("全部勾选")]),_c('a-menu-item',{key:"4",on:{"click":_vm.cancelCheckALL}},[_vm._v("取消全选")]),_c('a-menu-item',{key:"5",on:{"click":_vm.expandAll}},[_vm._v("展开所有")]),_c('a-menu-item',{key:"6",on:{"click":_vm.closeAll}},[_vm._v("合并所有")])],1),_c('a-button',[_vm._v(" 树操作 "),_c('a-icon',{attrs:{"type":"up"}})],1)],1),_c('a-popconfirm',{attrs:{"title":"确定放弃编辑？","okText":"确定","cancelText":"取消"},on:{"confirm":_vm.close}},[_c('a-button',{staticStyle:{"margin-right":"0.8rem"}},[_vm._v("取消")])],1),_c('a-button',{staticStyle:{"margin-right":"0.8rem"},attrs:{"type":"primary","loading":_vm.loading,"ghost":""},on:{"click":function($event){return _vm.handleSubmit(false)}}},[_vm._v("仅保存")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.handleSubmit(true)}}},[_vm._v("保存并关闭")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }