<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭">
    
    <a-spin :spinning="confirmLoading">
      
      <a-form-model ref="form" :model="model" :rules="validatorRules">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="客户" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="customerId" >
              <div @click="selectCustomer">
              <a-select style="width: 100%" v-model="model.customerId" placeholder="请选择客户" :open="false"  >
                  <template v-for="(item,i) in cusrecord" >
                        <a-select-option :key="i" :value="item.id">{{item.name}}</a-select-option>
                  </template>
                </a-select>
              </div>  
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="编码" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="code">
              <a-input v-model="model.code" placeholder="请输入编码"  ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="每月收费金额(元)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="money">
              <a-input-number v-model="model.money" :min="0" :max="99999999999"  style="width: 100%" placeholder="请输入每月收费金额"  />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="付款方式" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="payMethod">
              <a-select v-model="model.payMethod" placeholder="请选择付款方式">
                <a-select-option value="月付">月付</a-select-option>
                <a-select-option value="季度付">季度付</a-select-option>
                <a-select-option value="半年付">半年付</a-select-option>
                <a-select-option value="年付">年付</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="最后收费账期" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="lastDate">
                <a-month-picker :valueFormat="monthFormat" placeholder="请选择最后收费账期" v-model="model.lastDate" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="优惠金额(元)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="discount">
               <a-input-number v-model="model.discount" :min="-99999999999" :max="99999999999"  style="width: 100%" placeholder="请输入优惠金额" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="其他费用(元)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="otherMoney">
              <a-input-number v-model="model.otherMoney" :min="-99999999999" :max="99999999999"  style="width: 100%" placeholder="请输入其他费用" />
            </a-form-model-item>
          </a-col>
           <a-col :span="12">
            <a-form-model-item label="实收金额(元)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="realMoney">
               <a-input-number v-model="model.realMoney" :min="-99999999999" :max="99999999999"  style="width: 100%" placeholder="请输入实收金额" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="账户余额(元)" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="userBalance">
                <a-input-number v-model="model.userBalance" :min="-99999999999" :max="99999999999"  style="width: 100%" placeholder="请输入实收金额" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="收费日期" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="chargDate">
               <j-date placeholder="请选择收费日期" v-model="model.chargDate" :dateFormat="dateFormat"  style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="上传附件" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="file">
              <a-upload :file-list="fileList" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload">
                <a-button> <a-icon type="upload" />点击上传</a-button>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>    
          <a-col :span="24">
            <a-form-model-item label="备注" prop="remark">
              <a-textarea v-model="model.remark" rows="3" placeholder="请输入备注" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
    <select-customer-modal ref="modalForm" @ok="modalFormOk" />
  </a-modal>
</template>

<script>
  import { uploadAction } from '@/api/manage'
  import moment from "moment"
  import JDate from '@/components/jeecg/JDate.vue'
  import SelectCustomerModal from '../../customer/modules/SelectCustomerModal.vue'

  export default {
    name: "KhcrmChargModal",
    components: {
      JDate,
      SelectCustomerModal
    },
    data () {
      return {
        title:"操作",
        visible: false,
        dateFormat:"YYYY-MM-DD",
        monthFormat:'YYYY-MM',
        model: {},
        fileList:[],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 },
        },

        confirmLoading: false,
        validatorRules:{
            customerId:[{ required: true, message: '请选择客户!' }],
            money:[{ required: true, message: '请输入每月收费金额!' }],
            payMethod:[{ required: true, message: '请选择付款方式!' }],
            lastDate:[{ required: true, message: '请选择最后收费账期!' }],
        },
        url: {
          add: "/khcrm/web/khcrmCharg/addCharg",
          edit: "/khcrm/web/khcrmCharg/updateCharg",
        },
        cusrecord:[]
      }
    },
    created () {
    },
    methods: {
      add () {
        //初始化默认值
        this.edit({});
      },
      edit (record) {
        this.model = Object.assign({}, record);
        if(record.fileUrl){
          this.fileList = [
            {
                uid: 'czawe12',
                name: '附件',
                status: 'done',
                url: '',
              },
          ]
        }else{
          this.fileList = []
        }
        this.visible = true;
      },
      close () {
        this.$emit('close');
        this.visible = false;
        this.$refs.form.clearValidate();
      },
      handleOk () {
        const that = this;
        // 触发表单验证
         this.$refs.form.validate(valid => {
          if (valid) {
            let httpurl = '';
            if(!this.model.id){
              httpurl+=this.url.add;
            }else{
              httpurl+=this.url.edit;
            }
            that.confirmLoading = true;
            const fileList = this.fileList;
            const formData = new FormData();
            if(fileList.length >0){
              if(fileList[0].uid != 'czawe12'){
                  fileList.forEach(file => {
                    formData.append('file', file);
                  });
              }
            }
            for(let i in this.model){
              if(this.model[i]){
                  formData.append(i, this.model[i]); 
              }
            }
            uploadAction(httpurl,formData).then((res)=>{
              if(res.code == 200){
                that.$message.success(res.message);
                that.$emit('ok');
                this.visible = false
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          } 
        })
      },
      handleCancel () {
        this.close()
      },
      selectCustomer(){
          this.$refs.modalForm.show();
      },
      modalFormOk(e){
          this.cusrecord = [];
          this.cusrecord.push(e)
          this.model.customerId = e.id
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      beforeUpload(file) {
        this.fileList = []
        this.fileList = [...this.fileList, file];
        return false;
      },


    }
  }
</script>

<style lang="less" scoped>

</style>