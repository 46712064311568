<template>
  <a-card :bordered="false">

    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">

          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="付款方式">
              <a-select v-model="queryParam.payMethod" placeholder="请选择付款方式" allowClear>
                <a-select-option value="月付">月付</a-select-option>
                <a-select-option value="季度付">季度付</a-select-option>
                <a-select-option value="半年付">半年付</a-select-option>
                <a-select-option value="年付">年付</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.numStatus" placeholder="请选择状态" allowClear>
                <a-select-option value="1">预收</a-select-option>
                <a-select-option value="2">欠费</a-select-option>
                <a-select-option value="0">其他</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="账期">
              <a-month-picker :valueFormat="monthFormat" placeholder="请选择最后收费账期" v-model="queryParam.lastDate" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <a-tabs :default-active-key="0" @change="tabChange">
        <a-tab-pane :key="0" tab="使用中">
        </a-tab-pane>
        <a-tab-pane :key="1" tab="已停用" force-render>
        </a-tab-pane>
      </a-tabs>
      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :scroll="{x:800}"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap" >


        <template slot="moneyNum" slot-scope="text, record">
            <a-tag  size="small" v-if="record.numStatus == 2" color="#f50">欠费</a-tag>
            <a-tag size="small" v-if="record.numStatus == 1" color="#2db7f5">预收</a-tag>
            <span style="margin-left:10px">{{text}}</span>
        </template>

        <template slot="discount" slot-scope="text, record">
          <span v-if="text > 0 " style="color:red"> {{text}} </span>
          <span v-else-if="text < 0 " style="color:green"> {{text}} </span>
          <span v-else> {{text}} </span>
        </template>

         
        <template slot="status" slot-scope="text">
            <a-tag v-if="text == 0" color="red">未审核</a-tag>
            <a-tag v-else color="green">已审核</a-tag>
        </template>


        <template slot="file" slot-scope="text">
           <template v-if="text">
              <a @click="askOrDownFile(text)">点击查看/下载附件</a>
           </template>
        </template>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定停用吗?" v-if="record.deleteStatus == 0" @confirm="() => deleteRecord(record.id,1)">
            <a>停用</a>
          </a-popconfirm>
          <a-popconfirm title="确定启用吗?" v-else @confirm="() => deleteRecord(record.id,0)">
            <a>启用</a>
          </a-popconfirm>
        </span>

      </a-table>
    </div>
    <!-- table区域-end -->

    <!-- 表单区域 -->
    <khcrmCharg-modal ref="modalForm" @ok="modalFormOk"></khcrmCharg-modal>
  </a-card>
</template>

<script>
  import '@/assets/less/TableExpand.less'
  import KhcrmChargModal from './modules/KhcrmChargModal'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { postAction} from '@/api/manage'
  export default {
    name: "KhcrmChargList",
    mixins:[JeecgListMixin],
    components: {
      KhcrmChargModal
    },
    data () {
      return {
        description: '计费管理模块管理页面',
        // 表头
        columns: [
          {
            title: '企业名称',
            align:"center",
            ellipsis: true,
            width:230,
            fixed:"left",
            dataIndex: 'customerName'
           },
		      {
            title: '编码',
            align:"center",
            dataIndex: 'code',
            ellipsis: true,
            width:120,
           },
		       {
            title: '每月收费金额(元)',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'money'
           },
           {
            title: '月数',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'moneyNum',
            scopedSlots: { customRender: 'moneyNum' }
           },
		       {
            title: '最后收费账期',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'lastDate'
           },
           {
            title: '付款方式',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'payMethod',
           },
		      {
            title: '优惠金额(元)',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'discount',
            scopedSlots: { customRender: 'discount' }
           },
           {
            title: '总金额(元)',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'totalMoney'
           },
		      {
            title: '其他费用(元)',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'otherMoney'
           },
		       {
            title: '账户余额(元)',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'userBalance'
           },
		        {
            title: '实收金额(元)',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'realMoney'
           },
		      {
            title: '收费日期',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'chargDate'
           },
		      {
            title: '收费人',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'createUserName'
           },
		      {
            title: '审核状态',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' }
           },
		       {
            title: '附件',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'fileUrl',
            scopedSlots: { customRender: 'file' }

           },
		      {
            title: '备注',
            align:"center",
            ellipsis: true,
            width:150,
            dataIndex: 'remark'
           },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            ellipsis: true,
            fixed:"right",
            width:150,
            scopedSlots: { customRender: 'action' },
          }
        ],
		    url: {
          list: "/khcrm/web/khcrmCharg/listCharg",
          delete: "/khcrm/web/khcrmCharg/delete",
          deleteRecord:'/khcrm/web/khcrmCharg/changeChargDeleteStatus'
        },
        deleteStatus:0,
        monthFormat:'YYYY-MM',
    }
  },
    created(){
       this.queryParam.deleteStatus = 0
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      }
    },
    methods: {
       tabChange(e){
         this.queryParam.deleteStatus = e
         this.loadData(1)
       },
       // 冻结 解冻
       deleteRecord(id,deleteStatus){
          let param = {
             id : id,
             deleteStatus : deleteStatus
          }
          var that = this;
          that.loading = true;
          postAction(this.url.deleteRecord,param).then((res)=>{
              if(res.code == 200){
                this.loadData(1)
                that.$message.success(res.message);
              }else{
                that.$message.warning(res.message);
              }
          }).finally(() => {
              that.loading = false;
          })
       }

    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>