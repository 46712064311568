<template>
   <!-- modal -->
      <a-modal v-model="changeOwnerVisible" title="变更负责人" @ok="changeOwnerOk">
        <a-row>  
          <a-col :span="24">
           <a-form-model-item label="变更负责人为" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select v-model="userOwnerId" style="width: 100%"  placeholder="请选择">
                <template v-for="(item,i) in userOwnerList" >
                        <a-select-option :key="i" :value="item.userId">{{item.userName}}</a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>  
        <a-row v-if="isShow">   
          <a-col :span="24">
           <a-form-model-item label="将原负责人:" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-radio-group v-model="changeOwnerTo">
                  <a-radio  value="1">
                     移除
                  </a-radio>
                  <a-radio value="3">
                     转为团队成员
                  </a-radio>
                </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>  
      </a-modal>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import { Leads,Person } from "@/apis";

  export default {
    // 选择客户
    name: 'ChangeLeaderModal',
    props: {
      isShow:{
        type: Boolean,
        default: false,
        required: false
      },
    },
    mixins:[JeecgListMixin, mixinDevice],
    components: {
    },
    data () {
      return {
        title:'',
        width:800,
        disableSubmit: false,
        changeOwnerVisible :false,
        confirmLoading: false,
        userOwnerList:[],
        dataSource:[],
        columns:[],
        userOwnerId:undefined,
        changeOwnerTo:"1",
        radioStyle: {
          display: 'block',
          height: '30px',
          lineHeight: '30px',
          paddingTop:'5px'
        },
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
        dictOptions:{},
        superFieldList:[],
      }
    },
    methods: {
      loadData(arg) {
         
      },
      show(){
         this.changeOwnerVisible = true
         this.getOwnerList();
      },
      tableClick(record, index){
        return {
            on: {
               click: () => {
                 let data = {
                    id:record.id,
                    name:record.name
                 }
                this.$emit('ok',data);
                this.cusvisible = false
               }
            }
        }
      },
      getOwnerList(){
        if(this.userOwnerList == null || this.userOwnerList.length == 0){
            Leads.queryUsCompanyUser(null).then((res) => {
              if (res.code ==200) {
                console.log(res.data)
                  this.userOwnerList = res.data
              } else {
                that.$message.warning(res.message);
              }
            })
        }
      },
      changeOwnerOk(){
          let userOwnerId = this.userOwnerId
          if(typeof  userOwnerId == 'undefined' ||   userOwnerId == ""){
             this.$message.warning("请选择要变更的负责人");
             return
          }
         this.$emit('ok',userOwnerId,this.changeOwnerTo);
         this.changeOwnerVisible = false
            
      }, 
      handleOk(){
        this.cusvisible = false
      },
      handleCancel(){
         this.cusvisible = false
      },
    }
  }
</script>