<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="addRotation" type="primary" icon="plus">新增</a-button>
      <a-button @click="previewRotation" type="primary" icon="play-circle">预览</a-button>
      <a-button @click="changeRotationModal(1)" type="primary" icon="arrow-up">发布</a-button>
      <a-button @click="changeRotationModal(0)" type="danger"  icon="arrow-down">取消发布</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />注意: 最多只能上传8张轮播图
      </div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择 <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        class="j-table-force-nowrap"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        @change="handleTableChange">
         
        <template slot="imgPath" slot-scope="text, record">
           <img :src="record.readPath" width="150px" height="150px" />
        </template>

         <template slot="status" slot-scope="text">
             <a-tag color="red" v-if="text == 0">未发布</a-tag>
             <a-tag color="green" v-if="text == 1">已发布</a-tag>
        </template>

        <span slot="action" slot-scope="text, record">
           <a-popconfirm title="删除后无法恢复,确定删除吗?" @confirm="() => handleDelete(record.id)">
              <a>删除</a>
           </a-popconfirm>
        </span>

      </a-table>
    </div>
    <!-- 踢单 -->
    <a-modal
       title="提示"
       :visible="alertVisible"
       :confirm-loading="modalLoading"
       @ok="changeRotationStatus"
       @cancel="handleCancel"
     >
      <p>{{modalText}}</p>
    </a-modal>

    <rotation-form-modal ref="modalForm" @ok="modalFormOk" />
    <img-show-modal ref="imgShowModal" />
  </a-card>
</template>
 
<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import RotationFormModal from './modules/RotationFormModal'
  import { postAction, getAction,uploadAction } from '@/api/manage'
  import ImgShowModal from '@/components/common/ImgShowModal'

  // 轮播图管理
  export default {
    name: 'RotationList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
      RotationFormModal,
      ImgShowModal
    },
    data () {
      return {
        description: 'RotationList管理页面',
        alertVisible:false,
        modalLoading:false,
        modalText:'',
        // 表头
        columns: [
          {
            title:'排序',
            align:"center",
            dataIndex: 'sort',
          },
          {
            title:'图片',
            align:"center",
            dataIndex: 'imgPath',
            scopedSlots: { customRender: 'imgPath' },
          },
          {
            title:'是否发布',
            align:"center",
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
          },
          {
            title:'关联的商品id',
            align:"center",
            dataIndex: 'relationId',
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/shop/web/shopGoodsNotice/getRotationList",
          delete: "/shop/web/shopGoodsNotice/deleteRotation",
          changeRotationStatus: "/shop/web/shopGoodsNotice/changeRotationStatus",
          previewRotation: "/shop/web/shopGoodsNotice/previewRotation",
        },
        changeRotationParam:{}
      }
    },
    created() {
    },
    computed: {
    
    },
    methods: {
       addRotation(){
          this.$refs.modalForm.add();
       },
       // 预览
       previewRotation(){
          let that = this
          getAction(this.url.previewRotation,{}).then((res)=>{
            if(res.code == 200){
                let data = res.data;
                if(data == null){
                    that.$message.warn("没有发布的轮播图片");
                    return 
                }
                that.showImg(data.pathList)
            }else{
              that.$message.error(res.message);
            }
          }) 
       },
       showImg(url,readPath){
        let param = {
          url:url,
          readPath:readPath,
          accessToken: ''
        }
        this.$refs.imgShowModal.show(param);
      },
       modalFormOk(){
          this.loadData();
       },
       // 发布or 取消发布
       changeRotationModal(status){
          if(this.selectedRowKeys.length <= 0){
             this.$message.warn("请勾选要发布的列");
             return
          }
          this.changeRotationParam = {
             idList:this.selectedRowKeys,
             status:status
          }
          if(status == 1){
              this.modalText = "您确定要发布勾选的轮播图吗?";

          }else{
              this.modalText = "您确定要取消发布勾选的轮播图吗?";
          }
          this.alertVisible = true;
       },
       changeRotationStatus(){
            let that = this
            this.modalLoading = true
            postAction(this.url.changeRotationStatus,this.changeRotationParam).then((res)=>{
              if(res.code == 200){
                that.$message.success("操作成功");
                that.modalFormOk();
                that.handleCancel()
              }else{
                that.$message.error(res.message);
              }
            }).finally(() => {
              that.modalLoading = false;
            })
       },
       handleCancel(){
         this.alertVisible = false;
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>