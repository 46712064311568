<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="唯一标识"    prop="mainFiled">
              <a-input :disabled="false" v-model="model.mainFiled" placeholder="请输入唯一标识"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="要导出的列标题名(以英文下的逗号分隔)"  prop="auxFiled">
               <!-- <a-select mode="tags" v-model="model.auxFiled" style="width: 100%" placeholder="请输入要导出的列标题名" @change="handleChange">
                </a-select> -->
               <a-input v-model="model.auxFiled" placeholder="请输入要导出的列标题名(以英文下的逗号分隔)"  ></a-input> 
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="选择上传主文件" prop="mainfileList">
                <a-upload :accept="accept" :file-list="mainfileList" :multiple="false" :remove="mainhandleRemove" :before-upload="mainbeforeUpload">
                  <a-button> <a-icon type="upload" />点击上传主文件</a-button>
                </a-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="选择上传要被合并的文件"  prop="auxfileList">
             <a-upload :accept="accept" :file-list="auxfileList" :multiple="false" :remove="auxhandleRemove" :before-upload="auxbeforeUpload">
                  <a-button> <a-icon type="upload" />点击上传辅文件</a-button>
                </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
  </a-spin>
</template>

<script>

  import { httpAction, getAction,uploadBlobAction,toDownExcel } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'
  import JFormContainer from '@/components/jeecg/JFormContainer'
  import JDate from '@/components/jeecg/JDate.vue'

  export default {
    name: 'AuxExcelForm',
    components: {
      JFormContainer,
      JDate,
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        model:{
           mainFiled:""
         },
        mainfileList:[],
        auxfileList:[],
        accept:'xlsx',
        labelCol: {
          xs: { span: 30 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 30 },
          sm: { span: 10 },
        },
        confirmLoading: false,
        validatorRules: {
           auxFiled: [
              { required: true, message: '请输入要导出的列标题名!'},
           ],
           mainFiled: [
              { required: true, message: '请输入唯一标识!'},
           ],
          //  mainfileList: [
          //     { required: true, message: '请选择上传主文件!'},
          //  ],
           createUserId: [
              { required: true, message: '请输入创建人id!'},
           ],
        },
        url: {
          add: "/khcrm/web/auxExcel/mergeExcel",
        }
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        // this.model = Object.assign({}, record);
        this.mainfileList = [];
        this.auxfileList = [];
        this.visible = true;
      },
      mainhandleRemove(file) {
        const index = this.mainfileList.indexOf(file);
        const newFileList = this.mainfileList.slice();
        newFileList.splice(index, 1);
        this.mainfileList = newFileList;
      },
      mainbeforeUpload(file) {
        // this.fileList = [...this.fileList, file];
        this.mainfileList = []
        this.mainfileList = [...this.mainfileList, file];
        return false;
      },
      auxbeforeUpload(file) {
        // this.fileList = [...this.fileList, file];
        this.auxfileList = [...this.auxfileList, file];
        return false;
      },
      auxhandleRemove(file) {
        const index = this.auxfileList.indexOf(file);
        const newFileList = this.auxfileList.slice();
        newFileList.splice(index, 1);
        this.auxfileList = newFileList;
      },
      handleChange(value) {
        console.log(`selected ${value}`);
      },
       
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {

             if(that.model.auxFiled.indexOf('，') >= 0){
                 that.$message.warning("检测到[要导出的列]中含有中文逗号,请删除");
                  return
             }
             const { mainfileList } = that;
              if(mainfileList == null || mainfileList.length == 0){
                  that.$message.warning("主文件不能为空");
                  return
              }
              const formData = new FormData();
              if(mainfileList.length >0){
                mainfileList.forEach(file => {
                   formData.append('mainfile', file);
                });
              }


              const { auxfileList } = that;
              if(auxfileList == null || auxfileList.length == 0){
                  that.$message.warning("辅文件不能为空");
                  return
              }
              if(auxfileList.length >0){
                if(auxfileList.length > 8){
                    that.$message.warning("辅文件最多上传8个");
                    return
                }
                auxfileList.forEach(file => {
                   formData.append('auxfileList', file);
                });
              }
             formData.append('mainFiled', that.model.mainFiled); 
             formData.append('auxFiled', that.model.mainFiled +','+that.model.auxFiled); 
             that.confirmLoading = true;
            let httpurl = that.url.add;
            uploadBlobAction(httpurl,formData).then((res)=>{
                let fileReader = new FileReader();
                fileReader.onload = function() {
                  try {
                      let jsonData = JSON.parse(fileReader.result);  // 说明是普通对象数据，后台转换失败
                      that.$message.warning(jsonData.message);
                  } catch (err) { 
                      // 解析成对象失败，说明是正常的文件流
                      var fileName = '合并文件' + Math.ceil(Math.random()*100000);+'.xlsx';
                      toDownExcel(res,fileName);
                      that.$message.success("操作成功");
                      that.$emit('ok');
                  } 
                }; 
                fileReader.readAsText(res) 

                that.confirmLoading = false;
            })
          }
         
        })

      },
    }
  }
</script>