<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <!-- 搜索区域 -->
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item label="角色名称">
              <a-input placeholder="" v-model="queryParam.roleName"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="12" :sm="24">
            <a-button
              type="primary"
              @click="searchQuery"
              icon="search"
              style="margin-left: 21px"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 操作按钮区域 -->
    <div class="table-operator" style="margin: 5px 0 10px 2px">
      <a-button @click="handleAdd" type="primary" icon="plus"
        >新建角色</a-button
      >
      <!--<a-button @click="handleEdit(model1)" type="primary" icon="plus">角色编辑</a-button>-->
    </div>
    <div style="margin-top: 15px">
      <a-table
        style="height: 500px"
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange"
      >
        
        <span slot="departName" slot-scope="text, record">
           <span v-if="text">{{text}}</span>
           <span v-else>本平台默认</span>

        </span>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>

          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handlePerssion(record.id)">授权</a>
              </a-menu-item>
              <!-- <a-menu-item>
                <a-popconfirm
                  title="确定删除吗?"
                  @confirm="() => handleDelete(record.id)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item> -->
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
    <!-- 右侧的角色权限配置 -->
    <user-role-modal ref="modalUserRole"></user-role-modal>
    <role-modal ref="modalForm" @ok="modalFormOk"></role-modal>
  </a-card>
</template>
<script>
import { JeecgListMixin } from "@/mixins/JeecgListMixin";
import RoleModal from "./modules/RoleModal";
import UserRoleModal from "./modules/UserRoleModal";
import moment from "moment";
export default {
  name: "RoleUserList",
  mixins: [JeecgListMixin],
  components: {
    RoleModal,
    UserRoleModal,
  },
  data() {
    return {
      model1: {},
      currentRoleId: "",
      queryParam1: {},
      queryParam2: {},
      dataSource1: [],
      dataSource2: [],
      ipagination1: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      ipagination2: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      isorter1: {
        column: "createTime",
        order: "desc",
      },
      isorter2: {
        column: "createTime",
        order: "desc",
      },
      filters1: {},
      filters2: {},
      loading1: false,
      loading2: false,
      selectedRowKeys1: [],
      selectedRowKeys2: [],
      selectionRows1: [],
      selectionRows2: [],
      test: {},
      rightcolval: 0,
      columns: [
        {
          title: "角色编码",
          align: "center",
          dataIndex: "roleCode",
        },
        {
          title: "角色名称",
          align: "center",
          dataIndex: "roleName",
        },
        {
          title: "所属公司",
          align: "center",
          dataIndex: "departName",
          scopedSlots: { customRender: "departName" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          align: "center",
          sorter: true,
          customRender: (text) => {
            return moment(text).format("YYYY-MM-DD");
          },
        },
        {
          title: "操作",
          dataIndex: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      url: {
        list: "/khcrm/web/sysuser/getRoleList",
        delete: "/sys/role/delete",
        list2: "/sys/user/userRoleList",
        addUserRole: "/sys/user/addSysUserRole",
        delete2: "/sys/user/deleteUserRole",
        deleteBatch2: "/sys/user/deleteUserRoleBatch",
        exportXlsUrl: "sys/role/exportXls",
      },
    };
  },
  computed: {},
  methods: {
    handlePerssion(roleId) {
      this.$refs.modalUserRole.show(roleId);
    },
  },
};
</script>
<style scoped>
/** Button按钮间距 */
.ant-btn {
  margin-left: 8px;
}
</style>