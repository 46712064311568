<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="联系人名称" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name">
              <a-input v-model="model.name" placeholder="请输入联系人名称"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="客户" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="customerId">
              <div @click="selectCustomer">
              <a-select style="width: 100%" v-model="model.customerId" placeholder="请选择客户" :open="false">
                <template v-for="item in cusrecord">
                      <a-select-option :value="item.id">{{item.name}}</a-select-option>
                </template>
              </a-select>
              </div> 
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="mobile">
              <a-input v-model="model.mobile" placeholder="请输入手机"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="联系时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="nextTime">
              <j-date placeholder="请选择下次联系时间" v-model="model.nextTime" :dateFormat="dateFormat"  style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="性别" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="sex">
              <a-select style="width: 100%" v-model="model.sex" placeholder="请选择">
                <a-select-option value="男">男</a-select-option>
                <a-select-option value="女">女</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          
          <a-col :span="12">
            <a-form-model-item label="电话" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="telphone">
              <a-input v-model="model.telphone" placeholder="请输入电话"  ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>  
          <a-col :span="12">
            <a-form-model-item label="电子邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="email">
              <a-input v-model="model.email" placeholder="请输入电子邮箱"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="职务" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="post">
              <a-input v-model="model.post" placeholder="请输入职务"  ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>  
          <a-col :span="12">
            <a-form-model-item label="详细地址" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="address">
              <a-input v-model="model.address" placeholder="请输入详细地址"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="决策人" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="isLeader">
              <a-select style="width: 100%" v-model="model.isLeader" placeholder="请选择是否为关键决策人">
                <a-select-option value="1">是</a-select-option>
                <a-select-option value="0">否</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>  
          <a-col :span="24">
            <a-form-model-item label="备注" prop="remark">
              <a-textarea v-model="model.remark" rows="3" placeholder="请输入备注" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
    <select-customer-modal ref="modalForm" @ok="modalFormOk" />
  </a-spin>
</template>

<script>
  import JFormContainer from '@/components/jeecg/JFormContainer'
  import JDate from '@/components/jeecg/JDate.vue'
  import { httpAction, getAction } from '@/api/manage'
  import SelectCustomerModal from '../../customer/modules/SelectCustomerModal.vue'
  export default {
    name: 'KhcrmPersonForm',
    components: {
      JFormContainer,
      JDate,
      SelectCustomerModal,
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        model:{
         },
        dateFormat:"YYYY-MM-DD HH:mm",
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
        loading:false,
        cusrecord: [],
        validatorRules: {
           name: [
              { required: true, message: '请输入联系人名称!'},
           ],
           customerId: [
              { required: true, message: '请选择客户!',trigger: 'change'},
           ],
           mobile: [
              { required: true, message: '请输入手机号!'},
           ],
           isLeader:[
              { required: false, message: '请选择是否为关键决策人!'},
           ],
           address:[
              { required: false, message: '请输入详细地址!'},
           ],
        },
        url: {
          add: "/khcrm/web/person/add",
          edit: "/khcrm/web/person/edit",
          queryById: "/khcrm/web/person/queryById"
        },
        confirmLoading:false,
        isCusClick:true
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        let data = {
          id:record.customerId,
          name:record.customerName
        }
        this.cusrecord = [];
        this.cusrecord.push(data)
        this.model = Object.assign({}, record);
        if(record._isOnlyAddCus){
           this.isCusClick = false
        }else{
          this.isCusClick = true
        }
        this.visible = true;
      },
      selectCustomer(){
        if(this.isCusClick){
              this.$refs.modalForm.show();  
        }
      },
      modalFormOk(e){
          this.cusrecord = [];
          this.cusrecord.push(e)
        //  this.form.setFieldsValue({ customerId: record.id });
          this.model.customerId = e.id
          this.cusvisible = false
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            httpAction(httpurl,this.model,method).then((res)=>{
              if(res.code == 200){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },
    }
  }
</script>