<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper" v-if="haveRole">
      <!-- 搜索区域 -->
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item label="公司名称">
              <a-input placeholder="请输入公司名称" v-model="queryParam.departName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="联系人电话">
              <a-input placeholder="请输入联系人电话" v-model="queryParam.mobile"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="12" :sm="24">
            <a-button
              type="primary"
              @click="searchQuery"
              icon="search"
              style="margin-left: 21px"
              >查询</a-button
            >
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 操作按钮区域 -->
    <div class="table-operator" style="margin: 5px 0 10px 2px">
      <a-button v-if="haveRole" @click="handleAdd('0')" type="primary">添加公司</a-button>
    </div>
   <!-- table区域-begin -->
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      @change="handleTableChange">
        <span slot="orgType" slot-scope="text, record">
            <a-tag v-if="text==1" color="orange">公司</a-tag>
            <a-tag v-else-if="text==2" color="green">部门</a-tag>
            <a-tag v-else-if="text==3" color="cyan">子部门</a-tag>
        </span>

        <span slot="departName" slot-scope="text, record">
          <span v-if="record.orgType == 1">{{text}} <br><a-tag style="margin-top:10px;margin-left:20px" color="blue">允许添加用户: <span style="color:red">{{record.allowNum}}</span> 人</a-tag></span>
          <span v-else>{{text}}</span>
            
        </span>



       <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-dropdown v-if="record.orgType < 3">
            <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="handleAdd(record.id)">添加下级</a>
              </a-menu-item>
              <!-- <template v-if="haveRole && record.orgType == 1">
                 <a-menu-item v-if="record.status == 0">
                  <a-popconfirm
                    title="确定冻结吗?"
                    @confirm="() => handleFrozen(record)"
                  >
                    <a>冻结</a>
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item v-if="record.status == 1">
                  <a-popconfirm
                    title="确定解冻吗?"
                    @confirm="() => handleFrozen(record)"
                  >
                    <a>解冻</a>
                  </a-popconfirm>
                </a-menu-item>
              </template>
               -->
            </a-menu>
          </a-dropdown>
        </span>


    </a-table>
    <!-- table区域-end -->


        <depart-modal ref="departModal" @ok="loadTree"></depart-modal>

  </a-card>
</template>

<script>
  import { filterObj } from '@/utils/util';
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import Vue from 'vue'
  import { USER_INFO } from '@/store/mutation-types'  
  import DepartModal from "./modules/DepartModal";



  export default {
    name: "LogList",
    mixins:[JeecgListMixin],
    components: {
      DepartModal
    },
    data () {
      return {
        description: '',
        haveRole:false,
        // 表头
        columns: [
           {
              title: '公司名称',
              dataIndex: 'departName',
              align:"left",
              ellipsis: true,
              width:220,
              scopedSlots: { customRender: 'departName' }

            },
            {
              title: '类型',
              dataIndex: 'orgType',
              align:"center",
              ellipsis: true,
              width:120,
              scopedSlots: { customRender: 'orgType' }
            },
            {
              title: '公司简称',
              dataIndex: 'departNameAbbr',
              align:"center",
              ellipsis: true,
              width:150,
            },
            
            {
              title: '公司英文名称',
              dataIndex: 'departNameEn',
              align:"center",
              ellipsis: true,
              width:150,
            },
            {
              title: '联系人电话',
              dataIndex: 'mobile',
              align:"center",
              ellipsis: true,
              width:130,
            }, 
            {
              title: '编码',
              dataIndex: 'orgCode',
              align:"center",
              ellipsis: true,
              width:130,
            }, 
            {
              title: '传真',
              dataIndex: 'fax',
              align:"center",
              ellipsis: true,
              width:130,
            }, 
            {
              title: '详细地址',
              dataIndex: 'address',
              align:"center",
              ellipsis: true,
              width:200,
            }, 
            {
              title: "操作",
              dataIndex: "action",
              scopedSlots: { customRender: "action" },
              align: "center",
              width: 140,
            },
        ],
        dataSource:[
          
        ],
        url: {
          list: "/khcrm/web/sysuser/getDeparts",
        },
      }
    },
    created(){
      const userinfo = Vue.ls.get(USER_INFO)
      this.roleCode = userinfo.roleCode
      if(userinfo.roleCode == 'admin'){
        this.haveRole = true
      }else{
        this.haveRole = false
      }
    },
    methods: {
       handleAdd(parentId){
          this.$refs.departModal.add(parentId);
          this.$refs.departModal.title = "新增";
       },
       handleEdit(record){
          this.$refs.departModal.edit(record);
          this.$refs.departModal.title = "编辑";
       },
       loadTree(){
         this.loadData(1)
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less'
</style>