import { getAction, postAction, formAction, formAction2 } from '@/api/manage'
const url = {
    // 猜您喜欢公司数据
    getGussetCompanyList: '/khcrm/web/company/getGussetCompanyList',
     // 根据查询条件查询数据信息
    findTableListByParam: '/khcrm/web/company/findTableListByParam',
    // 查询省市区
    getCityList:'/khcrm/web/company/getCityList',
}
export default {
    getGussetCompanyList(params) {
        return getAction(url.getGussetCompanyList, params);
    },
    findTableListByParam(params) {
        return postAction(url.findTableListByParam, params);
    },
    getCityList(params) {
        return getAction(url.getCityList, params);
    }
}
