<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24"  >
          <a-col :span="12" class="process-searchclass-col">
            <a-form-item label="查询列名">
             <a-select 
                    @change="selectChange"
                    show-search
                    mode="multiple"
                    :filter-option="filterOption"
                    placeholder="请选择要查询列">
                  <a-select-option v-for="(item,index) in  restCheckDate" :value="item.colField + '-'+item.title " :key="index">{{item.title}}</a-select-option>
              </a-select>
            </a-form-item>  
          </a-col>
        </a-row> 
        <a-row class="process-searchclass"> 
          <a-col :md="5" :sm="8" class="process-searchclass-col">
             <a-form-item label="当前环节">
               <a-select 
                   allowClear
                    v-model="queryParam.stepId"
                    placeholder="请选择当前环节"
                  >
                  <a-select-option v-for="item in stepDataList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                </a-select>
             </a-form-item>
          </a-col>
          <a-col :md="5" :sm="8" class="process-searchclass-col">
             <a-form-item label="审批状态">
                <a-select 
                  allowClear
                  v-model="queryParam.approvalStatus"
                  placeholder="请选择审批状态"
                >
                  <a-select-option v-for="item in approvalStatusList" :key="item.code" :value="item.code">{{item.name}}</a-select-option>
                </a-select>
             </a-form-item>
          </a-col>
          <a-col :span="5" v-for="(item,index) in findColData" :key="index" class="process-searchclass-col">
             <a-form-item :label="item.split('-')[1]">
                 <a-input style="width:90%" v-model="queryParam[item.split('-')[0]]" :maxLength="200" placeholder="请输入要查询的数据" />
             </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button style="margin-left:10px" type="primary" @click="searchQuery" icon="search" >查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="startLink" type="primary" icon="plus">开始流程</a-button>
      <a-button @click="setTableHeader" type="primary" icon="edit">表头设置</a-button>
      <a-button @click="setTableSort" type="primary" icon="sort-descending">表头排序</a-button>
    </div>
    <div>
      <a-tabs default-active-key="1" @change="tabsChange">
        <a-tab-pane key="1" tab="我提交的流程">
        </a-tab-pane>
        <a-tab-pane key="2" tab="需要我审批的流程">
        </a-tab-pane>
        <a-tab-pane key="3" tab="抄送给我的流程">
        </a-tab-pane>
     </a-tabs>
    <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap">


        <template slot="file" slot-scope="text">
           <template v-if="text">
              <template  v-for="(item,index) in text.split(',')">
                <a :key="index" @click="downFile(item)">点击查看/下载附件</a>
                <br :key="index + 666" />
            </template>
           </template>
        </template>

        <template slot="approvalStatus" slot-scope="text">
            <a-tag v-if="text==0" color="green">无需审批直接通过</a-tag>
            <a-tag v-else-if="text==1"  color="#FF00FF">审批中</a-tag>
            <a-tag v-else-if="text==2"  color="#52c41a">审批通过</a-tag>
            <a-tag v-else-if="text==3"  color="red">审批拒绝</a-tag>
        </template>
        <template slot="completeStatus" slot-scope="text">
            <a-tag v-if="text==0" color="#87d068">进行中</a-tag>
            <a-tag v-else color="#f50">已完成</a-tag>
        </template>
 

        <span slot="action" slot-scope="text, record">
          <a @click="showDetail(record)">详情</a>
          <a v-if="record.roleCode == usroleCode && record.approvalStatus == 1" @click="approvalUser(record)"><a-divider type="vertical" />审批</a>
          <template v-if="record.createUserId == createUserId && record.completeStatus == 0 && (record.approvalStatus == 0 || record.approvalStatus == 2)" >
              <a-divider type="vertical" />
              <a @click="nextLink(record)">进行下一环节</a>
          </template>
          <template v-if="record.createUserId == createUserId && (record.approvalStatus == 3)" >
              <a-divider type="vertical" />
              <a @click="againLink(record)">重新填写</a>
          </template>
        </span>

      </a-table>
    </div> 
    <process-detail ref="processDetailForm" />
   <start-process ref="startProcessForm" @ok="startProcessFormOk" />
   
    <!-- 环节审批 -->
    <a-modal
      title="环节审批"
      :visible="modalvisible"
      :confirm-loading="confirmLoading"
      @ok="modalOk"
      @cancel="modalCancel"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-form-item label="状态">
            <a-select 
                v-decorator="['approvalStatus', { rules: [{ required: true, message: '请选择是否通过审批!' }] }]"
                placeholder="请选择是否通过审批"
                style="width: 100%" >
                    <a-select-option  :value="2">通过</a-select-option>
                    <a-select-option  :value="3">拒绝</a-select-option>
                </a-select>  
          </a-form-item>
          <a-form-item label="批语">
             <a-textarea
                :rows="4"
                v-decorator="['approvalContent', { rules: [{ required: true, message: '请输入通过/不通过原因!' }] }]"
                placeholder="请输入通过/不通过原因"></a-textarea>
          </a-form-item>
      </a-form>
    </a-modal>

<!-- 表头设置 -->
    <a-modal
      title="表头设置"
      :visible="tablevisible"
      :confirm-loading="confirmLoading"
      @ok="tablemodalOk"
      @cancel="tablemodalCancel"
    >
      <a-checkbox-group v-model="checkedList">
          <a-row>
            <template v-for="(item,index) in selectCol">
              <a-col :span="12" :key="index">
                <a-checkbox :value="item.colField">
                  {{item.title}}
                </a-checkbox>
              </a-col>
            </template>
          </a-row>
        </a-checkbox-group>
    </a-modal>
   
    <!-- 表头排序 -->
    <a-modal
      title="表头排序"
      :visible="sortvisible"
      :confirm-loading="confirmLoading"
      @ok="sortModalOk"
      @cancel="sortCancel"
    >
    <div style="color:red;margin-bottom:20px">注意：表头顺序为从小到大,数值越小,顺序约靠前!!!</div>
    <a-row>
      <a-form :form="sortform" :label-col="{ span: 16 }" :wrapper-col="{ span: 8}" >
         <a-col :key="index" :span="12" v-for="(item,index) in sortShowList" > 
          <a-form-item :label="item.title" >
            <a-input-number 
                style="width:100%"
                v-decorator="[item.id, { initialValue: item.sort, rules: [{ required: true, message: '请输入顺序!' }] }]"
                placeholder="请输入顺序"
                :min="1" :max="10000" :precision="0" 
              />
          </a-form-item>
         </a-col>  
      </a-form>
    </a-row>  
    </a-modal>

  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import ProcessTemplate from '../common/ProcessTemplate.vue'
  import { MakeLink } from "@/apis";
  import StartProcess from '../common/StartProcess.vue'
  import pick from "lodash.pick";
  import Vue from 'vue'
  import { USER_INFO } from '@/store/mutation-types'
  import ProcessDetail from '../common/ProcessDetail.vue'
  import { deepClone } from '@/utils/util'

  export default {

    // 流程环节制作之后统一页面
    name: 'ProcessList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
       ProcessTemplate,
       StartProcess,
       ProcessDetail
    },
    data () {
      return {
        description: '流程环节制作之后统一页面',
        // 表头
        columns: [],
        selectCol:[],
        dataSource:[],
        loading:false,
        sortvisible:false,
        modalvisible:false,
        confirmLoading:false,
        modalRecord:{},
        form:this.$form.createForm(this, { name: 'coordinated' }),
        sortform:this.$form.createForm(this, { name: 'sortform' }),
        processId:'',
        createUserId:'',
        usroleCode:'',
        tablevisible:false,
        tabType:1,
        checkedList:[],
        sortShowList:[],
        restCheckedList:[],// 存源数据  防止静态页面 数据变动
        restCheckDate:[],
        userSelectTitle:[],
        approvalStatusList:[
          {
            code:0,
            name:'不需要审批直接通过'
          },{
            code:1,
            name:'审批中'
          }, {
            code:2,
            name:'审批通过'
          }, {
            code:3,
            name:'审批拒绝'
          }
        ],//审批状态
        findColData:[],// 查询列数据
        stepDataList:[] // 环节步骤
      }
    },
    created() {
      const userinfo = Vue.ls.get(USER_INFO)
      this.usroleCode = userinfo.roleCode
      this.createUserId = userinfo.userId
      let id = this.$route.meta.param.id
      if(id){
          this.processId = id
          this.getFiledColByProcessId();
          this.getAllFiledCol();
          this.getProccessStepInfo();
      }else{
         this.processId = ''
         this.$message.error("获取参数失败,请联系客服！");
      }
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      loadData(arg) {
        // 加载数据 若传入参数1则加载第一页的内容
        if (arg === 1) {
          this.ipagination.current = 1
        }
       
        if(this.processId){
          this.getProcessApprovalData(this.tabType)
        }
      },
      selectChange(e){
        this.findColData = e;
      },
      setTableHeader(){
         this.checkedList = deepClone(this.restCheckedList)
         this.tablevisible = true
      },
      setTableSort(){
        let selectCol = this.selectCol
        let userSelectTitle = this.userSelectTitle
        if(userSelectTitle == null || userSelectTitle.length == 0){
          this.$message.warning("请先进行表头设置,然后再进行排序!");
          return
        }
        let sortShowList = []
        for(let r in userSelectTitle){
          let rest = userSelectTitle[r]
          console.log(rest)
          for(let s in  selectCol){
             let sc = selectCol[s]
             console.log(sc)
             if(sc.colField == rest.colField){
                let showjson = {
                   title : sc.title,
                   id: rest.id,
                   sort:rest.sort
                }
                sortShowList.push(showjson)
             }
          }
        }
        console.log(sortShowList)
        this.sortShowList = sortShowList
        this.sortvisible = true
      },
      //设置表头
      tablemodalOk(){
        if(this.checkedList == null || this.checkedList.length == 0){
            this.$message.warning("请至少选择一个表头!");
            return
        }
         let param = {
            processId : this.processId,
            selectUserFiled:this.checkedList
         }
         this.saveUserSelectFiled(param);
      },
      // 表头排序
      sortModalOk(){
          this.sortform.validateFields((err, values) => {
              if (!err) {
                let param = values
                console.log(values)
                this.confirmLoading = true
                MakeLink.changeUserSelectSort(param).then((res) => {
                    if (res.code ==200) {
                        this.$message.success("保存成功")
                        this.getFiledColByProcessId();
                        this.getUserSelectFiledCol(null);
                        this.sortvisible = false
                    } else {
                      this.$message.warning(res.message);
                    }
                }).finally(() => {
                    this.confirmLoading = false
                })  
              }
          })
      },
      tablemodalCancel(){
        this.tablevisible = false
      },
      tabsChange(e){
        this.getProcessApprovalData(e)
      },
      startLink(){
        this.$refs.startProcessForm.show(this.processId,1);
      },
      nextLink(r){
         let stepNum = r.stepNum + 1
         this.$refs.startProcessForm.show(this.processId,stepNum,r.id);
      },
      againLink(r){
         let stepNum = r.stepNum
         this.$refs.startProcessForm.show(this.processId,stepNum,r.id);
      },
      showDetail(record){
        this.$refs.processDetailForm.show(record);

      },
      downFile(t){
        window.open(t)
      },
      getProcessApprovalData(tabType){
         let params = this._getQueryParams() // 查询条件
         params.processId = this.processId,
         params.tabType = this.tabType
         console.log(params)
          this.tabType = tabType
          this.loading = true
          MakeLink.getProcessApprovalData(params).then((res) => {
              if (res.code ==200) {
                 this.dataSource = res.data
                 this.ipagination.total = res.total
              } else {
                that.$message.warning(res.message);
              }
              this.loading = false
          }) 
      },
      getFiledColByProcessId(){
          let param = {
             processId : this.processId
          }
          this.loading = true
          MakeLink.getFiledColByProcessId(param).then((res) => {
              if (res.code ==200) {
                 let cols = [
                   {
                      title: '序号',
                      dataIndex: '',
                      key:'rowIndex',
                      width:80,
                      align:"center",
                      customRender:function (t,r,index) {
                        return parseInt(index)+1;
                      }
                    }
                    
                 ]
                 let data = res.data
                 if(data !=null && data.length > 0){
                    for(let  i in data){
                        if(data[i].type == 'file'){
                           let col = {
                              title: data[i].title,
                              align:"center",
                              ellipsis: true,
                              width:170,
                              dataIndex: data[i].colField,
                              scopedSlots: { customRender: 'file' }
                            }
                            cols.push(col)  
                        }else{
                            let col = {
                              title: data[i].title,
                              align:"center",
                              ellipsis: true,
                              width:170,
                              dataIndex: data[i].colField
                            }
                            cols.push(col)  
                        }
                    }
                 }
                
                cols.push({
                   title: '批语',
                   dataIndex: 'approvalContent',
                   align:"center",
                   ellipsis: true,
                 },
                 {
                   title: '环节状态',
                   dataIndex: 'completeStatus',
                   align:"center",
                   width:147,
                   scopedSlots: { customRender: 'completeStatus' }
                },{
                   title: '当前环节',
                   dataIndex: 'stepName',
                   align:"center",
                   width:140,
                   fixed: 'right',
                }
                ,{
                   title: '审批状态',
                   dataIndex: 'approvalStatus',
                   align:"center",
                   width:140,
                   fixed: 'right',
                   scopedSlots: { customRender: 'approvalStatus' }
                },
                {
                   title: '操作',
                   dataIndex: 'action',
                   align:"center",
                   fixed: 'right',
                   width:150,
                   scopedSlots: { customRender: 'action' }
                })
                this.columns = cols
                // this.getProcessApprovalData()
              } else {
                that.$message.warning(res.message);
              }
              this.loading = false
          }) 
       },
       approvalUser(r){
          this.form.resetFields();
          this.modalRecord = r
          this.modalvisible = true
          this.confirmLoading = false
       },
       // 获取选中的
       getUserSelectFiledCol(alldata){
         let param = {
           processId:this.processId
         }
          MakeLink.getUserSelectFiledCol(param).then((res) => {
              if (res.code ==200) {
                  if(res.data != null && res.data.length > 0){
                     alldata = res.data
                     this.userSelectTitle = res.data;
                  }  
                  let checkedList = []
                  for(let i in alldata){
                    let d = alldata[i]
                    checkedList.push(d.colField)
                  }
                  this.checkedList = checkedList
                  this.restCheckedList = deepClone(checkedList)
                 
              } else {
                this.$message.warning(res.message);
              }
          }) 
       },
       //  获取用户选择得页面字段
       getAllFiledCol(){
         let param = {
           processId:this.processId
         }
         MakeLink.getAllFiledCol(param).then((res) => {
            if (res.code ==200) {
                this.selectCol = res.data
                this.restCheckDate = deepClone(res.data)
                this.getUserSelectFiledCol(res.data);
            } else {
              this.$message.warning(res.message);
            }
          }) 
       },
       //保存用户选择得页面字段
       saveUserSelectFiled(param){
         this.confirmLoading = true
         MakeLink.saveUserSelectFiled(param).then((res) => {
            if (res.code ==200) {
                this.$message.success("保存成功")
                this.restCheckedList = deepClone(param.selectUserFiled)
                this.getFiledColByProcessId();
                this.getUserSelectFiledCol(null);
                this.tablevisible = false
            } else {
              this.$message.warning(res.message);
            }
          }).finally(() => {
            this.confirmLoading = false
          }) 
       },
       modalOk(){
          this.form.validateFields((err, values) => {
              if (!err) {
                let param = values
                param.id = this.modalRecord.id
                param.stepName = this.modalRecord.stepName
                this.confirmLoading = true
                MakeLink.approvalStepData(param).then((res) => {
                    if (res.code ==200) {
                        this.$message.success("操作成功")
                        this.loadData(1)
                        this.modalvisible = false
                    } else {
                      this.$message.warning(res.message);
                    }
                     this.confirmLoading = false
                }) 
              }
          })
       },
       modalCancel(){
          this.modalvisible = false

       },
       sortCancel(){
         this.sortvisible = false
       },
       filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
       startProcessFormOk(){
         this.loadData();
       },
       getProccessStepInfo(){
          let param = {
              processId:this.processId
          }
          MakeLink.getProccessStepInfo(param).then((res) => {
              if (res.code ==200) {
                  this.stepDataList = res.data
              } else {
                this.$message.warning(res.message);
              }
          }) 
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
  .process-searchclass{
    margin-bottom: 40px;
    
  }
  .process-searchclass-col{
    margin-left: 20px;
  }
</style>