import { render, staticRenderFns } from "./UserRoleModal.vue?vue&type=template&id=52ca3609&scoped=true&"
import script from "./UserRoleModal.vue?vue&type=script&lang=js&"
export * from "./UserRoleModal.vue?vue&type=script&lang=js&"
import style0 from "./UserRoleModal.vue?vue&type=style&index=0&id=52ca3609&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ca3609",
  null
  
)

export default component.exports