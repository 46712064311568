<template>
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="公告内容(中文)">
                  <a-input :maxLength="700" v-decorator="['contentZh', { rules: [{ required: true, message: '请输入公告内容!' }] }]"  placeholder="请输入公告内容"  ></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="公告内容(英文)" v-if="openLanguage">
                  <a-input :maxLength="700" v-decorator="['contentEn', { rules: [{ required: true, message: '请输入公告内容!' }] }]"  placeholder="请输入公告内容"  ></a-input>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
    </a-modal>  

</template>

<script>

  import { postAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/commonUtil'
  export default {
    name: 'NoticeFormModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:800,
        visible: false,
        openLanguage:false,
        confirmLoading: false,
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          createNotice: "/shop/web/shopGoodsNotice/createNotice",
          updateNotice: "/shop/web/shopGoodsNotice/updateNotice",
        },
        isUpdate:false,
        updateId:0,
        openLanguage:false
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      add (item) {
        this.form.resetFields();
        this.openLanguage = item.openLanguage
        this.isUpdate = false;
        this.visible = true;
        this.title = "新增公告"
      },
      edit (record) {
        let setValue = {
           contentZh:record.contentZh,
           contentEn:record.contentEn
        }
        this.$nextTick(() => {
            setTimeout(() => {
                this.form.setFieldsValue(setValue)
            })
        });
        this.openLanguage = record.openLanguage
        this.updateId = record.id;
        this.isUpdate = true;
        this.visible = true;
        this.title = "修改公告"
      },
      submitForm () {
        this.form.validateFields((err, values) => {
          if (!err) {
            if(this.isUpdate){
              this.updateNotice(values)
            }else{
              this.createNotice(values)
            }
          }
         })
      },
      // 创建公告
      createNotice(param){
        let that = this
        this.confirmLoading = true
        postAction(this.url.createNotice,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("创建成功");
            that.handleCancel()
            that.$emit('ok');
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
      },
      updateNotice(param){
        let that = this
        param.id = this.updateId;
        this.confirmLoading = true
        postAction(this.url.updateNotice,param).then((res)=>{
          if(res.code == 200){
            that.$message.success("创建成功");
            that.handleCancel()
            that.$emit('ok');
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
      },
      handleCancel () {
        this.visible = false;
      }
    }
  }
</script>