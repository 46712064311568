<template>
    <div>
      <a-drawer
        :title="'流程环节预览'"
        placement="right"
        :closable="false"
        width="65%"
        :visible="visible"
        @close="onClose"
      >
      <a-spin :spinning="spinning">
        <div >
          <a-steps labelPlacement="vertical" style="margin-top:-20px" :current="current"  >
            <template v-for="item in stepData">
               <a-step  :key="item.id">
                 <template v-if="item.isUse == 0">
                    <span slot="title">
                      <a-dropdown>
                        <a style="margin-left:10px"><a-icon type="edit" /></a>
                        <a-menu slot="overlay">
                          <a-menu-item>
                            <a @click="updateSetpInfo(item)">修改</a>
                          </a-menu-item>
                          <a-menu-item>
                            <a-popconfirm title="停用该步骤后,旗下所有控件都会被停止,是否要停用?" @confirm="() => stopStep(item)">
                              <a>停用</a>
                            </a-popconfirm>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    <span class="step-title"> {{item.name}} </span>
                  </span>
                  <span slot="description">
                      <div style="font-size:12px"><strong>审批角色:</strong> 
                        <span v-if="item.isApproval != 1 " style="color:green;margin-left:5px">无需审批</span>
                        <span v-else style="margin-left:5px">{{item.roleName}}</span>
                      </div>
                  </span>
                </template>
                <template v-else>
                  <span slot="title">
                    <div style="color:red;font-size:8px">该环节步骤已停用 </div>
                    <span class="step-title" style="color:#c3c3c3"> 
                      {{item.name}}
                        <a-popconfirm style="font-size:8px" title="启用该步骤后,旗下所有控件都会被启用,是否要开启?" @confirm="() => startStep(item)">
                              <a>启用</a>
                        </a-popconfirm>
                    </span>  
                  </span>
                </template>
             </a-step>
            </template>
          </a-steps>
        </div>  
          <a-divider />
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            
             <a-row v-if="pageData != null && pageData.length > 0">
                <template  v-for="(item, index) in pageData">
                   <template v-if="item.isUse  == '0'">
                        <a-col :span="12" :key="index"  >
                          <a-form-item :label="item.title">
                              <a-input 
                                v-if="item.type == 'text'"
                                class="control-width"
                                v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请输入'+item.title+'!' }] }]"
                                :placeholder="'请输入' + item.title"></a-input>
                              <a-input-number 
                                v-if="item.type == 'number'"
                                class="control-width"
                                v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请输入'+item.title+'!' }] }]"
                                :placeholder="'请输入' + item.title"
                                :min="item.isNegative == 0 ? -9999999 : 0" :max="9999999" :precision="item.isDecimal == 0? 2 : 0" 
                              />
                            <a-textarea
                                class="control-width"
                                v-if="item.type == 'textarea'"
                                :rows="4"
                                v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请输入'+item.title+'!' }] }]"
                                :placeholder="'请输入' + item.title"></a-textarea>
                            <template v-if="item.type == 'time'">
                              <a-month-picker
                                class="control-width"
                                v-if="item.accurate == 0" 
                                :placeholder="'请选择' + item.title" 
                                v-decorator="[item.colField + '_' + item.accurate, { rules: [{ required: item.isMust == 1, message: '请选择'+item.title+'!' }] }]"
                                />
                              <j-date 
                                class="control-width"
                                v-else
                                :placeholder="'请选择' + item.title" 
                                v-decorator="[item.colField + '_' + item.accurate, { rules: [{ required: item.isMust == 1, message: '请选择'+item.title+'!' }] }]"
                                :dateFormat="item.accurate == 1 ? dateFormat : timeFormat" 
                                  />
                              </template>  
                              <a-select 
                                class="control-width"
                                v-if="item.type == 'select'"
                                v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请选择'+item.title+'!' }] }]"
                                :placeholder="'请选择' + item.title"
                                :mode="item.multiple == 1? 'default' : 'multiple'"
                              >
                                <template  v-for="(s, i) in item.dataSource.split(',')">
                                  <a-select-option :key="i" :value="s">{{s}}</a-select-option>
                                </template>
                              </a-select>  
                              <a-dropdown v-if="item.type != 'file'">
                                  <a style="margin-left:10px"><a-icon type="edit" /></a>
                                  <a-menu slot="overlay">
                                    <a-menu-item>
                                      <a @click="updateControl(item)">修改</a>
                                    </a-menu-item>
                                    <a-menu-item>
                                      <a-popconfirm title="确定是否要停用该控件?" @confirm="() => stopControl(item)">
                                        <a>停用</a>
                                      </a-popconfirm>
                                    </a-menu-item>
                                    <!-- <a-menu-item>
                                      <a  style="color:red" @click="deletePage(item)">删除</a>
                                    </a-menu-item> -->
                                  </a-menu>
                                </a-dropdown>

                            <a-upload 
                                class="control-width"
                                v-if="item.type == 'file'"
                                v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请选择'+item.title+'!' }] }]"
                                :placeholder="'请选择' + item.title"
                                :file-list="filecol[item.colField] || []" 
                                :multiple="item.multiple == 0" 
                                :remove="handleRemove"
                                :before-upload="beforeUpload">
                                <a-button @click="uploadBtn(item.colField)"> <a-icon type="upload" />点击上传{{item.title}}</a-button>
                            
                                <a-dropdown>
                                  <a style="margin-left:10px"><a-icon type="edit" /></a>
                                  <a-menu slot="overlay">
                                    <a-menu-item>
                                      <a @click="updateControl(item)">修改</a>
                                    </a-menu-item>
                                    <a-menu-item>
                                      <a-popconfirm title="确定是否要停用该控件?" @confirm="() => stopControl(item)">
                                        <a>停用</a>
                                      </a-popconfirm>
                                    </a-menu-item>
                                    <!-- <a-menu-item>
                                      <a  style="color:red" @click="deletePage(item)">删除</a>
                                    </a-menu-item> -->
                                  </a-menu>
                                </a-dropdown>
                            </a-upload>    
                          </a-form-item>
                        </a-col>
                  </template>
                  <template v-else>
                    <a-col :span="12" :key="index">
                      <a-form-item :label="item.title">
                          <span style="color:red">该控件已停止使用</span>
                            <a-popconfirm  style="margin-left:10px" title="确定是否要启用该控件?" @confirm="() => startControl(item)">
                              <a>启用</a>
                            </a-popconfirm>
                      </a-form-item>
                    </a-col>  

                  </template>
                </template>
             </a-row>
             <a-row v-else>
               <a-form-item>
                无需添加任何信息,上一环节信息复制一份,自动执行下一环节
               </a-form-item>
             </a-row>
          </a-form>
         
        </a-spin>  
          <div
            :style="{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }"
          >
           
            <a-button style="marginRight: 8px" @click="onClose">
              关闭
            </a-button>
             <a-button type="primary" style="marginRight: 8px" @click="addControl">
              新增控件
            </a-button>
            <template>
                 <a-button v-if="this.current > 0" style="margin-left: 8px"   type="primary" @click="previousStep">
                  上一步
                 </a-button>
                 <a-button style="margin-left: 8px"  v-if="this.current < (this.stepData.length - 1)"  type="primary" @click="nextStep">
                  下一步
                 </a-button>
            </template>
           
        </div>
     </a-drawer> 

     <update-control-modal ref="updateControlForm" @ok="updateControlFormOk" />
     <update-step-modal ref="updateStepForm" @ok="updateControlFormOk"  />
    </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { MakeLink } from "@/apis";
  import moment from 'moment'
  import UpdateControlModal from './UpdateControlModal.vue';
import UpdateStepModal from './UpdateStepModal.vue';

  export default {
    // 渲染控件
    name: 'ControlTemplate',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate,
      UpdateControlModal,
        UpdateStepModal
    },
    data () {
      return {
        spinning:false,
        visible:false,
        dateFormat:"YYYY-MM-DD",
        timeFormat:"YYYY-MM-DD HH:mm:ss",
        record:{},
        current:0,
        curStep:[],
        stepData:[],
        pageData:[],
        filecol:{},//文件集合
        numLimitType:'1',
        nowfileCol:"",
        form: this.$form.createForm(this, { name: 'coordinated' }),
      }
    },
    created() {
    },
    methods: {
      loadData(arg) {
        // 加载数据 若传入参数1则加载第一页的内容
        if (arg === 1) {
          this.ipagination.current = 1
        }
        let params = this._getQueryParams() // 查询条件
        console.log(params)
      },
      show(record){
        this.current = 0
        this.record = record
        this.form.resetFields();
        this.filecol = {};
        this.spinning = false

        this.findStepInfoByProcessId()
        this.visible = true
      },
      previousStep(){
        if(this.current <=0){
             return
        }
        this.form.resetFields();
        this.current --;
        this.curStep = this.stepData[this.current]
        this.pageData = this.curStep.pageList
      },
      nextStep(){
        if(this.current >= (this.stepData.length - 1)){
             return
        }
        this.form.resetFields();
        this.current ++;

        this.curStep = this.stepData[this.current]
        this.pageData = this.curStep.pageList
      },
      onClose(){
        this.visible = false
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      beforeUpload(file,e) {
        this.filecol[this.nowfileCol] = []
        this.filecol[this.nowfileCol].push(file)
        return false;
      },
      uploadBtn(col){
        this.nowfileCol = col
      },
      //查询信息
      findStepInfoByProcessId(nowStep){
        let param = {
            id : this.record.id
        }
        MakeLink.findStepInfoByProcessId(param).then((res) => {
            if (res.code ==200) {
              if(res.data !=null && res.data.length > 0){
                this.stepData = res.data
                if(nowStep){
                    this.curStep = res.data[nowStep]
                }else{
                    this.curStep = res.data[0]
                }
                this.pageData = this.curStep.pageList
              }
            } else {
              this.$message.warning(res.message);
            }
        }) 
      },
      // 修改环节信息
      updateSetpInfo(e){
        this.$refs.updateStepForm.show(e);
      },
      // 停止整个步骤环节
      stopStep(item){
        this.changeStepUse(item,1)
      },
      startStep(item){
        this.changeStepUse(item,0)
      },
      changeStepUse(item,isUse){
        let param = {
            id:item.id,
            isUse: isUse
         }
         this.spinning = true
          MakeLink.changeStepUse(param).then((res) => {
            if (res.code ==200) {
               this.updateControlFormOk()
            } else {
              this.$message.warning(res.message);
            }
          }).finally(() => {
            this.spinning = false
          })
      },
      //修改控件信息
      updateControl(e){
         this.$refs.updateControlForm.show(e,false);
      },
      // 启用控件
      startControl(item){
         this.optPageIsUse(item,0)
      },
      // 停用控件
      stopControl(item){
         this.optPageIsUse(item,1)
      },
      //删除控件
      deletePage(e){
         console.log(e)
      },
      // 启用停用控件
      optPageIsUse(item,isUse){
         let param = {
            id:item.id,
            isUse: isUse
         }
         this.spinning = true
          MakeLink.optPageIsUse(param).then((res) => {
            if (res.code ==200) {
               item.isUse = isUse
            } else {
              this.$message.warning(res.message);
            }
          }).finally(() => {
            this.spinning = false
          })
      },
      addControl(){
        this.$refs.updateControlForm.show(this.curStep,true);
         console.log(this.curStep)
      },
      updateControlFormOk(){
        this.findStepInfoByProcessId(this.current)
      }
       
    }
  }
</script>
<style lang="less" scoped>
  @import '~@assets/less/common.less';
  
  .seg-title-all{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .submit-btn{
    display: flex;
    flex-direction:row-reverse;
    justify-content: center;
  }
  .input-class{
    width:75%;
    margin-left:10px
  }

  .seg-line{
    background-color: @primary-color;
    width: 4px;
    height: 20px;
  }
  .seg-font{
    margin-left: 10px;
    font-size: 16px;
    font-weight: 800;
    
  }
  .del-a-btn{
    margin-left: 20px;
  }
  .seg-row{
    margin-left:20px;
    margin-top:20px
  }
  .seg-col{
    display: flex;
    flex-direction: row;
    
  }
  .seg-col-div{
    width: 100px;
    text-align: right;
    margin-right: 20px;
    /* background-color: red; */
  }
 .control-width{
   width: 70%;
 }
 .step-title{
 }
</style>
<style lang="less" >
  .ant-steps-label-vertical .ant-steps-item-content {
    width: 150px;
  }
  .ant-steps{
    margin-top: 20px;
    display: block;
    // line-height: 3
  }
  .ant-steps-item{
    margin-top:20px
  }

</style>
