<template>
  <a-card :bordered="false">
   <div class="gpt">
      <div>Gtp3.5 </div>
      <div class="gpt-body">
        <div class="gpt-answer"  >
          <a-icon type="android" theme="filled" style="font-size: 30px;color: #486AF7;"/>
          <div style="color: #486AF7;padding-left: 30px;">您好,请问有什么可以帮助您?</div>
        </div>
        <template v-for="item in chatData">
              <div class="gpt-question" v-if="item.question != ''">
                <a-icon type="message" style="font-size: 30px;color: #30BEB9;"  />
                <div style="color: #30BEB9;padding-left: 30px;">{{item.question}}</div>
              </div>
              <div class="gpt-answer" v-if="item.answer != ''" >
                <a-icon type="android" theme="filled" style="font-size: 30px;color: #486AF7;"/>
                <div style="color: #486AF7;padding-left: 30px;">{{item.answer}}</div>
              </div>
        </template>  
      </div>   
      

      <div class="gpt-btn">
        <a-input-search v-model="content"  :disabled="disabled" @search="onSearch" enter-button="发送" allowClear/>
      </div>
   </div>  

  </a-card>
</template>
 
<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { postAction, getAction } from '@/api/manage'
  import { deepClone } from '@/utils/util'
  // chatGpt
  export default {
    name: 'ChatGptList',
    mixins:[JeecgListMixin, mixinDevice],
    data () {
      return {
        description: 'ChatGpt',
        // 表头
        columns: [],
        disabled:false,
        url: {
          list: "",
          sendGptChat:"/khcrm/web/intellect/sendGptChat",
        },
        content:"",
        chatData:[
        
        ]

      }
    },
    created() {
    },
    computed: {
    
    },
    methods: {
       modalFormOk(){
          this.loadData();
       },
       onSearch(){
          if(this.content == ''){
            this.$message.warning("请输入要发送的问题");
            return;
          }
          let data = {
            answer:"loading.......",
            question: this.content,
          }
          this.chatData.push(data);
          let info = deepClone(this.content)
          this.sendGptChat(info);
          this.content = ''
          this.disabled = true;
      
       },
       sendGptChat(content){
          let that = this;
          let param = {
            content : content
          }
          postAction(this.url.sendGptChat,param).then((res)=>{
            if(res.code == 200){
              that.chatData.at(-1).answer = res.data;
            }else{
              that.$message.error(res.message);
            }
          }).finally(() => {
            that.confirmLoading = false;
            that.disabled = false;
          })
       }
    }
  }
</script>
<style scoped>
  .gpt{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .gpt-body{
    background-color: #DAF3FE;
    height: 500px;
    width: 80%;
    padding-top: 20px;
    padding-left: 20px;
  }
  .gpt-btn{
    width: 80%;
    margin-top: 30px;
  }
  .gpt-answer{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-size: 20px;
    margin-top:20px;
  }
  .gpt-question{
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-top:20px;
  }
</style>