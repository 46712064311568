<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="addNotice" type="primary" icon="plus">新增</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

       <template slot="status" slot-scope="text, record">
          <template  v-if="record.status == 1">
            <a-switch checked-children="已发布"  :checked="true" :loading="switchLoading" @click="changeNoticeModal(record,0)" />
          </template>
          <template  v-else>
            <a-switch un-checked-children="未发布" :checked="false" :loading="switchLoading" @click="changeNoticeModal(record,1)"/>
          </template>
        </template>
        <span slot="action" slot-scope="text, record">
          <a @click="updateNotice(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="删除后无法恢复,确定删除吗?" @confirm="() => deleteNotice(record.id)">
              <a>删除</a>
           </a-popconfirm>
        </span>

      </a-table>
    </div>
     <a-modal
       title="提示"
       :visible="alertVisible"
       @ok="changeNoticeStatus"
       @cancel="handleCancel"
     >
      <p>{{modalText}}</p>
    </a-modal>
     <notice-form-modal ref="noticeForm" @ok="modalFormOk" />
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import NoticeFormModal from './modules/NoticeFormModal.vue'
  import { postAction, getAction } from '@/api/manage'
  import { USER_INFO } from '@/store/mutation-types'
  import Vue from 'vue'

  // 通知公告管理
  export default {
    name: 'NoticeList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
        NoticeFormModal
      
    },
    data () {
      return {
        description: 'NoticeList',
        switchLoading:false,
        alertVisible:false,
        modalText:'',
        // 表头
        columns: [
          {
            title:'公告内容(中文)',
            align:"center",
            dataIndex: 'contentZh'
          },
          {
            title:'公告内容(英文)',
            align:"center",
            dataIndex: 'contentEn'
          },
          {
            title:'是否发布',
            align:"center",
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
          },
          {
            title:'创建时间',
            align:"center",
            dataIndex: 'createTime'
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/shop/web/shopGoodsNotice/getNoticeList",
          changeNoticeStatus: "/shop/web/shopGoodsNotice/changeNoticeStatus",
          deleteNotice: "/shop/web/shopGoodsNotice/deleteNotice",
        },
        changeNoticeParam:{},
        openLanguage:false
      }
    },
    created() {
      const userinfo = Vue.ls.get(USER_INFO)
      this.openLanguage = userinfo.openLanguage
      if(!userinfo.openLanguage){
        this.columns.splice(1, 1);
      }
    },
    computed: {
    
    },
    methods: {
      addNotice(){
        let item =  {
          openLanguage : this.openLanguage
        }
        this.$refs.noticeForm.add(item);
      },
      updateNotice(item){
        item.openLanguage = this.openLanguage
        this.$refs.noticeForm.edit(item);
      },
      changeNoticeModal(item,status){
          let param = {
            id:item.id,
            status : status
          }
        this.changeNoticeParam = param;
        if(status == 1){
           this.modalText = '系统同一时间仅会发布一条公告,发布该公告会自动取消其余公告,您确定要发布该公告吗?';
        }else{
           this.modalText = '您确定要取消发布该公告吗?';
        }
        this.alertVisible = true;
      },
      changeNoticeStatus(){
          let that = this
          that.handleCancel();
          this.switchLoading = true
          postAction(this.url.changeNoticeStatus,this.changeNoticeParam).then((res)=>{
            if(res.code == 200){
              that.$message.success("操作成功");
              that.modalFormOk();
            }else{
              that.$message.error(res.message);
            }
          }).finally(() => {
            that.switchLoading = false;
          })
      },
      deleteNotice(id){
        let param = {
          id:id
        }
        let that = this
        this.loading = true
        postAction(this.url.deleteNotice,param).then((res)=>{
            if(res.code == 200){
              that.$message.success("删除成功");
              that.modalFormOk();
            }else{
              that.$message.error(res.message);
            }
        }).finally(() => {
            that.loading = false;
        })
      },
      handleCancel(){
        this.alertVisible = false;
      },
      modalFormOk(){
          this.loadData();
      },
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>