<template>
  <a-modal
      title="请选择客户联系人"
      :visible="cusvisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      :closable="true"
      okText="关闭"
      @cancel="handleCancel"
      width="700px"
     >
       <a-table
        :customRow="tableClick"
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="buscolumns"
        :dataSource="busdataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

       </a-table>
    </a-modal>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { Person } from "@/apis";

  export default {
    // 选择客户联系人
    name: 'SelectPersonoModal',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate
    },
    data () {
      return {
        title:'',
        width:800,
        disableSubmit: false,
        cusvisible:false,
        busdataSource:[],
        columns:[],
        dataSource:[],
        buscolumns:[
          {
            title:'联系人名称',
            align:"center",
            dataIndex: 'name',
            fixed:"left",
            ellipsis: true,
            width:120,

          },
          {
            title:'电话',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'mobile'
          },
          {
            title:'职务',
            align:"center",
            dataIndex: 'post',
            ellipsis: true,
            width:100,
          },
           {
            title:'是否为关键决策人',
            align:"center",
            ellipsis: true,
            width:120,
            dataIndex: 'isLeader'
          },
          {
            title:'创建时间',
            align:"center",
            dataIndex: 'createTime'
          },
        ],
        confirmLoading: false,
        customerId:null,
        loadflag:false
      }
    },
    created() {
       
    },
    methods: {
      show(id){
       this.busdataSource=[]
       this.customerId = id
       this.cusvisible = true
       this.loadflag = false
       this.findPersonByCustomerId(1)
      },
      loadData(arg) {
        if(this.loadflag){
           this.findPersonByCustomerId()
         }
      },
      findPersonByCustomerId(arg) {
        //加载数据 若传入参数1则加载第一页的内容
        if (arg === 1) {
          this.ipagination.current = 1;
        }
        var params = this.getQueryParams();//查询条件
        params.customerId = this.customerId
        this.loading = true;
        Person.findPersonByCustomerId(params).then((res)=>{
            if(res.code == 200){
              this.busdataSource = res.data || [];
              this.ipagination.total = res.total || 0;
            }else{
              this.$message.warning(res.message);
            }
             this.loading = false;
             this.loadflag = true
        })
    },
      tableClick(record, index){
        return {
            on: {
               click: () => {
                 let data = {
                    id:record.id,
                    name:record.name
                 }
                this.$emit('ok',data);
                this.cusvisible = false
               }
            }
        }
      },
      handleOk(){
        this.cusvisible = false
      },
      handleCancel(){
         this.cusvisible = false
      },
    }
  }
</script>