<template>
  <a-drawer
    :title="title"
    :maskClosable="true"
    :width="drawerWidth"
    placement="right"
    :closable="true"
    @close="handleCancel"
    :visible="visible"
    style="height: 100%; overflow: auto; padding-bottom: 53px"
  >
    <template slot="title">
      <div style="width: 100%">
        <span>{{ title }}</span>
        <span
          style="
            display: inline-block;
            width: calc(100% - 51px);
            padding-right: 10px;
            text-align: right;
          "
        >
          <a-button
            @click="toggleScreen"
            icon="appstore"
            style="height: 20px; width: 20px; border: 0px"
          ></a-button>
        </span>
      </div>
    </template>

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="用户账号"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入用户账号"
            v-decorator.trim="['username', validatorRules.username]"
            :readOnly="!!model.id"
          />
        </a-form-item>

        <template v-if="!model.id">
          <a-form-item
            label="登陆密码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              type="password"
              placeholder="请输入登陆密码"
              v-decorator.trim="['password', validatorRules.password]"
            />
          </a-form-item>

          <a-form-item
            label="确认密码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              type="password"
              @blur="handleConfirmBlur"
              placeholder="请重新输入登陆密码"
              v-decorator.trim="[
                'confirmpassword',
                validatorRules.confirmpassword,
              ]"
            />
          </a-form-item>
        </template>

        <a-form-item
          label="用户姓名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入用户姓名"
            v-decorator.trim="['realname', validatorRules.realname]"
          />
        </a-form-item>

        <a-form-item
          label="角色分配"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-select
            roleId
            style="width: 100%"
            placeholder="请选择用户角色"
            optionFilterProp="children"
            v-decorator="['roleId', validatorRules.roleId]"
            :getPopupContainer="(target) => target.parentNode"
            :options="option.roleOpt"
          >
          </a-select>
        </a-form-item>
        <a-form-item
          label="所属公司"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-select
            style="width: 100%"
            placeholder="请选择所属公司"
            optionFilterProp="children"
            v-decorator="['departId', validatorRules.departId]"
            :getPopupContainer="(target) => target.parentNode"
            :options="option.departOpt"
          >
          </a-select>
        </a-form-item>
        <a-form-item
          label="手机号码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入手机号码"
            v-decorator.trim="['telphone', validatorRules.phone]"
          />
        </a-form-item>
      </a-form>
    </a-spin>

    <div class="drawer-bootom-button" v-show="!disableSubmit">
      <a-popconfirm
        title="确定放弃编辑？"
        @confirm="handleCancel"
        okText="确定"
        cancelText="取消"
      >
        <a-button style="margin-right: 0.8rem">取消</a-button>
      </a-popconfirm>
      <a-button @click="handleSubmit" type="primary" :loading="confirmLoading"
        >提交</a-button
      >
    </div>
  </a-drawer>
</template>

<script>
import pick from "lodash.pick";
import moment from "moment";
import Vue from "vue";
// 引入搜索部门弹出框的组件
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { addUser, editUser, queryRoleList, queryDepartList } from "@/api/api";
import { disabledAuthFilter } from "@/utils/authFilter";

export default {
  name: "UserModal",
  components: {},
  data() {
    return {
      modalWidth: 800,
      drawerWidth: 700,
      modaltoggleFlag: true,
      confirmDirty: false,
      disableSubmit: false,
      dateFormat: "YYYY-MM-DD",
      validatorRules: {
        username: {
          rules: [
            {
              required: true,
              message: "请输入用户账号!",
            },
          ],
        },
        password: {
          rules: [
            {
              required: true,
              message: "请输入密码！",
            },
            // {
            //   validator: this.validateToNextPassword,
            // },
          ],
        },
        confirmpassword: {
          rules: [
            {
              required: true,
              message: "请重新输入登陆密码!",
            },
            {
              validator: this.compareToFirstPassword,
            },
          ],
        },
        realname: { rules: [{ required: true, message: "请输入用户名称!" }] },
        roleId: { rules: [{ required: true, message: "请选择用户角色!" }] },
        departId: {
          rules: [{ required: true, message: "请选择用户所属公司!" }],
        },
        phone: {
          rules: [
            { required: true, message: "请输入用户手机!" },
            { validator: this.validatePhone },
          ],
        },
      },
      title: "操作",
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      option: {
        departOpt: [],
        roleOpt: [],
      },
    };
  },
  created() {
    this.getOpt();
  },

  methods: {
    getOpt() {
      this.initTenantList();
      this.initialRoleList();
    },
    initTenantList() {
      queryDepartList().then((res) => {
        const { code, data } = res;
        if (code == "200") {
          this.option.departOpt = data.map((item) => {
            return Object.assign(
              {
                label: item.departName,
                value: item.id,
              },
              item
            );
          });
        } else {
          this.$message.warning("获取公司下拉失败!");
        }
        console.log(res);
      });
    },
    initialRoleList() {
      queryRoleList().then((res) => {
        const { code, data } = res;
        if (code == "200") {
          this.option.roleOpt = data.map((item) => {
            return Object.assign(
              {
                label: item.roleName,
                value: item.id,
              },
              item
            );
          });
        } else {
          this.$message.warning("获取角色下拉失败!");
        }
      });
    },
    //窗口最大化切换
    toggleScreen() {
      if (this.modaltoggleFlag) {
        this.modalWidth = window.innerWidth;
      } else {
        this.modalWidth = 800;
      }
      this.modaltoggleFlag = !this.modaltoggleFlag;
    },

    refresh() {
      this.departId = [];
    },
    add() {
      this.refresh();
      this.edit({});
    },
    edit(record) {
      this.resetScreenSize(); // 调用此方法,根据屏幕宽度自适应调整抽屉的宽度
      let that = this;
      that.form.resetFields();
      that.visible = true;
      that.model = Object.assign({}, record);
      that.$nextTick(() => {
        that.form.setFieldsValue(
          pick(
            this.model,
            "username",
            "realname",
            "telphone",
            "roleId",
            "departId"
          )
        );
      });
    },
    //

    close() {
      this.$emit("close");
      this.visible = false;
      this.disableSubmit = false;
    },
    moment,
    handleSubmit() {
      console.log("handleSubmit");
      const that = this;
      // 触发表单验证
      let flag = false;
      this.form.validateFields({ force: true }, (err) => {
        if (err) flag = true;
      });
      if (flag) return;
      const values = this.form.getFieldsValue();
      that.confirmLoading = true;
      let formData = Object.assign({}, this.model, values);
      let obj;
      if (!this.model.id) {
        obj = addUser(formData);
      } else {
        obj = editUser(formData);
      }
      obj
        .then((res) => {
          const {code,message} = res
          if (code == 200) {
            that.$message.success(message);
            that.$emit("ok");
            that.close();
          } else {
            that.$message.warning(message);
          }
        })
        .finally(() => {
          that.confirmLoading = false;
          
        });
    },
    handleCancel() {
      this.close();
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      const confirmpassword = form.getFieldValue("confirmpassword");

      if (value && confirmpassword && value !== confirmpassword) {
        callback("两次输入的密码不一样！");
      }
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("两次输入的密码不一样！");
      } else {
        callback();
      }
    },
    validatePhone(rule, value, callback) {
      if (!value) {
        callback();
      } else {
        //update-begin--Author:kangxiaolin  Date:20190826 for：[05] 手机号不支持199号码段--------------------
        if (!new RegExp(/^1[3|4|5|7|8|9][0-9]\d{8}$/).test(value)) {
          callback("请输入正确格式的手机号码!");
        }
      }
    },
    validateEmail(rule, value, callback) {
      if (!value) {
        callback();
      } else {
        if (
          !new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          callback("请输入正确格式的邮箱!");
        }
      }
    },

    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    // 根据屏幕变化,设置抽屉尺寸
    resetScreenSize() {
      let screenWidth = document.body.clientWidth;
      if (screenWidth < 500) {
        this.drawerWidth = screenWidth;
      } else {
        this.drawerWidth = 700;
      }
    },
  },
};
</script>

<style scoped>
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}
.ant-upload-select-picture-card i {
  font-size: 49px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-bootom-button {
  position: absolute;
  bottom: -8px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}
</style>