<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="客户名称" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name">
              <a-input v-model="model.name" placeholder="请输入客户名称"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="客户来源" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="source">
              <j-dict-select-tag  v-model="model.source" placeholder="请选择客户来源" dictCode="leader_source"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>  
          <a-col :span="12">
            <a-form-model-item label="客户行业" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="industry">
               <j-dict-select-tag  v-model="model.industry" placeholder="请选择客户行业" dictCode="customer_industry"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="客户级别" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="level">
              <a-select v-model="model.level" placeholder="请选择">
                <a-select-option value="A(重点客户)">A(重点客户)</a-select-option>
                <a-select-option value="B(普通客户)">B(普通客户)</a-select-option>
                <a-select-option value="C(非优先用户)">C(非优先用户)</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>    
           <a-col :span="12">
            <a-form-model-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="mobile">
              <a-input v-model="model.mobile" placeholder="请输入手机号"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="座机电话" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="telephone">
              <a-input v-model="model.telephone" placeholder="请输入座机电话"  ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>    
          <a-col :span="12">
            <a-form-model-item label="网址" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="website">
              <a-input v-model="model.website" placeholder="请输入网址"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="email">
              <a-input v-model="model.email" placeholder="请输入邮箱"  ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>    
          <a-col :span="12">
            <a-form-model-item label="下次联系时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="nextTime">
              <j-date placeholder="请选择下次联系时间" v-model="model.nextTime" :dateFormat="dateFormat"  style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="详细地址" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="detailAddress">
              <a-input v-model="model.detailAddress" placeholder="请输入详细地址"  ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>    
           <a-col :span="24">
            <a-form-model-item label="备注" prop="remark">
              <a-textarea v-model="model.remark" rows="3" placeholder="请输入备注" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
  </a-spin>
</template>

<script>

  import { httpAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'
  import JFormContainer from '@/components/jeecg/JFormContainer'
  import JDate from '@/components/jeecg/JDate.vue'
  import JDictSelectTag from '@/components/dict/JDictSelectTag'

  export default {
    name: 'KhcrmCustomerForm',
    components: {
      JFormContainer,
      JDate,
      JDictSelectTag
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false,
       
      }
    },
    data () {
      return {
        model:{
         },
        dateFormat:"YYYY-MM-DD HH:mm",
        labelCol: {
          xs: { span: 26 },
          sm: { span: 7 },
        },
        wrapperCol: {
          xs: { span: 22 },
          sm: { span: 14 },
        },
        confirmLoading: false,
        validatorRules: {
           name: [
              { required: true, message: '请输入客户名称!'},
           ],
           source: [
              { required: false, message: '请选择客户来源!'},
           ],
           industry: [
              { required: false, message: '请选择客户行业!'},
           ],
           level: [
              { required: false, message: '请选择客户级别!'},
           ],
           mobile: [
              { required: true, message: '请输入手机号!'},
           ],
           detailAddress: [
              { required: false, message: '请输入详细地址!'},
           ],
      
        },
        url: {
          add: "/khcrm/web/khcrmCustomer/add",
          edit: "/khcrm/web/khcrmCustomer/edit",
          queryById: "/khcrm/web/khcrmCustomer/queryById"
        }
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        this.model = Object.assign({}, record);
        this.visible = true;
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            httpAction(httpurl,this.model,method).then((res)=>{
              if(res.code == 200){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },
    }
  }
</script>