import { getAction, postAction, uploadAction, formAction2 } from '@/api/manage'
const url = {
    // 保存流程步骤
    makeStepLink: '/khcrm/web/process/makeStepLink',
    
    // 添加主流程信息
    addMainProcess: '/khcrm/web/process/addMainProcess',

    //根据流程id查询环节以及控件信息
    findStepInfoByProcessId: '/khcrm/web/process/findStepInfoByProcessId',
    findCustomerProcessPageInfo:'/khcrm/web/process/findCustomerProcessPageInfo',
    getUsRoleInfo :  '/khcrm/web/process/getUsRoleInfo',

    // 提交数据
    setpSubmitData :  '/khcrm/web/process/setpSubmitData',
    
    //根据流程id获取字段
    getFiledColByProcessId: '/khcrm/web/process/getFiledColByProcessId',

    // 获取流程页面的数据
    getProcessApprovalData: '/khcrm/web/process/getProcessApprovalData',
 
     // 切换发布未发布
    changeProcessIsUse:'/khcrm/web/process/changeProcessIsUse',

    getUserList:'/khcrm/web/process/getUserList',

    // 审批
    approvalStepData:'/khcrm/web/process/approvalStepData',

    // 审批日志
    findProcessLogByDataId:'/khcrm/web/process/findProcessLogByDataId',

    // 得所有字段
    getAllFiledCol:'/khcrm/web/process/getAllFiledCol',

    // 获取用户选择得页面字段
    getUserSelectFiledCol:'/khcrm/web/process/getUserSelectFiledCol',

    // 保存用户选择得页面字段
    saveUserSelectFiled:'/khcrm/web/process/saveUserSelectFiled',

    //调整顺序
    changeUserSelectSort:'/khcrm/web/process/changeUserSelectSort',


    optPageIsUse:'/khcrm/web/process/optPageIsUse',

    saveOrUpdatePage:'/khcrm/web/process/saveOrUpdatePage',

    updateProcessStep:'/khcrm/web/process/updateProcessStep',

    changeStepUse:'/khcrm/web/process/changeStepUse',

    getProccessStepInfo:'/khcrm/web/process/getProccessStepInfo',


}
export default {
    makeStepLink(params) {
        return postAction(url.makeStepLink, params);
    } ,
    
    addMainProcess(params) {
        return postAction(url.addMainProcess, params);
    } ,

    findStepInfoByProcessId(params) {
        return postAction(url.findStepInfoByProcessId, params);
    } ,
    findCustomerProcessPageInfo(params) {
        return postAction(url.findCustomerProcessPageInfo, params);
    } ,
    

    changeProcessIsUse(params) {
        return postAction(url.changeProcessIsUse, params);
    } ,
    
    setpSubmitData(params) {
        return uploadAction(url.setpSubmitData, params);
    } 
    ,
    getUsRoleInfo(params) {
        return getAction(url.getUsRoleInfo, params);
    } ,
    getUserList(params) {
        return getAction(url.getUserList, params);
    } ,
    getFiledColByProcessId(params) {
        return getAction(url.getFiledColByProcessId, params);
    } ,
    getProcessApprovalData(params) {
        return postAction(url.getProcessApprovalData, params);
    } ,

    approvalStepData(params) {
        return postAction(url.approvalStepData, params);
    } ,
    findProcessLogByDataId(params) {
        return getAction(url.findProcessLogByDataId, params);
    } ,
    getUserSelectFiledCol(params) {
        return getAction(url.getUserSelectFiledCol, params);
    } ,
    getAllFiledCol(params) {
        return getAction(url.getAllFiledCol, params);
    } ,
    saveUserSelectFiled(params) {
        return postAction(url.saveUserSelectFiled, params);
    } ,
    changeUserSelectSort(params) {
        return postAction(url.changeUserSelectSort, params);
    } ,
    optPageIsUse(params) {
        return postAction(url.optPageIsUse, params);
    } ,
    saveOrUpdatePage(params) {
        return postAction(url.saveOrUpdatePage, params);
    } ,
    updateProcessStep(params) {
        return postAction(url.updateProcessStep, params);
    } ,
    changeStepUse(params) {
        return postAction(url.changeStepUse, params);
    } ,
    getProccessStepInfo(params) {
        return getAction(url.getProccessStepInfo, params);
    } 
    
}
