<template>
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                  <a-form-item label="优惠券类型">
                    <a-radio-group 
                        v-decorator="['rangeType', { rules: [{ required: true, message: '请选择优惠券类型!' }] }]"
                        @change="clickRangeType"
                      >
                        <a-radio :value="1">
                          全平台通用券
                        </a-radio>
                        <a-radio :value="2">
                          商品分组券
                        </a-radio>
                        <a-radio :value="3">
                          指定商品券
                        </a-radio>
                      </a-radio-group>                
                  </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="满(元)">
                   <a-input-number :max="999999999" :min="0" :precision="2"
                      v-decorator="['fullMoney', { rules: [{ required: true, message: '请输入满金额!' }] }]"
                        placeholder="请输入满金额" style="width: 30%" />
                         <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />注意: 当输入金额为 0 时候,就是无门槛券
                    </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="减(元)">
                   <a-input-number :max="999999999" :min="0" :precision="2"
                      v-decorator="['reduceMoney', { rules: [{ required: true, message: '请输入减金额!' }]}]"
                        placeholder="请输入减金额" style="width: 30%" />
                    </a-form-item>
              </a-col>
            </a-row>
            <a-row v-if="rangeType == 2">
              <a-col :span="24">
                <a-form-item label="商品分组">
                   <a-select
                      show-search
                      v-decorator="['relationId', { rules: [{ required: true, message: '请选择商品分组!' }]}]"
                      placeholder="请输入商品分组名称进行查询"
                      style="width: 100%"
                      :filter-option="false"
                      :not-found-content="fetching ? undefined : null"
                      @search="fetchShopGroup"
                    >
                      <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                      <a-select-option v-for="d in shopGroupData" :key="d.id">
                        {{ d.groupNameZh }}
                      </a-select-option>
                    </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row v-else-if="rangeType == 3">
              <a-col :span="24">
                <a-form-item label="商品">
                  <a-select
                      show-search
                      v-decorator="['relationId', { rules: [{ required: true, message: '请选择商品!' }]}]"
                      placeholder="请输入商品名称进行查询"
                      style="width: 100%"
                      :filter-option="false"
                      :not-found-content="fetching ? undefined : null"
                      @search="fetchShopInfo"
                    >
                      <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                      <a-select-option v-for="d in shopData" :key="d.id">
                        {{ d.goodsNameZh }}
                      </a-select-option>
                    </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="用户可领取次数">
                   <a-input-number :max="999999" :min="1" :precision="0"
                      v-decorator="['canUse', { rules: [{ required: true, message: '请输入用户可领取次数!' }] , initialValue:'1'}]"
                        placeholder="请输入用户可领取次数" style="width: 30%" />
                    </a-form-item>
              </a-col>
            </a-row>
          </a-form>
    </a-modal>  

</template>

<script>
  import debounce from 'lodash/debounce';
  import { postAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/commonUtil'
  export default {
    name: 'AddGoodsCouponModal',
    components: {
    },
    data () {
      this.fetchShopGroup = debounce(this.fetchShopGroup, 800);
      this.fetchShopInfo = debounce(this.fetchShopInfo, 800);
      return {
        title:'新增优惠券',
        width:800,
        visible: false,
        openLanguage:false,
        confirmLoading: false,
        rangeType:1,
        fetching: false,
        shopGroupData:[],
        shopData:[],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          createShopCoupon: "/shop/web/shopCoupon/createShopCoupon",
          getShopGroupInfoByGroupName: "/shop/web/common/getShopGroupInfoByGroupName",
          getShopInfoByName: "/shop/web/common/getShopInfoByName",
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      add () {
        this.visible = true;
       
      },
      edit (record) {
        
      },
      submitForm () {
         this.form.validateFields((err, values) => {
          if (!err) {
              let that = this
              this.confirmLoading = true
              postAction(this.url.createShopCoupon,values).then((res)=>{
                if(res.code == 200){
                  that.$message.success("创建优惠券成功");
                  that.handleCancel()
                  that.$emit('ok');
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                this.confirmLoading = false
              })
          }})
      },
      // 切换优惠券类型
      clickRangeType(e){
        let values = e.target.value;
        this.rangeType = values
      },
      // 查询商品分组
      fetchShopGroup(e){
        if(e == '' || e == null){
           return
        }
        let that = this
        let param = {
          name : e
        }
        this.fetching = true
        getAction(this.url.getShopGroupInfoByGroupName,param).then((res)=>{
          if(res.code == 200){
             if(res.data == null || res.data.length <= 0){
                that.$message.warn("未查询到商品分组数据");
                return
             }
             this.shopGroupData = res.data
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          this.fetching = false
        })
      },
       // 查询商品
      fetchShopInfo(e){
        if(e == '' || e == null){
           return
        }
        let that = this
        let param = {
          name : e
        }
        this.fetching = true
        getAction(this.url.getShopInfoByName,param).then((res)=>{
          if(res.code == 200){
             if(res.data == null || res.data.length <= 0){
                that.$message.warn("未查询到商品数据");
                return
             }
             this.shopData = res.data
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          this.fetching = false
        })
      },
      handleCancel () {
        this.visible = false;
      }
    }
  }
</script>