<template>
   <a-drawer
      :title="'【' + record.name + '】合同尾款详情'"
      placement="right"
      :closable="true"
      :visible="visible"
      width="60%"
      @close="onClose"
    >

       <!-- 操作按钮区域 -->
      <div class="table-operator">
        <a-button @click="handleAdd" type="primary" icon="plus">新增回款</a-button>
      </div>
       <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        class="j-table-force-nowrap">
            <span slot="action" slot-scope="text, record">
              <a @click="handleEdit(record)">编辑</a>
            </span>
            <template slot="fileUrl" slot-scope="text, record">
                <img v-if="text" :src="text" class="money-img" @click="enlargeImg(text)" />
                <span v-else>-</span>
            </template>
            
       </a-table>

        <a-modal
          title="放大"
          :visible="enlargevisible"
          width="30%"
          @cancel="enlargeCancel"
          @ok="enlargeCancel"
        >
          <img :src="imgUrl" style="height:100%;width:100%" />
        </a-modal>
        <khcrm-return-money-modal ref="modalForm" @ok="modalFormOk"></khcrm-return-money-modal>
    </a-drawer>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { Contract } from "@/apis";
  import KhcrmReturnMoneyModal from '../../returnmoney/modules/KhcrmReturnMoneyModal.vue';
 

  export default {
    // 合同详情
    name: 'SelectContractModal',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate,
      KhcrmReturnMoneyModal
    },
    data () {
      return {
        title:'',
        width:800,
        visible:false,
        enlargevisible:false,
        columns:[
          {
            title:'回款日期',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'returnTime'
          },
          {
            title:'合同金额(元)',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'totalMoney'
          },
          {
            title:'回款金额(元)',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'money'
          },
          {
            title:'回款比例',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'moneyRate'
          },
          {
            title:'回款方式',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'returnType'
          },
           {
            title:'回款凭证',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'fileUrl',
            scopedSlots: { customRender: 'fileUrl' }
          },
          {
            title:'回款编号',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'number'
          },
          {
            title:'备注',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'remark'
          },
          {
            title:'负责人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'ownerName'
          },
          {
            title:'创建人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'createName'
          },
          {
            title:'创建时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'createTime'
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            width:100,
            scopedSlots: { customRender: 'action' }
          }
        ],
        dataSource:[],
        loading: false,
        record:{},
        imgUrl:''
      }
    },
    created() {
       
    },
    methods: {
      show(record){
        this.record = record
        this.getReturnMoneyByContract();
        this.visible = true
      },
      handleAdd(){
        this.$refs.modalForm.title = "新增回款";
        let param = {
           customerId: this.record.customerId,
           customerName: this.record.customerName,
           contractId:this.record.id,
           contractName:this.record.name
        }
        this.$refs.modalForm.edit(param);
      },
      handleEdit(r){
        this.$refs.modalForm.title = "编辑回款";
        r.customerId = this.record.customerId,
        r.customerName = this.record.customerName,
        r.contractId = this.record.id,
        r.contractName = this.record.name
        this.$refs.modalForm.edit(r);
      },
      loadData(arg) {
         
      },
      // 根据合同查询尾款信息
      getReturnMoneyByContract(arg) {
        this.loading = true;
        let params = {
           id : this.record.id
        }
        Contract.getReturnMoneyByContract(params).then((res)=>{
            if(res.code == 200){
               this.dataSource = res.data
            }else{
              this.$message.warning(res.message);
            }
             this.loading = false;
        })
    },
      enlargeImg(url){
         this.imgUrl = url;
         this.enlargevisible = true
      },
      enlargeCancel(){
        this.enlargevisible = false
      },
      handleOk(){
        this.cusvisible = false
      },
      handleCancel(){
         this.cusvisible = false
      },
      onClose(){
        this.visible = false
      },
      modalFormOk(){
        this.getReturnMoneyByContract();
      }
    }
  }
</script>
<style scoped>
  .money-img{
    height: 30px;
    width: 100px;
  }
</style>