<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="8" :sm="12">
            <a-form-item label="主文件名称">
              <a-input
                placeholder="请输入主文件名称模糊查询"
                v-model="queryParam.mainName"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="12">
            <a-form-item label="辅文件名称">
              <a-input
                placeholder="请输入辅文件名称模糊查询"
                v-model="queryParam.auxName"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>  
          <a-col :md="12" :sm="10">
              <a-form-item label="创建时间">
                <a-row>
                  <a-col :md="11" :sm="10">
                    <j-date placeholder="请选择开始日期" v-model="queryParam.startTime" :dateFormat="dateFormat"  style="width: 100%" />
                  </a-col>
                    <a-col :md="2" :sm="10" style="text-align: center;">
                      ~
                    </a-col>
                  <a-col :md="11" :sm="10">
                    <j-date placeholder="请选择结束日期" v-model="queryParam.endTime" :dateFormat="dateFormat"  style="width: 100%" />
                  </a-col>
                </a-row>
              </a-form-item>
          </a-col>
          <a-col :md="4" :sm="8">
            <span
              style="float: left; overflow: hidden;margin-left:10px"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
              <a-button
                type="primary"
                @click="searchReset"
                icon="reload"
                style="margin-left: 8px"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">合并Excel</a-button>
      <a-button style="margin-left:20px" @click="excelRandom" type="primary" icon="download">excel随机抽取数据</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
         <span style="color:red">使用提示:  数据会以‘主文件’为主,主文件存在的数据不会删除任何，例如: 主文件的列与辅助文件相同,会拿去主文件数据,主文件数据多于辅助文件,则最终结果数据为多,如果主文件少于辅助文件数据,则数据会少[一切以主文件为基准]
         </span>
      </div>

      <a-table
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">
 
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px;font-style: italic;">无文件</span>
          <a-button
            v-else
            :ghost="true"
            type="primary"
            icon="download"
            size="small"
            @click="downloadFile(text)">
            下载
          </a-button>
        </template>

        <span slot="genUrl" slot-scope="text, record">
          <a @click="openDownFile(text)">下载合并后的文件</a>
        </span>
        <span slot="mainUrl" slot-scope="text, record">
          <a @click="openDownFile(text)">下载原主文件</a>
        </span>
        <span slot="auxUrl" slot-scope="text, record">
          <a @click="openDownFile(text)">下载原辅文件</a>
        </span>

        <span slot="action" slot-scope="text, record">
          <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
          </a-popconfirm>
        </span>

      </a-table>
    </div>

    <aux-excel-modal ref="modalForm" @ok="modalFormOk"></aux-excel-modal>
    <excel-random-modal ref="excelRandomForm" />
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import AuxExcelModal from './modules/AuxExcelModal'
  import ExcelRandomModal from './modules/ExcelRandomModal'
  import JDate from '@/components/jeecg/JDate.vue'

  export default {
    name: 'AuxExcelList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      AuxExcelModal,
      JDate,
      ExcelRandomModal
    },
    data () {
      return {
        description: 'aux_excel管理页面',
        // 表头
        columns: [
          {
            title: '序号',
            dataIndex: '',
            key:'rowIndex',
            width:80,
            align:"center",
            customRender:function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title:'唯一标识名称',
            align:"center",
            dataIndex: 'idenName',
            ellipsis: true,
            width:147,
          },
          {
            title:'要导出的列名',
            align:"center",
            dataIndex: 'exportFiledName',
            ellipsis: true,
            width:250,
          },
          {
            title:'合并生成后文件',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'genUrl',
            scopedSlots: { customRender: 'genUrl' }
          },
          {
            title:'主文件名称',
            align:"center",
            ellipsis: true,
            width:500,
            dataIndex: 'mainFileName'
          },
          {
            title:'辅文件名称',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'auxFileName'
          },
          {
            title:'主文件保存',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'mainUrl',
            scopedSlots: { customRender: 'mainUrl' }

          },
          {
            title:'辅文件保存',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'auxUrl',
             scopedSlots: { customRender: 'auxUrl' }

          },
          {
            title:'创建时间',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'createTime'
          } , {
            title: '操作',
            dataIndex: 'action',
            width:100,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/khcrm/web/auxExcel/list",
          delete: "/khcrm/web/auxExcel/delete",
          importExcelUrl: "/khcrm/web/auxExcel/importExcel",
        },
        dictOptions:{},
        superFieldList:[],
        dateFormat:"YYYY-MM-DD",
      }
    },
    created() {
     },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      openDownFile(url){
        console.log(url)
          window.open(url)
      },
      excelRandom(){
        this.$refs.excelRandomForm.show()
      }  
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>