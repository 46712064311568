<template>
    <div>
      <a-drawer
        :title="'流程环节'"
        placement="right"
        :closable="true"
        width="60%"
        :visible="visible"
        @close="onClose"
      >
      <a-spin :spinning="spinning">

          <a-steps labelPlacement="vertical" :current="current" style="margin-top:-20px;margin-bottom:20px">
             <a-step v-for="item in stepData" :key="item.id" :title="item.name" >
               <span slot="description">
                  <div style="font-size:12px"><strong>审批角色:</strong> 
                    <span v-if="item.isApproval != 1 " style="color:red;margin-left:10px">不需要审批</span>
                    <span v-else style="margin-left:10px">{{item.roleName}}</span>
                  </div>
               </span>
             </a-step>
          </a-steps>
          <a-divider />
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
             
             <a-row v-if="pageData != null && pageData.length > 0">
                <template  v-for="(item, index) in pageData">
                  <a-col :span="12" :key="index"  v-if="item.type == 'text'">
                    <a-form-item :label="item.title">
                        <a-input 
                          v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请输入'+item.title+'!' }] }]"
                          :placeholder="'请输入' + item.title"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" :key="index"  v-if="item.type == 'number'">
                    <a-form-item :label="item.title">
                        <a-input-number 
                          style="width:100%"
                          v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请输入'+item.title+'!' }] }]"
                          :placeholder="'请输入' + item.title"
                          :min="item.isNegative == 0 ? -9999999 : 0" :max="9999999" :precision="item.isDecimal == 0? 2 : 0" 
                        />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" :key="index"  v-if="item.type == 'textarea'">
                    <a-form-item :label="item.title">
                       <a-textarea
                          :rows="4"
                          v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请输入'+item.title+'!' }] }]"
                          :placeholder="'请输入' + item.title"></a-textarea>
                    </a-form-item>
                  </a-col>
                   <a-col :span="12" :key="index"  v-if="item.type == 'time'">
                    <a-form-item :label="item.title">
                       <a-month-picker
                         v-if="item.accurate == 0" 
                         style="width: 100%"
                         :placeholder="'请选择' + item.title" 
                         v-decorator="[item.colField + '_' + item.accurate, { rules: [{ required: item.isMust == 1, message: '请选择'+item.title+'!' }] }]"
                        />
                      <j-date 
                         v-else
                         style="width: 100%"
                         :placeholder="'请选择' + item.title" 
                         v-decorator="[item.colField + '_' + item.accurate, { rules: [{ required: item.isMust == 1, message: '请选择'+item.title+'!' }] }]"
                         :dateFormat="item.accurate == 1 ? dateFormat : timeFormat" 
                          />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" :key="index"  v-if="item.type == 'select'">
                    <a-form-item :label="item.title">
                        <a-select 
                          v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请选择'+item.title+'!' }] }]"
                          :placeholder="'请选择' + item.title"
                          style="width: 100%"
                          :mode="item.multiple == 1? 'default' : 'multiple'"
                        >
                          <template  v-for="(s, i) in item.dataSource.split(',')">
                            <a-select-option :key="i" :value="s">{{s}}</a-select-option>
                          </template>
                        </a-select>  
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" :key="index"  v-if="item.type == 'file'">
                    <a-form-item :label="item.title">
                      <a-upload 
                          v-decorator="[item.colField, { rules: [{ required: item.isMust == 1, message: '请选择'+item.title+'!' }] }]"
                          :placeholder="'请选择' + item.title"
                          style="width: 100%"
                          :file-list="filecol[item.colField] || []" 
                          :multiple="false" 
                          :remove="handleRemove"
                          
                          :before-upload="beforeUpload">
                          <a-button @click="uploadBtn(item.colField)"> <a-icon type="upload" />点击上传{{item.title}}</a-button>
                      </a-upload>
                    </a-form-item>
                  </a-col>
                </template>
             </a-row>
             <a-row v-else>
               <a-form-item>
                无需添加任何信息,上一环节信息复制一份,自动执行下一环节
               </a-form-item>
             </a-row>

              <a-divider />
              <a-form-item label="抄送给">
                  <a-select 
                      v-decorator="['copyUserId', { rules: [{ required: false, message: '请选择要抄送的人!' }] }]"
                      placeholder="请选择要抄送的人"
                      style="width: 100%"
                      mode="multiple"
                    >
                      <template  v-for="(s, i) in copyList">
                        <a-select-option :key="i" :value="s.id">{{s.realname}}</a-select-option>
                      </template>
                </a-select>  
              </a-form-item>
          </a-form>
         
        </a-spin>  
          <div
            :style="{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }"
          >
            <a-button style="marginRight: 8px" @click="onClose">
              关闭
            </a-button>
            <a-button :loading="spinning" type="primary" @click="submitBtn">
              提交
            </a-button>
        </div>
     </a-drawer> 
    </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { MakeLink } from "@/apis";
  import moment from 'moment'

  export default {
    // 开始流程步骤
    name: 'StartProcess',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate
    },
    data () {
      return {
        spinning:false,
        visible:false,
        dateFormat:"YYYY-MM-DD",
        timeFormat:"YYYY-MM-DD HH:mm:ss",
        current:0,
        curStep:[],
        stepData:[],
        pageData:[],
        stepNum:1,
        filecol:{},
        nowfileCol:"",
        dataId:'',
        copyList:[],
        numLimitType:'1',
        processId:'',
        form: this.$form.createForm(this, { name: 'coordinated' }),
      }
    },
    created() {
      this.getUserList()
    },
    methods: {
      loadData(arg) {
        // 加载数据 若传入参数1则加载第一页的内容
        if (arg === 1) {
          this.ipagination.current = 1
        }
        let params = this._getQueryParams() // 查询条件
        console.log(params)
      },
      show(processId,stepNum,dataId){
        console.log(dataId)
        if(dataId){
          this.dataId = dataId
        }else{
          this.dataId = ''
        }

        this.current = (stepNum - 1)
        this.processId = processId
        this.form.resetFields();
        this.filecol = {}
        this.spinning = false
        this.stepNum = stepNum
        this.findCustomerProcessPageInfo()
        
      },
      submitBtn(){
         this.form.validateFields((err, values) => {
          if (!err) {
            console.log(values)
            const formData = new FormData();
            for(let i in values){
               let v = values[i]
               console.log(v)
               if(v){
                  if(v instanceof moment){
                    let l = i.substring(i.lastIndexOf('_'))
                    let va = '';
                    if(l == '_0'){
                       va = v.format('YYYY-MM')
                    }else if(l == '_1'){
                       va = v.format('YYYY-MM-DD')
                    }else if(l == '_2'){
                       va = v.format('YYYY-MM-DD HH:mm:ss')
                    }
                    let pt = i.replace(l,'')
                     formData.append(pt, va); 
                  }else if(Array.isArray(v)){
                     formData.append(i, v.join(",")); 
                  }else if(v.fileList){
                    v.fileList.forEach(file => {
                      formData.append(i, file.originFileObj);
                    });
                  }else {
                      formData.append(i, v); 
                  }
               }
            }
            // this.spinning = true
            formData.append("stepName", this.curStep.name); 
            formData.append("processId", this.curStep.processId); 
            if(this.curStep.roleCode){
               formData.append("roleCode", this.curStep.roleCode); 
            }
            formData.append("approvalStatus", this.curStep.isApproval); 
            formData.append("stepId", this.curStep.id); 
            formData.append("stepNum", this.stepNum); 
            formData.append("id", this.dataId); 
            this.setpSubmitData(formData);

          }
         })
      },
      previousStep(){
        if(this.current <=0){
             return
        }
        this.form.resetFields();
        this.current --;
        this.curStep = this.stepData[this.current]
        this.pageData = this.curStep.pageList
      },
      nextStep(){
        if(this.current >= (this.stepData.length - 1)){
             return
        }
        this.form.resetFields();
        this.current ++;

        this.curStep = this.stepData[this.current]
        this.pageData = this.curStep.pageList
      },
      onClose(){
        this.visible = false
      },
      handleRemove(file) {
        for(let i in this.filecol){
            let f = this.filecol[i]
            const index = f.indexOf(file);
            console.log(index)
            if(index >= 0){
               this.filecol[i] = []
            }
        }
        // const index = this.fileList.indexOf(file);
        // const newFileList = this.fileList.slice();
        // newFileList.splice(index, 1);
        // this.fileList = newFileList;
      },
      beforeUpload(file) {
        this.filecol[this.nowfileCol] = []
        this.filecol[this.nowfileCol].push(file)
        return false;
      },
      uploadBtn(col){
        this.nowfileCol = col
      },
      setpSubmitData(formData){
        this.spinning = true
         MakeLink.setpSubmitData(formData).then((res) => {
            if (res.code ==200) {
                this.refreshTable()
                this.visible = false
            } else {
              this.$message.warning(res.message);
            }
            this.spinning = false
         }) 
      },
      getUserList(){
        MakeLink.getUserList(null).then((res) => {
            if (res.code ==200) {
               this.copyList = res.data
            } else {
              this.$message.warning(res.message);
            }
            this.spinning = false
         }) 
      },
      //查询信息
      findCustomerProcessPageInfo(){
        let param = {
            id : this.processId
        }
        MakeLink.findCustomerProcessPageInfo(param).then((res) => {
            if (res.code ==200) {
              if(res.data !=null && res.data.length > 0){
                 if(this.current >= res.data.length){
                   this.$message.warning("当前流程已完成！");
                   return
                 }
                 this.stepData = res.data
                 this.curStep = res.data[this.current]
                 this.pageData = this.curStep.pageList
                 for(let p in this.pageData){
                    let d = this.pageData[p]
                    if(d.type == 'file'){
                          this.numLimitType = d.multiple
                    }
                 }
                 this.visible = true
              }
            } else {
              this.$message.warning(res.message);
            }
        }) 
      },
      refreshTable(){
         this.$emit('ok');
      }
       
    }
  }
</script>
<style lang="less" scoped>
  @import '~@assets/less/common.less';
  .seg-title-all{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .copy-class{
    display: flex;
    flex-direction: row;
  }
  .submit-btn{
    display: flex;
    flex-direction:row-reverse;
    justify-content: center;
  }
  .input-class{
    width:75%;
    margin-left:10px
  }

  .seg-line{
    background-color: @primary-color;
    width: 4px;
    height: 20px;
  }
  .seg-font{
    margin-left: 10px;
    font-size: 16px;
    font-weight: 800;
    
  }
  .del-a-btn{
    margin-left: 20px;
  }
  .seg-row{
    margin-left:20px;
    margin-top:20px
  }
  .seg-col{
    display: flex;
    flex-direction: row;
    
  }
  .seg-col-div{
    width: 100px;
    text-align: right;
    margin-right: 20px;
    /* background-color: red; */
  }

</style>
<style lang="less" >
  .ant-steps-label-vertical .ant-steps-item-content {
    width: 150px;
  }
  .ant-steps-label-vertical .ant-steps-item-icon{
    margin-left: 60px;
  }
  .ant-steps-label-vertical .ant-steps-item-tail{
    margin-left: 75px;
  }
</style>