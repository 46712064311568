import { render, staticRenderFns } from "./MiniArea.vue?vue&type=template&id=764379a1&scoped=true&"
var script = {}
import style0 from "./MiniArea.vue?vue&type=style&index=0&id=764379a1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764379a1",
  null
  
)

export default component.exports