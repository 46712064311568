var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-chart',{attrs:{"forceFit":true,"height":_vm.height,"width":_vm.width,"data":_vm.data,"scale":_vm.scale,"padding":0}},[_c('v-tooltip'),_c('v-interval',{attrs:{"shape":['liquid-fill-gauge'],"position":"transfer*value","color":"","v-style":{
        lineWidth: 8,
        opacity: 0.75
      },"tooltip":[
        'transfer*value',
        function (transfer, value) {
          return {
            name: transfer,
            value: value,
          };
        } ]}}),_vm._l((_vm.data),function(row,index){return _c('v-guide',{key:index,attrs:{"type":"text","top":true,"position":{
        gender: row.transfer,
        value: 45
      },"content":row.value + '%',"v-style":{
        fontSize: 100,
        textAlign: 'center',
        opacity: 0.75,
      }}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }