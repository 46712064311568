<template>
    <a-drawer
      title="商品详情"
      :width="1200"
      placement="right"
      :closable="true"
      :visible="visible"
      @close="handleCancel"
    >
      <div>
         <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="商品轮播图" >
                    <img v-for="item in record.rotatImgList" :key="item" :src="record.readPath + item" width="200px" height="200px" />
                    <a @click="showModalUpload('商品轮播图','rotatImgList',record.rotatImgList)" class="update-shop-edit"> <a-icon type="edit" /></a>
                </a-form-item> 
              </a-col>
            </a-row>
             <a-row>
              <a-col :span="24">
                <a-form-item label="商品是否上架" >
                  <template v-if="record.status == 1">
                      <a-tag color="green">
                          已上架
                      </a-tag>
                      <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
                           商品上架后 【商品原价】【商品现价】【初始库存量】 无法修改
                  </template>
                   <template v-else>
                      <a-tag color="red" >
                          未上架
                      </a-tag>
                  </template>
                </a-form-item> 
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="商品id" >
                    {{record.id}}
                </a-form-item> 
              </a-col>
              <a-col :span="12">
                <a-form-item label="排序"> 
                   {{record.sort}} 
                    <a @click="showModalSort('排序','sort',record.sort)"
                        class="update-shop-edit"> <a-icon type="edit" /></a> 
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                  <a-form-item label="商品分组" >
                      {{record.groupNameZh}}  
                      <a @click="showModalGroup('商品分组','groupNameZh',record.goodsGroupId)" class="update-shop-edit"> <a-icon type="edit" /></a>
                  </a-form-item> 
                </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="商品名称" >
                     {{record.goodsNameZh}} 
                      <a @click="showModalInput('商品名称','goodsNameZh',record.goodsNameZh)"
                        class="update-shop-edit"> <a-icon type="edit" /></a>
                </a-form-item> 
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="服务描述" >
                    {{record.serviceDescZh}} 
                     <a @click="showModalInput('服务描述','serviceDescZh',record.serviceDescZh)"
                        class="update-shop-edit"> <a-icon type="edit" /></a>
                </a-form-item> 
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="商品原价" >
                    ￥ {{record.oldMoney}} 
                    <a @click="showModalMoney('商品原价','oldMoney',record.oldMoney)" v-if="record.status == 0"
                        class="update-shop-edit"> <a-icon type="edit" /></a> 
                </a-form-item> 
              </a-col>
              <a-col :span="12">
                <a-form-item label="商品现价"> 
                  <template v-if="!record.haveSpecs">
                       ￥ {{record.realMoney}}
                     <a @click="showModalMoney('商品现价','realMoney',record.realMoney)" v-if="record.status == 0"
                        class="update-shop-edit"> <a-icon type="edit" /></a> 
                  </template>
                  <template v-else>
                      见规格
                  </template>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="初始库存量" >
                  <template v-if="!record.haveSpecs">
                      {{record.stock}} 件 
                      <a @click="showModalStock('初始库存量','stock',record.stock)" v-if="record.status == 0"
                        class="update-shop-edit"> <a-icon type="edit" /></a> 
                  </template>
                  <template v-else>
                      见规格
                  </template>
                </a-form-item> 
              </a-col>
              <a-col :span="12">
                <a-form-item label="商品成本"> 
                  <template v-if="!record.haveSpecs && record.shopGoodsSpecsList">
                       ￥ {{record.shopGoodsSpecsList[0].profit}}元
                     <a @click="showModalMoney('商品成本','profit',record.shopGoodsSpecsList[0].profit)"
                        class="update-shop-edit"> <a-icon type="edit" /></a>  
                  </template>
                  <template v-else>
                      见规格
                  </template>
                </a-form-item>
              </a-col>
            </a-row>
            
            <a-row>
              <a-col :span="12">
                <a-form-item label="商品详情" >
                  <img v-for="item in record.goodsDetailsImgList" :key="item" :src="record.readPath + item" width="400px" height="400px" />
                  <a @click="showModalUpload('商品详情图','goodsDetailsImgList',record.goodsDetailsImgList)" class="update-shop-edit"> <a-icon type="edit" /></a>
                </a-form-item> 
              </a-col>
            </a-row>
         </a-form>
      </div>

      <a-modal
          title="修改商品"
          :visible="alertVisible"
          :width="600"
          @ok="updateShop"
          @cancel="modalCancel"
          :confirm-loading="confirmLoading"
        >
          <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18}">
              <a-form-item :label="updateLabel">
                <a-input v-if="showType =='input'" :maxLength="240"  v-decorator="[updateFiled, { rules: [{ required: true, message: '请输入' + updateLabel }], initialValue:filedValue }]"  ></a-input>
                <a-input-number v-else-if="showType =='money'" style="width: 100%"  :max="99999999" :precision="2" :min="0"  v-decorator="[updateFiled, { rules: [{ required: true, message: '请输入' + updateLabel }], initialValue:filedValue }]"  ></a-input-number>
                <a-select  v-else-if="showType =='shopTag'"
                  v-decorator="['shopTag', { rules: [{ required: false, message: '' }] , initialValue:filedValue }]" :allowClear="true"
                    style="width: 100%">
                      <a-select-option  value="recommend">
                        精品推荐
                      </a-select-option>
                      <a-select-option  value="minPrice">
                        低价促销
                      </a-select-option>
                </a-select>
                <a-input-number v-else-if="showType =='sort'" :max="99999" :min="-99999" :precision="0"
                  v-decorator="['sort', { rules: [{ required: true, message: '请输入排序' }] , initialValue:filedValue}]"
                   style="width: 100%" />
                <a-input-number 
                  :max="99999999" v-else-if="showType =='stock'" :precision="0" :min="0" 
                  v-decorator="['stock', { rules: [{ required: true, message: '请输入初始库存' }], initialValue:filedValue }]"
                  style="width: 35%" />   

                <a-select
                  v-else-if="showType =='shopGroup'"
                  show-search
                  option-filter-prop="children"
                  style="width: 100%"
                  :filter-option="filterOption"
                  v-decorator="['goodsGroupId', { rules: [{ required: true, message: '请选择商品分组' }] , initialValue:filedValue}]" 
                >
                  <a-select-option  v-for=" item in record.shopGroupList" :key="item.id" :value="item.id">
                    {{item.groupNameZh}}
                  </a-select-option>
                </a-select>

                 <a-upload v-else-if="showType =='upload'"
                      v-decorator="[updateFiled, { rules: [{ required: true, message: '请选择商品详情图!' }]  }]"
                      :file-list="goodsDetailFileList"
                      accept=".png,.jpg"
                      :before-upload="goodsDetailBeforeUpload"
                      :remove="goodsDetailHandleRemove"
                    >
                  <div v-if="goodsDetailFileList.length < 8">
                      <a-button type="primary"> <a-icon type="upload" />上传</a-button>
                      <a-icon style="margin-left:10px;margin-right:10px;color:#EA7500" type="info-circle" />图片最多上传8个,上传后会覆盖当前{{updateLabel}}
                  </div>
                 </a-upload>
              </a-form-item>
          </a-form>
      </a-modal>
    </a-drawer>

</template>

<script>

  import { postAction, getAction,uploadAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'
  // 修改商品 modal
  export default {
    name: 'UpdateShopModal',
    components: {
    },
    data () {
      return {
        width:800,
        visible: false,
        alertVisible:false,
        openLanguage:false,
        confirmLoading: false,
        updateLabel:'',
        updateFiled:'',
        filedValue:'',
        // 展示组件类型
        showType:'',
        goodsDetailFileList:[],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        record:{},
        // 是否修改了信息  用于关闭刷新
        isGoToUpdate:false,
        url: {
          updateShop: "/shop/web/shop/updateShop",
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      show (record) {
        console.log(record)
        this.record = record
        this.isGoToUpdate = false;
        this.visible = true;
      },
      // 展示modal 输入框
      showModalInput(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'input';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalMoney(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'money';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalTag(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'shopTag';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalSort(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'sort';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalStock(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'stock';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalGroup(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'shopGroup';
        this.form.resetFields();
        this.alertVisible = true
      },
      showModalUpload(updateLabel,filed,filedValue){
        this.updateLabel = updateLabel
        this.updateFiled = filed
        this.filedValue = filedValue
        this.showType = 'upload';
        this.goodsDetailFileList = []
        this.form.resetFields();
        this.alertVisible = true
      },
      // 修改商品
      updateShop () {
         this.form.validateFields((err, values) => {
          if (!err) {
             const formData = new FormData();
             formData.append("id", this.record.id);
             for(let i in values){
               let v = values[i]
               if(v == 0){
                  v = '0'
               }
               if(!isEmpty(v)){
                 if(v.fileList){
                    v.fileList.forEach(file => {
                      if(file.originFileObj){
                          formData.append(i, file.originFileObj);
                      }else{
                          formData.append(i, file);
                      }
                      
                    });
                 }else{
                    formData.append(i, v);
                 }
               }
             }
        
            this.sendUpdateShop(formData);
          }})
      },
      sendUpdateShop(formData){
        let that = this
        this.confirmLoading = true
        uploadAction(this.url.updateShop,formData).then((res)=>{
          if(res.code == 200){
            that.isGoToUpdate = true
            that.record[that.updateFiled] = res.data[that.updateFiled]
            that.$message.success("修改成功");
            that.modalCancel();
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        }) 
           
      },
      handleCancel () {
        if(this.isGoToUpdate){
           this.$emit('ok');
        }
        this.visible = false;
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      goodsDetailBeforeUpload(file){
        this.goodsDetailFileList = [...this.goodsDetailFileList, file];
        return false;
      },
      goodsDetailHandleRemove(file) {
        const index = this.goodsDetailFileList.indexOf(file);
        const newFileList = this.goodsDetailFileList.slice();
        newFileList.splice(index, 1);
        this.goodsDetailFileList = newFileList;
      },
      modalCancel(){
        this.alertVisible = false
      }
    }
  }
</script>
<style scoped>
  .update-shop-edit{
    margin-left: 10px;
  }
</style>