<template>
  <a-modal
      title="请选择客户信息"
      :visible="cusvisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      :closable="true"
      okText="关闭"
      @cancel="handleCancel"
      width="800px"
     >
       <a-table
        :customRow="tableClick"
        ref="table"
        size="middle"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

       </a-table>
    </a-modal>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  export default {
    // 选择客户
    name: 'SelectCustomerModal',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate
    },
    data () {
      return {
        title:'',
        width:800,
        disableSubmit: false,
        cusvisible:false,
        dataSource:[],
        columns:[
          {
            title:'客户名称',
            align:"center",
            dataIndex: 'name',
            fixed:"left",
            ellipsis: true,
            width:250,

          },
          {
            title:'手机号',
            align:"center",
            dataIndex: 'mobile',
            ellipsis: true,
            width:120,
          },
          {
            title:'客户级别',
            align:"center",
            ellipsis: true,
            width:100,
            dataIndex: 'level'
          },
          {
            title:'下次联系时间',
            align:"center",
            ellipsis: true,
            dataIndex: 'nextTime' 
          },
          {
            title:'创建时间',
            align:"center",
            dataIndex: 'createTime'
          },
        ],
        confirmLoading: false,
        url: {
          list: "/khcrm/web/khcrmCustomer/list",
        }
      }
    },
    created() {
      this.queryParam.range = "3"
    },
    methods: {
      show(){
       this.cusvisible = true
      },
      tableClick(record, index){
        return {
            on: {
               click: () => {
                 let data = {
                    id:record.id,
                    name:record.name
                 }
                this.$emit('ok',data);
                this.cusvisible = false
               }
            }
        }
      },
      handleOk(){
        this.cusvisible = false
      },
      handleCancel(){
         this.cusvisible = false
      },
    }
  }
</script>