import { getAction, postAction, formAction, formAction2 } from '@/api/manage'
const url = {
    // 修改负责人
    changePersonOwner: '/khcrm/web/person/changePersonOwner',
   // 根据customerId 查询旗下联系人
   findPersonByCustomerId: '/khcrm/web/person/findPersonByCustomerId',
    
 
}
export default {
    changePersonOwner(params) {
        return postAction(url.changePersonOwner, params);
    },
    findPersonByCustomerId(params) {
        return postAction(url.findPersonByCustomerId, params);
    },
}
