<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24" class="searchclass" >
          <a-col :md="8" :sm="10">
            <a-form-item label="">
              <a-input-search v-model="queryParam.name" :maxLength="200" placeholder="请输入客户名称/手机号" enter-button="搜索" size="default"
                  @search="searchQuery" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24"  >
          <a-col :md="6" :sm="8">
            <a-form-item label="客户场景">
              <a-select v-model="queryParam.range" @change="changeRange" placeholder="请选择">
                <a-select-option value="1">全部客户</a-select-option>
                <a-select-option value="2">本部门的客户</a-select-option>
                <a-select-option value="3">我负责的客户</a-select-option>
                <a-select-option value="5">我参与的客户</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-button  type="primary" @click="changeOwner"> <a-icon type="retweet" />变更负责人</a-button>
      <a-button  type="primary" style="margin-left: 8px" @click="changeDealStatus"> <a-icon type="edit" />更改成交状态</a-button>
      <a-button  style="margin-left: 8px" type="danger" @click="batchDel"> <a-icon type="delete"/>删除</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择 <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="rowSelection"
        class="j-table-force-nowrap"
        @change="handleTableChange">

         <template slot="name" slot-scope="text, record">
            <a v-if="record.ownerUserId == userId" @click="showDetail(record)">{{text}}</a>
            <span v-else>{{text}}</span>
        </template>

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="followup" slot-scope="text">
            <a-tag v-if="text==0" color="#108ee9">未跟进</a-tag>
            <a-tag v-else color="green">已跟进</a-tag>
        </template>
        <template slot="isLock" slot-scope="text">
            <a-tag v-if="text==0" color="#108ee9">未锁定</a-tag>
            <a-tag v-else color="green">已锁定</a-tag>
        </template>

       <template slot="dealStatus" slot-scope="text">
            <a-tag v-if="text==0" color="red">未成交</a-tag>
            <a-tag v-else color="green">已成交</a-tag>
        </template>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)" v-if="record.ownerUserId == userId">编辑</a>
          <a-divider type="vertical" />
          <a-dropdown >
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleDetail(record)">详情</a>
              </a-menu-item>
              <template v-if="record.ownerUserId == userId || (record.rwUserId != null && record.rwUserId.indexOf(userId) >=0)">
                <a-menu-item>
                    <a @click="findOpt(record)">操作记录</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="followup(record)">写跟进</a>
                  </a-menu-item>
                <a-menu-item v-if="record.ownerUserId == userId">
                  <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                    <a>删除</a>
                  </a-popconfirm>
                </a-menu-item>
              </template>
              
            </a-menu>
          </a-dropdown>
        </span>

      </a-table>


<!-- modal -->
      <a-modal v-model="changeDealStatusVisible" title="更改成交状态" @ok="toDealStatus">
        <a-row>  
          <a-col :span="24">
            <a-select style="width: 100%"  v-model="cdealStatus" >
                <a-select-option value="1">已成交</a-select-option>
                <a-select-option value="0">未成交</a-select-option>
            </a-select>
          </a-col>
        </a-row>  
      </a-modal>
    </div>

    <customer-detail ref="cusDetailForm" @ok="cusDetailFormOk" />
    <opt-log-modal  ref="optLogForm"  />
    <followup-modal :tableType="'2'" ref="followupForm" @ok="followupFormOk" />
    <change-leader-modal :isShow="true" ref="changeLeader" @ok="changeLeaderOk" />
    <khcrm-customer-modal ref="modalForm" @ok="modalFormOk"></khcrm-customer-modal>
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import KhcrmCustomerModal from './modules/KhcrmCustomerModal'
  import JSuperQuery from '@/components/jeecg/JSuperQuery.vue';
  import { Customer } from "@/apis";
  import ChangeLeaderModal from '../customer/modules/ChangeLeaderModal.vue'
  import Vue from 'vue'
  import { USER_INFO } from '@/store/mutation-types'
  import FollowupModal from '../common/FollowupModal.vue'
  import OptLogModal from '../common/OptLogModal.vue'
  import CustomerDetail from './modules/CustomerDetail.vue'
  



  export default {
    name: 'KhcrmCustomerList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      KhcrmCustomerModal,
      JSuperQuery,
      ChangeLeaderModal,
      FollowupModal,
      OptLogModal,
        CustomerDetail
    },
    data () {
      return {
        description: 'khcrm_customer管理页面',
        changeDealStatusVisible:false,
        cdealStatus:'1',
        // 表头
        columns: [
          {
            title:'客户名称',
            align:"center",
            dataIndex: 'name',
            fixed:"left",
            ellipsis: true,
            width:147,
            scopedSlots: { customRender: 'name' }
          },
          {
            title:'客户来源',
            align:"center",
            dataIndex: 'source',
            fixed:"left",
            ellipsis: true,
            width:147,
          },
          {
            title:'手机号',
            align:"center",
            dataIndex: 'mobile',
            ellipsis: true,
            width:140,
          },
          {
            title:'客户级别',
            align:"center",
            ellipsis: true,
            width:140,
            dataIndex: 'level'
          },
          {
            title:'客户行业',
            align:"center",
            ellipsis: true,
            width:140,
            dataIndex: 'industry'
          },
           {
            title:'负责人',
            align:"center",
            width:140,
            ellipsis: true,
            dataIndex: 'ownerName'
          },
          {
            title:'邮箱',
            align:"center",
            ellipsis: true,
            width:140,
            dataIndex: 'email'
          },
          {
            title:'跟进状态',
            align:"center",
            dataIndex: 'followup',
            ellipsis: true,
            width:100,
            scopedSlots: { customRender: 'followup' }
          },
          {
            title:'是否锁定',
            align:"center",
            dataIndex: 'isLock',
            ellipsis: true,
            width:100,
            scopedSlots: { customRender: 'isLock' }
          },
          {
            title:'下次联系时间',
            align:"center",
            ellipsis: true,
            width:140,
            dataIndex: 'nextTime' 
          },
          {
            title:'成交状态',
            align:"center",
            dataIndex: 'dealStatus',
            ellipsis: true,
            width:100,
            scopedSlots: { customRender: 'dealStatus' }

          },
          {
            title:'成交时间',
            align:"center",
            ellipsis: true,
            width:140,
            dataIndex: 'dealTime'
          },
          {
            title:'备注',
            align:"center",
            dataIndex: 'remark',
            ellipsis: true,
            width:200,
          },
          {
            title:'最后跟进时间',
            align:"center",
            dataIndex: 'lastTime',
            ellipsis: true,
            width:140,
          },
          {
            title:'最后一条跟进记录',
            align:"center",
            ellipsis: true,
            width:140,
            dataIndex: 'lastContent'
          },
          {
            title:'详细地址',
            align:"center",
            dataIndex: 'detailAddress',
            width:200,
             ellipsis: true,
          },
          {
            title:'放入公海时间',
            align:"center",
            width:140,
             ellipsis: true,
            dataIndex: 'poolTime',
          },
          {
            title:'进入公海前负责人',
            align:"center",
            width:140,
            ellipsis: true,
            dataIndex: 'preOwnerUserId'
          },
          {
            title:'创建人',
            align:"center",
            width:140,
            ellipsis: true,
            dataIndex: 'createName'
          },
         
          {
            title:'创建时间',
            align:"center",
            dataIndex: 'createTime'
          },
           {
            title:'更新时间',
            align:"center",
            dataIndex: 'updateTime'
          },

          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/khcrm/web/khcrmCustomer/list",
          delete: "/khcrm/web/khcrmCustomer/delete",
          deleteBatch: "/khcrm/web/khcrmCustomer/deleteBatch",
        },
        dictOptions:{},
        superFieldList:[],
        userId:""
      }
    },
    created() {
      const userinfo = Vue.ls.get(USER_INFO)
      this.userId = userinfo.userId
      this.queryParam.range = "3"
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
      rowSelection() {
          const { selectedRowKeys } = this;
          return {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.selectedRowKeys = selectedRowKeys;
                this.selectionRows = selectedRows;
            },
            getCheckboxProps: record => ({
              props: {
                disabled: !(record.ownerUserId == this.userId || (record.rwUserId != null && record.rwUserId.indexOf(this.userId) >=0))
                ,
                selectionRows: this.selectionRows
              },
            }) 
         };
      }
    },
    methods: {
      initDictConfig(){
      },
      changeRange(){
        this.loadData(1)
      },
      changeDealStatus(){
         this.changeDealStatusVisible = true;
      },
      followup(r){
        r.tableType = 2
        let param = {
          businessId : r.id,
          tableType : r.tableType,
          type:1
        }
        this.$refs.followupForm.show(r,param);
      },
      findOpt(r){
        r.tableType = 2
        let param = {
          businessId : r.id,
          tableType : r.tableType,
          type:2
        }
         this.$refs.optLogForm.show(r,param); 
      },
       followupFormOk(){
         this.loadData(1)
       },
       showDetail(record){
          console.log(record)
          this.$refs.cusDetailForm.show(record); 
       },
       cusDetailFormOk(){
                    
       },
       // 执行
       toDealStatus(){
         var ids = "";
         for (var a = 0; a < this.selectedRowKeys.length; a++) {
           ids += this.selectedRowKeys[a] + ",";
         }
         let param ={
           ids:ids,
           dealStatus :this.cdealStatus
         }
         if(ids == ""){
            this.$message.warning("请先选择客户");
            return
        }
         var that = this;
         Customer.changeDealStatus(param).then((res) => {
              if (res.code ==200) {
                that.$message.success(res.message);
                that.loadData();
                that.onClearSelected();
                that.changeDealStatusVisible = false
              } else {
                that.$message.warning(res.message);
              }
              that.loading = false;
         }) 
      },
      changeOwner(){
        this.$refs.changeLeader.show();
      },
      changeLeaderOk(userOwnerId,changeOwnerTo){
         var ids = "";
          for (var a = 0; a < this.selectedRowKeys.length; a++) {
            ids += this.selectedRowKeys[a] + ",";
          }
          if(ids == ""){
            this.$message.warning("请先选择客户");
            return
          }
          let param = {
             ids : ids,
             ownerUserId : userOwnerId,
             changeOwnerTo:changeOwnerTo
          }

          var that = this
          that.loading = true;
          Customer.changeCustomerOwner(param).then((res) => {
              if (res.code ==200) {
                that.$message.success(res.message);
                that.loadData();
              } else {
                that.$message.warning(res.message);
              }
              that.loading = false;
          }) 
       },
      toLock(status){
        var ids = "";
        for (var a = 0; a < this.selectedRowKeys.length; a++) {
          ids += this.selectedRowKeys[a] + ",";
        }
        if(ids == ""){
            this.$message.warning("请先选择客户");
            return
        }
        var that = this;
        this.$confirm({
          title: "提示",
          content: "锁定后将不会掉入公海,确定要锁定这些客户吗?",
          onOk: function () {
            that.loading = true;
            let param = {
              ids:ids,
              isLock:status
            }
            Customer.toLock(param).then((res) => {
              if (res.code ==200) {
                that.$message.success(res.message);
                that.loadData();
                that.onClearSelected();
              } else {
                that.$message.warning(res.message);
              }
              that.loading = false;
            })
          }
        });
      }
      
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
  .searchclass{
    display:flex;
    justify-content:center;
  }
</style>