import { getAction, postAction, formAction } from '@/api/manage'
const url = {
    getShopGroupNoPage: '/shop/web/shopGroup/getShopGroupNoPage',
    getFileData:'/shop/file',
}
export default {
    getShopGroupNoPage(params) {
        return getAction(url.getShopGroupNoPage, params);
    },
    getFileData(fileName){
        return getAction(url.getFileData + "/" + fileName, null);
    }
}
