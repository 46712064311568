<template>
  <a-modal
    title="重新设定密码"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
    cancelText="关闭"
    style="top: 20px"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="用户账号"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入用户账号"
            v-decorator="['username', {}]"
            :readOnly="true"
          />
        </a-form-item>

        <a-form-item
          label="登陆密码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          hasFeedback
        >
          <a-input
            type="password"
            placeholder="请输入登陆密码"
            v-decorator="['password', validatorRules.password]"
          />
        </a-form-item>

        <a-form-item
          label="确认密码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          hasFeedback
        >
          <a-input
            type="password"
            @blur="handleConfirmBlur"
            placeholder="请重新输入登陆密码"
            v-decorator="['confirmpassword', validatorRules.confirmpassword]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { changePassword } from "@/api/api";
import pick from "lodash.pick";

export default {
  name: "PasswordModal",
  data() {
    return {
      visible: false,
      confirmLoading: false,
      confirmDirty: false,
      validatorRules: {
        password: {
          rules: [
            {
              required: true,
              // pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/,
              message: "密码由8位数字、大小写字母和特殊符号组成!",
            },
            {
              validator: this.validateToNextPassword,
            },
          ],
        },
        confirmpassword: {
          rules: [
            {
              required: true,
              message: "请重新输入登陆密码!",
            },
            {
              validator: this.compareToFirstPassword,
            },
          ],
        },
      },

      model: {},

      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      form: this.$form.createForm(this),
    };
  },
  created() {
    console.log("created");
  },

  methods: {
    show({ username, id }) {
      console.log(username);
      this.form.resetFields();
      this.visible = true;
      this.model.id = id;
      this.$nextTick(() => {
        this.form.setFieldsValue({ username });
      });
    },
    close() {
      this.$emit("close");
      this.visible = false;
      this.disableSubmit = false;
    },
    handleSubmit() {
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          let formData = Object.assign(this.model, values);
          changePassword(pick(formData, "id", "password"))
            .then((res) => {
              const { code, message } = res;
              if (code == 200) {
                this.$message.success(message);
                this.$emit("ok");
              } else {
                this.$message.warning(message);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
              this.close();
            });
        }
      });
    },
    handleCancel() {
      this.close();
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      const confirmpassword = form.getFieldValue("confirmpassword");
      console.log("confirmpassword==>", confirmpassword);
      if (value && confirmpassword && value !== confirmpassword) {
        callback("两次输入的密码不一样！");
      }
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("两次输入的密码不一样！");
      } else {
        callback();
      }
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
  },
};
</script>