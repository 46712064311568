<template>
    <div>
        <a-modal v-model="visible" title="修改步骤信息" @ok="handleOk" :confirmLoading="confirmLoading">
          <a-form  :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12}">
             <a-form-item label="标题名称">
                 <a-input 
                    v-decorator="['name', { initialValue: record.name ,rules: [{ required: true, message: '请输入标题名称!' }] }]"
                    placeholder="请输入标题名称"></a-input>
              </a-form-item>
              <a-form-item  label="是否需要审批" >
               <a-radio-group v-decorator="['isApproval', { initialValue: record.isApproval, rules: [{ required: true, message: '请选择是否需要审批!' }] }]">
                  <a-radio :value="0" @click="approvalChange(0)">不需要 </a-radio> 
                  <a-radio :value="1" @click="approvalChange(1)">需要</a-radio>
                </a-radio-group>
            </a-form-item> 
            <a-form-item label="选择审批角色" v-if="record.isApproval != 0">
               <a-select v-decorator="['roleCode', { initialValue: record.roleCode, rules: [{ required: true, message: '请选择审批角色!' }] }]"  placeholder="请选择审批角色">
                 <template v-if="roleList">
                      <a-select-option v-for="item in roleList" :key="item.id" :value="item.roleCode" >{{item.roleName}}</a-select-option>
                 </template>
                 <template v-else>
                      <a-select-option value="" >您还未建任何角色</a-select-option>
                 </template>
              </a-select>
            </a-form-item> 
          </a-form>
        </a-modal>   
    </div>
</template>

<script>
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import { mixinDevice } from '@/utils/mixin'
  import JDate from '@/components/jeecg/JDate.vue'
  import { MakeLink } from "@/apis";
  import moment from 'moment'

  export default {
    // 修改环节信息
    name: 'UpdateStepModal',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      JDate
    },
    data () {
      return {
        spinning:false,
        visible:false,
        confirmLoading:false,
        record:{},
        form: this.$form.createForm(this, { name: 'coordinated' }),
 
        roleList:[]
      }
    },
    created() {
      this.getUsRoleInfo()
    },
    methods: {
      loadData(arg) {
        // 加载数据 若传入参数1则加载第一页的内容
        if (arg === 1) {
          this.ipagination.current = 1
        }
        let params = this._getQueryParams() // 查询条件
        console.log(params)
      },
      show(record){
        console.log(record)
        this.form.resetFields();
        this.record = record
        this.visible = true
      },
      handleOk(){
         this.form.validateFields((err, values) => {
           if (!err) {
             console.log(values)
              values.id = this.record.id
              this.confirmLoading = true
              MakeLink.updateProcessStep(values).then((res) => {
                if (res.code ==200) {
                  this.$emit('ok');
                  this.visible = false
                } else {
                  this.$message.warning(res.message);
                }
              }).finally(() => {
                this.confirmLoading = false
              })
           }
        })
      },
      approvalChange(e){
         this.record.isApproval = e
      },
      getUsRoleInfo(){
         MakeLink.getUsRoleInfo(null).then((res) => {
            if (res.code ==200) {
                this.roleList = res.data
                console.log(this.roleList)
            } else {
              this.$message.warning(res.message);
            }
        }) 
      },
    }
  }
</script>
<style lang="less" scoped>
  @import '~@assets/less/common.less';
  .seg-title-all{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .submit-btn{
    display: flex;
    flex-direction:row-reverse;
    justify-content: center;
  }
  .input-class{
    width:75%;
    margin-left:10px
  }

  .seg-line{
    background-color: @primary-color;
    width: 4px;
    height: 20px;
  }
  .seg-font{
    margin-left: 10px;
    font-size: 16px;
    font-weight: 800;
    
  }
  .del-a-btn{
    margin-left: 20px;
  }
  .seg-row{
    margin-left:20px;
    margin-top:20px
  }
  .seg-col{
    display: flex;
    flex-direction: row;
    
  }
  .seg-col-div{
    width: 100px;
    text-align: right;
    margin-right: 20px;
    /* background-color: red; */
  }
 .control-width{
   width: 75%;
 }
</style>
