<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24" class="searchclass" >
          <a-col :md="8" :sm="10">
            <a-form-item label="">
              <a-input-search v-model="queryParam.name" :maxLength="200" placeholder="请输入客户名称/回款编号" enter-button="搜索" size="default"
                  @search="searchQuery" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24"  >
          <a-col :md="6" :sm="8">
            <a-form-item label="回款范围">
              <a-select v-model="queryParam.range" placeholder="请选择"  @change="changeRange">
                <a-select-option value="1">全部回款</a-select-option>
                <a-select-option value="2">本部门的回款</a-select-option>
                <a-select-option value="3">我负责的回款</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-button type="primary" @click="changeOwner"> <a-icon type="retweet" />变更负责人</a-button>
      <a-button style="margin-left: 8px" type="danger" @click="batchDel"> <a-icon type="delete"/>删除</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择 <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="status" slot-scope="text">
            <a-tag v-if="text==0" color="#108ee9">待提交</a-tag>
            <a-tag v-if="text==2"  color="#87d068">待审核</a-tag>
            <a-tag v-if="text==4"  color="blue">审核中</a-tag>
            <a-tag v-if="text==6"  color="#87d068">通过</a-tag>
            <a-tag v-if="text==8"  color="#f50">拒绝</a-tag>
            <a-tag v-if="text==10"  color="purple">撤回</a-tag>

        </template>

        <template slot="fileUrl" slot-scope="text, record">
             <img v-if="text" :src="text" class="money-img" @click="enlargeImg(text)" />
             <span v-else>-</span>
        </template>
        

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>

          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleDetail(record)">详情</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>

      </a-table>
    </div>


    <a-modal
      title="放大"
      :visible="visible"
      width="30%"
      @cancel="handleCancel"
      @ok="handleCancel"
    >
      <img :src="imgUrl" style="height:100%;width:100%" />
    </a-modal>
    <change-leader-modal ref="changeLeader" @ok="changeLeaderOk" />
    <khcrm-return-money-modal ref="modalForm" @ok="modalFormOk"></khcrm-return-money-modal>
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  import KhcrmReturnMoneyModal from './modules/KhcrmReturnMoneyModal'
  import ChangeLeaderModal from '../customer/modules/ChangeLeaderModal.vue'
  import { Returnmoney } from "@/apis";
  import Vue from 'vue'
  import { USER_INFO } from '@/store/mutation-types'

  export default {
    name: 'KhcrmReturnMoneyList',
    mixins:[JeecgListMixin, mixinDevice],
    components: {
      KhcrmReturnMoneyModal,
      ChangeLeaderModal
    },
    data () {
      return {
        description: 'khcrm_return_money管理页面',
        // 表头
        columns: [
          {
            title:'回款日期',
            align:"center",
            ellipsis: true,
            fixed:"left",
            width:147,
            dataIndex: 'returnTime'
          },
          {
            title:'回款金额(元)',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'money'
          },
          {
            title:'回款方式',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'returnType'
          },
          {
            title:'回款凭证',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'fileUrl',
            scopedSlots: { customRender: 'fileUrl' }
          },
          {
            title:'客户',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'customerName'
          },
          {
            title:'合同',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'contractName'
          },
          {
            title:'回款编号',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'number'
          },
          // {
          //   title:'状态',
          //   align:"center",
          //   ellipsis: true,
          //   width:120,
          //   dataIndex: 'status',
          //   scopedSlots: { customRender: 'status' }
          // },
          
           {
            title:'负责人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'ownerName'
          },
          {
            title:'备注',
            align:"center",
            ellipsis: true,
            width:200,
            dataIndex: 'remark'
          },
          {
            title:'创建人',
            align:"center",
            ellipsis: true,
            width:147,
            dataIndex: 'createName'
          },
         
          {
            title:'创建时间',
            align:"center",
            dataIndex: 'createTime'
          },
           {
            title:'更新时间',
            align:"center",
            dataIndex: 'updateTime'
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/khcrm/web/khcrmReturnMoney/list",
          delete: "/khcrm/web/khcrmReturnMoney/delete",
          deleteBatch: "/khcrm/web/khcrmReturnMoney/deleteBatch",
          exportXlsUrl: "/khcrm/web/khcrmReturnMoney/exportXls",
          importExcelUrl: "khcrm/web/khcrmReturnMoney/importExcel",
          
        },
        dictOptions:{},
        superFieldList:[],
        visible:false,
        imgUrl:''
      }
    },
    created() {
      const userinfo = Vue.ls.get(USER_INFO)
      this.userId = userinfo.userId
      this.queryParam.range = "3"
    },
    computed: {
      importExcelUrl: function(){
        return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },
    },
    methods: {
      initDictConfig(){
      },
      changeOwner(){
        this.$refs.changeLeader.show();
      },
      changeRange(){
        this.loadData(1)
      },
      enlargeImg(url){
         this.imgUrl = url;
         this.visible = true
      },
      handleCancel(){
        this.visible = false
      },
      changeLeaderOk(userOwnerId){
         var ids = "";
          for (var a = 0; a < this.selectedRowKeys.length; a++) {
            ids += this.selectedRowKeys[a] + ",";
          }
          if(ids == ""){
            this.$message.warning("请先选择回款");
            return
          }
          let param = {
             ids : ids,
             ownerUserId : userOwnerId
          }
          var that = this
          that.loading = true;
          Returnmoney.changeReturnMoneyOwner(param).then((res) => {
              if (res.code ==200) {
                that.$message.success(res.message);
                that.loadData();
                that.loading = false;
              } else {
                that.$message.warning(res.message);
              }
          }) 
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
  .money-img{
    height: 30px;
    width: 100px;
  }
</style>