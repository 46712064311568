<template>
  <a-modal
    :title="title"
    :width="width"
    :visible="visible"
    switchFullscreen
    @cancel="handleCancel"
    :footer="null"
    cancelText="关闭">
     <template>
        <a-carousel arrows dots-class="slick-dots slick-thumb">
            <a slot="customPaging" slot-scope="props">
                <img :src="getImgUrl(props.i)" />
            </a>
            <div v-for="item in fileList" :key="item">
              <img :src="item" />
            </div>
        </a-carousel>
    </template>
  </a-modal>
</template>

<script>

  export default {
    name: 'ImgShowModal',
    components: {
    },
    data () {
      return {
        title:'图片查看',
        width:800,
        visible: false,
        fileList:[],
        readPath:'',
        accessToken:''
      }
    },
    methods: {
      show (fileList) {
        this.fileList = fileList.url
        this.visible=true
      },
      getImgUrl(i) {
        return this.fileList[i];
      },
      close () {
        this.visible = false;
      },
      handleOk () {
        this.close()
      },
      handleCancel () {
        this.close()
      }

    }
  }
</script>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 100%;
}
.ant-carousel >>> .slick-thumb {
  bottom: 0px;
}
.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
</style>