import { getAction, postAction, formAction, formAction2 } from '@/api/manage'
const url = {
    // 根据客户id查询商机
    findBusinessByCustomerId: '/khcrm/web/khcrmBusiness/findBusinessByCustomerId',
    // 获取商机阶段组
    getBusStatusGroup: '/khcrm/web/khcrmBusiness/getBusStatusGroup',
    // 根据组查询阶段
    getBusStatusByGroupId: '/khcrm/web/khcrmBusiness/getBusStatusByGroupId',
    // 更换负责人
    changeBusinessOwner:'/khcrm/web/khcrmBusiness/changeBusinessOwner',
    // 更改需求阶段
    changeStep:'/khcrm/web/khcrmBusiness/changeStep',
}
export default {
    findBusinessByCustomerId(params) {
        return postAction(url.findBusinessByCustomerId, params);
    },
    getBusStatusGroup(params) {
        return getAction(url.getBusStatusGroup, params);
    },
    getBusStatusByGroupId(params) {
        return getAction(url.getBusStatusByGroupId, params);
    },
    changeBusinessOwner(params) {
        return postAction(url.changeBusinessOwner, params);
    }, 
    changeStep(params) {
        return postAction(url.changeStep, params);
    },
}
